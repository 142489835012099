import { useState } from "react";
import { useTranslation } from "react-i18next";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

export default function UploadFileArea({ onChange }) {
  const [t] = useTranslation("global");
  const [file, setFile] = useState(null);

  function internalOnChange(value) {
    setFile(value.target.files[0]);
    onChange(value);
  }
  function removeFile() {
    setFile(null);
    onChange({ target: { files: [null] } });
  }

  function getContent() {
    if (file) {
      return (
        <h1>
          {t("Account.Message.SelectedFile")} {file ? file.name : ""}{" "}
        </h1>
      );
    } else {
      return (
        <Box
          border="1px dashed grey"
          display="inline-block"
          width="410px"
          height="200px"
          borderRadius="10px"
          padding="10px"
        >
          <input
            data-testid={"InputFile"}
            id="file-id"
            required
            type="file"
            multiple
            accept="image/*"
            style={{
              position: "absolute",
              margin: "0",
              padding: "0",
              width: "330px",
              height: "180px",
              opacity: "0",
              outline: "none",
              cursor: "pointer",
            }}
            onChange={internalOnChange}
          />
          <div style={{ textAlign: "center", paddingTop: "10px" }}>
            <CloudUploadIcon style={{ color: "#404A56" }} />
            <Typography
              variant="h5"
              gutterBottom
              component="div"
              sx={{
                paddingTop: "20px",
                fontWeight: 500,
                fontSize: "14px",
                color: "#404A56",
              }}
            >
              {t("Account.Message.DragAndDrop")}
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              component="div"
              sx={{
                paddingTop: "20px",
                fontWeight: 500,
                fontSize: "14px",
                color: "#404A56",
              }}
            >
              {t(`Account.Message.OR`)}
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              component="div"
              sx={{
                paddingTop: "20px",
                fontWeight: 500,
                fontSize: "14px",
              }}
            >
              <a style={{ color: "#004FB6" }} href="# ">
                {t(`Account.Message.SelectFile`)}
              </a>
            </Typography>
          </div>
        </Box>
      );
    }
  }

  return (
    <div>
      {getContent()}
      <Stack direction="row" spacing={2} justifyContent="left" alignItems="end">
        <ErrorOutlineIcon style={{ fontSize: 19 }} />
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{
            paddingTop: "20px",
            fontWeight: 400,
            fontSize: "14px",
            color: "#404A56",
            margin: 0,
          }}
        >
          {t(`Account.Message.FileFormat`) + "10MB"}
        </Typography>
        <CheckCircleIcon style={{ color: "#404A56" }} />
        <DeleteIcon data-testid={"btn-delete"} style={{ color: "#404A56", cursor: "pointer" }} onClick={removeFile} />
      </Stack>
    </div>
  );
}
