import * as React from "react";
import { useTranslation } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";
import { Modal as BasicModal, Box, ButtonBase, InputBase, Typography } from "@mui/material";

export default function Modal(props) {
  const [t] = useTranslation("global");
  const myRef = React.useRef(null);

  const copyText = (e) => {
    navigator.clipboard.writeText(myRef.current.value);
    e.target.focus();
  };

  return (
    <div>
      <BasicModal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ p: 4 }} className="modal-box">
          <CloseIcon className="icon-close-modal" onClick={props.handleClose} />
          <Typography id="modal-modal-title" variant="h6" component="h2" mt={2}>
            {t(props.data.title)}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {t(props.data.subtitle)}
          </Typography>

          {props.data.hasOwnProperty("buttonWithCopy") ? (
            <Box mt={8} mb={8} sx={{ widht: "100%" }}>
              <InputBase
                inputRef={myRef}
                sx={{
                  ml: 1,
                  flex: 1,
                }}
                className="input-copy"
                placeholder=""
                value={props.data.buttonWithCopy.input.value}
              />
              <ButtonBase
                className="button-copy"
                onClick={(e) => {
                  copyText(e);
                }}
              >
                {t(props.data.buttonWithCopy.button.value)}
              </ButtonBase>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </BasicModal>
    </div>
  );
}
