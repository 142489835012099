import React, { Children, Fragment, useEffect, useRef, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import PropTypes from "prop-types";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export const permissionsContext = React.createContext();

export default function CheckPermisions(props) {
  const axiosPrivate = useAxiosPrivate();
  const [permissions, setPermissions] = useState({});
  const arrayChildren = Children.toArray(props.children);
  const isComponentMounted = useRef();
  const useAuth = useAuthUser();
  const auth = useAuth();

  useEffect(async () => {
    isComponentMounted.current = true;
    const controller = new AbortController();
    const getPermissions = async () => {
      const response = await axiosPrivate.get(`permissions`, {
        params: {
          view: props.view,
        },
      });
      if (isComponentMounted.current) {
        setPermissions(response.data.data);
      }
    };
    if(auth?.authToken?.IdToken)
      getPermissions();
    return () => {
      isComponentMounted.current = false;
      controller.abort();
    };
  }, [axiosPrivate, props]);

  return (
    <permissionsContext.Provider value={permissions}>
      <Fragment>
        {permissions &&
          arrayChildren.map(function (children, _index) {
            return children;
          })}
      </Fragment>
    </permissionsContext.Provider>
  );
}

// Define prop types for the component
CheckPermisions.propTypes = {
  children: PropTypes.node,
  view: PropTypes.string,
};
