import React from "react";
import PropTypes from "prop-types";
import ConfigIntegration from "../commons/configIntegration";

const ConfigEcommerce = (props) => {
  return <ConfigIntegration onClickIntegration={props.onClickIntegration} typeIntegration="PayButton" />;
};

ConfigEcommerce.propTypes = {
  onClickIntegration: PropTypes.func.isRequired,
};

export default ConfigEcommerce;
