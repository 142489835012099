import React from "react";

import { Box, Grid } from "@mui/material";

const Image = ({ img }) => {
  const styles = {
    contentImage: {
      height: "10vh",
      backgroundImage: `url(/images/${img.name})`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    },
  };

  return (
    <Box mb={3} sx={{ justifyContent: "center", display: "flex" }}>
      <Grid container justifyContent="center">
        <Grid item xs={2} md={2} sx={styles.contentImage}></Grid>
      </Grid>
    </Box>
  );
};

export default Image;
