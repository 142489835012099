import React, { Fragment, useContext, useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import { Button } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { v4 as uuidv4 } from "uuid";

import Buttons from "./buttons";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { permissionsContext } from "../check-permissions/checkPermissions";
import { SnackBar } from "../commons/alert";
import BasicTable from "../commons/basicTable";
import GenericBackdrop from "../commons/GenericBackdrop";
import { DataTableFormat } from "../commons/setDataTable";
import { getClientId } from "../utils/jwt";

export default function Transfers({ transfersData, branches, statuses }) {
  const [t] = useTranslation("global");
  let navigate = useNavigate();

  const [filterParams, setfilterParams] = useState({});
  const [loader, setLoader] = useState(false);
  const [columnsSearch, setColumnsSearch] = useState(null);
  const [columnsSort, setColumnsSort] = useState(null);
  const [dataBackUp, setDataBackUp] = useState(null);
  const [columns, setColumns] = useState(null);
  const [data, setData] = useState(null);
  const [datesFilter, setDatesFilter] = useState(null);
  const axiosPrivate = useAxiosPrivate();
  const useAuth = useAuthUser();
  const auth = useAuth();
  const [inputSearch, setInputSearch] = useState("");
  const permissions = useContext(permissionsContext);

  const condition = ["status"];

  useEffect(() => {
    setDatesFilter(transfersData["datesFilter"]);
  }, [transfersData]);

  const getBalanceDetail = async (filters) => {
    try {
      setLoader(true);

      const response = await axiosPrivate.get(`transactions`, {
        params: {
          client_id: getClientId(auth),
          ...filters,
        },
      });

      let permissionGranted = "resources.insights.actions.details".split(".").reduce((obj, i) => obj[i], auth?.roles);
      if (!permissionGranted) response.data.columns.pop("Adjustment");

      const result = DataTableFormat(response.data, t);

      setColumnsSearch(result["columnsSearch"]);
      setColumnsSort(result["columnsSort"]);
      setDataBackUp(result["dataBackUp"]);
      setColumns(result["columns"]);
      setData(result["data"]);
      setDatesFilter(result["datesFilter"]);
      setLoader(false);
    } catch (_) {
      SnackBar(t("General.Message.ErrorService"), "error");
      setLoader(false);
    }
  };

  const handleCallback = (childData) => {
    setfilterParams(childData);
    getBalanceDetail(childData);
  };

  const setButtons = (results) => {
    if (results.status === "OK") {
      return (
        <Button
          disabled
          style={{
            borderRadius: "10px",
            textTransform: "none",
            backgroundColor: "#DEF3DD",
            color: "#59C556",
            borderColor: "white",
            fontWeight: "bold",
          }}
          variant="outlined"
          startIcon={<CheckIcon />}
        >
          {t(`Insights.Transactions.Table.StatusLiquidated`)}
        </Button>
      );
    } else if (results.status === "ERROR") {
      return (
        <Button
          disabled
          style={{
            borderRadius: "10px",
            textTransform: "none",
            backgroundColor: "#EFCCD2",
            color: "#B00020",
            borderColor: "white",
            fontWeight: "bold",
          }}
          variant="outlined"
          startIcon={<CloseIcon />}
        >
          {t(`Insights.Transactions.Table.StatusError`)}
        </Button>
      );
    } else if (results.status === "REVERTED") {
      return (
        <Button
          disabled
          style={{
            borderRadius: "10px",
            textTransform: "none",
            backgroundColor: "#FFEEB5",
            color: "#7D6000",
            borderColor: "white",
            fontWeight: "bold",
          }}
          variant="outlined"
          startIcon={<AttachMoneyIcon />}
        >
          {t(`Insights.Transactions.Table.StatusRefunded`)}
        </Button>
      );
    } else {
      return (
        <Button
          disabled
          style={{
            borderRadius: "10px",
            textTransform: "none",
            backgroundColor: "#FFEEB5",
            color: "#7D6000",
            borderColor: "white",
            fontWeight: "bold",
          }}
          variant="outlined"
          startIcon={<AttachMoneyIcon />}
        >
          {t(`Insights.Transactions.Table.StatusReversed`)}
        </Button>
      );
    }
  };

  const handleClick = (row) => {
    navigate("/TransfersDetail", {
      screen: "TransfersDetail",
      state: {
        type: row,
      },
    });
  };

  const setIsSearch = (isSearch) => {
    console.log(isSearch);
  };

  const createaction = (row) => {
    return (
      <Fragment key={uuidv4()}>
        <TableCell align="center">{setButtons(row)}</TableCell>
        {permissions?.insights?.actions?.details && (
          <TableCell align="center" onClick={() => handleClick(row)}>
            {<SettingsIcon data-testid={`SettingsIcon-${row.transaction_id}`} style={{ cursor: "hand" }} />}
          </TableCell>
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <GenericBackdrop open={loader} />
      <Buttons
        data={data ?? transfersData["data"]}
        columns={columns ?? transfersData["columns"]}
        backUp={dataBackUp ?? transfersData["dataBackUp"]}
        setSearch={setData}
        columnsSearch={columnsSearch ?? transfersData["columnsSearch"]}
        setIsSearch={setIsSearch}
        dataFilter={handleCallback}
        dateDefaultFilter={datesFilter ?? transfersData["datesFilter"]}
        setInputSearch={setInputSearch}
        inputSearch={inputSearch}
        filterEmail={{ ...filterParams, input_search: inputSearch }}
        branches={branches}
        statuses={statuses}
      />
      <BasicTable
        dataTable={data ?? transfersData["data"]}
        dataColumns={columns ?? transfersData["columns"]}
        columnsSort={columnsSort ?? transfersData["columnsSort"]}
        action={createaction}
        condition={condition}
      />
    </Fragment>
  );
}
