import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

import Callback from "./callbacks";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Alert } from "../../commons/alert";
import GenericBackdrop from "../../commons/GenericBackdrop";
import AddKeyValue from "../commons/addKeyValue";
import ButtonsIntegrationsConfig from "../commons/buttonsIntegrationsConfig";
import { convertKeyValue, creatObjetFormat } from "../utils/utils";

const AddEcommerce = (props) => {
  const [t] = useTranslation("global");
  const axiosPrivate = useAxiosPrivate();
  const [loader, setLoader] = useState(false);
  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);
  const [edit, setEdit] = useState(false);
  const [metadata, setMetadata] = useState([{ key: "", value: "" }]);
  const [headersCorrect, setHeadersCorrect] = useState([{ key: "", value: "" }]);
  const [parametersCorrect, setParametersCorrect] = useState([{ key: "", value: "" }]);
  const [bodyCorrect, setBodyCorrect] = useState([{ key: "", value: "" }]);
  const [headersIncorrect, setHeadersIncorrect] = useState([{ key: "", value: "" }]);
  const [parametersIncorrect, setParametersIncorrect] = useState([{ key: "", value: "" }]);
  const [bodyIncorrect, setBodyIncorrect] = useState([{ key: "", value: "" }]);
  const [appId, setAppId] = useState("");
  const [callbackCorrect, setCallbackCorrect] = useState({
    redirect: "",
    callback: "",
    method: "",
  });
  const [callbackIncorrect, setCallbackIncorrect] = useState({
    redirect: "",
    callback: "",
    method: "",
  });

  useEffect(() => {
    const getConfiguration = (dataArray) => {
      const callbackSuccess = dataArray.callbacks.find((item) => item.type_callback === "BTN_FINISH_OK");
      const callbackError = dataArray.callbacks.find((item) => item.type_callback === "BTN_REJECTED");
      const redirectSuccess = dataArray.redirects.find((item) => item.type_redirect === "success");
      const redirectError = dataArray.redirects.find((item) => item.type_redirect === "error");

      setMetadata(convertKeyValue(dataArray.integrated.metadata));
      setAppId(dataArray.integrated.app_id);

      if (callbackSuccess) {
        setHeadersCorrect(convertKeyValue(callbackSuccess.headers));
        setParametersCorrect(convertKeyValue(callbackSuccess.params));
        setBodyCorrect(convertKeyValue(callbackSuccess.body));
        setCallbackCorrect({
          redirect: redirectSuccess ? redirectSuccess.redirect : "",
          callback: callbackSuccess.callback,
          method: callbackSuccess.method,
        });
      }

      if (callbackError) {
        setHeadersIncorrect(convertKeyValue(callbackError.headers));
        setParametersIncorrect(convertKeyValue(callbackError.params));
        setBodyIncorrect(convertKeyValue(callbackError.body));
        setCallbackIncorrect({
          redirect: redirectError ? redirectError.redirect : "",
          callback: callbackError.callback,
          method: callbackError.method,
        });
      }
    };

    if (props.dataConfiguration && !edit) {
      setEdit(true);
      getConfiguration(props.dataConfiguration);
    }
  }, [props.dataConfiguration, edit, bodyCorrect]);

  const handleChangesKeyValue = (newFields, fieldSetter) => {
    fieldSetter(newFields);
  };

  const handleChangeCallback = (campo, valor, fieldSetter) => {
    fieldSetter((prevCampos) => ({
      ...prevCampos,
      [campo]: valor,
    }));
  };

  const handleSaveFields = () => {
    handleLoaderOpen();
    if (validateFields()) {
      if (validateHttps()) {
        const body = {
          metadata: JSON.parse(JSON.stringify(creatObjetFormat(metadata, 0))),
          callbacks: [createObjetCallback("Correct"), createObjetCallback("InCorrect")],
        };
        axiosPrivate
          .post("integration/ecommerce", body)
          .then((response) => {
            handleLoaderClose();
            Alert(t("Integrations.PayButton.Implementation.Message.SavedSuccess"), "success");
            props.onClose(response.data);
          })
          .catch((_) => {
            handleLoaderClose();
            return Alert(t("General.Message.ErrorService"), "error");
          });
      } else {
        handleLoaderClose();
        Alert(t("Integrations.PayButton.Implementation.Message.ValHttp"), "warning");
      }
    } else {
      handleLoaderClose();
      Alert(t("Integrations.PayButton.Implementation.Message.FieldsRequire"), "warning");
    }
  };

  const handleUpdateFields = () => {
    handleLoaderOpen();
    if (validateFields()) {
      if (validateHttps()) {
        const body = {
          app_id: appId,
          metadata: JSON.parse(JSON.stringify(creatObjetFormat(metadata, 0))),
          callbacks: [createObjetCallback("Correct"), createObjetCallback("InCorrect")],
        };
        axiosPrivate
          .put("integration/ecommerce", body)
          .then((response) => {
            handleLoaderClose();
            Alert(t("Integrations.PayButton.Implementation.Message.UpdateSuccess"), "success");
            props.onClose(response.data);
          })
          .catch((_) => {
            handleLoaderClose();
            return Alert(t("General.Message.ErrorService"), "error");
          });
      } else {
        handleLoaderClose();
        Alert(t("Integrations.PayButton.Implementation.Message.ValHttp"), "warning");
      }
    } else {
      handleLoaderClose();
      Alert(t("Integrations.PayButton.Implementation.Message.FieldsRequire"), "warning");
    }
  };

  const validateHttps = () => {
    const isInvalidURL = (url) => {
      return url === "" || url.startsWith("https://");
    };

    return (
      isInvalidURL(callbackCorrect.redirect) &&
      isInvalidURL(callbackCorrect.callback) &&
      isInvalidURL(callbackIncorrect.redirect) &&
      isInvalidURL(callbackIncorrect.callback)
    );
  };

  const validateFields = () => {
    const validations = [
      {
        fields: [callbackCorrect.redirect, callbackCorrect.callback, callbackCorrect.method],
      },
      {
        fields: [callbackIncorrect.callback, callbackIncorrect.method],
      },
      {
        fields: bodyCorrect.map((item) => item.key !== "" && item.value !== ""),
      },
      {
        fields: bodyIncorrect.map((item) => item.key !== "" && item.value !== ""),
      },
    ];
    const invalidValidations = validations.filter((validation) => validation.fields.some((field) => field === ""));

    return invalidValidations.length === 0;
  };

  const createObjetCallback = (type) => {
    const dataCallback = type === "Correct" ? callbackCorrect : callbackIncorrect;
    const headers = {
      headers: type === "Correct" ? creatObjetFormat(headersCorrect) : creatObjetFormat(headersIncorrect),
    };
    const params = {
      params: type === "Correct" ? creatObjetFormat(parametersCorrect) : creatObjetFormat(parametersIncorrect),
    };
    const body = { body: type === "Correct" ? creatObjetFormat(bodyCorrect) : creatObjetFormat(bodyIncorrect) };
    const typeCallback = { type_callback: type === "Correct" ? "BTN_FINISH_OK" : "BTN_REJECTED" };
    const typeRedirect = { type_redirect: type === "Correct" ? "success" : "error" };
    const combinedObject = { ...dataCallback, ...typeCallback, ...typeRedirect, ...headers, ...body, ...params };
    return combinedObject;
  };

  return (
    <Stack style={{ fontFamily: "Roboto" }}>
      <GenericBackdrop open={loader} />
      <Card>
        <CardContent sx={{ margin: "10px 30px 10px 30px" }}>
          <Typography sx={{ color: "#404A56", fontWeight: "bold", fontSize: "18px", lineHeight: "32px" }}>
            {t("Integrations.PayButton.Implementation.SectionTitle.Configuration")}
          </Typography>
          <Stack spacing={2} paddingTop={3}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography sx={{ color: "#4C5866", fontWeight: "500", fontSize: "16px", lineHeight: "24px" }}>
                  {t("Integrations.PayButton.Implementation.Add.Metadata.Title")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: "#F8F9FA", color: "#708195" }}>
                <Typography sx={{ fontWeight: "500", fontSize: "16px", lineHeight: "24px" }}>
                  {t("Integrations.PayButton.Implementation.Add.Metadata.SubTitle")}
                </Typography>
                <Typography sx={{ fontSize: "12px", lineHeight: "20px" }}>
                  {t("Integrations.PayButton.Implementation.Add.Metadata.Description")}
                </Typography>
                <AddKeyValue
                  saveKeys={(newFields) => handleChangesKeyValue(newFields, setMetadata)}
                  required={false}
                  callEdit={edit}
                  data={metadata}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                <Typography sx={{ color: "#4C5866", fontWeight: "500", fontSize: "16px", lineHeight: "24px" }}>
                  {t("Integrations.PayButton.Implementation.Add.Callbacks.TitleCorrect")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: "#F8F9FA", color: "#708195" }}>
                <Callback
                  desciptionRedirect="RedirectCorrect"
                  desciptionCallback="DescriptionCorrect"
                  updateChangesH={(newFields) => handleChangesKeyValue(newFields, setHeadersCorrect)}
                  updateChangesP={(newFields) => handleChangesKeyValue(newFields, setParametersCorrect)}
                  updateChangesB={(newFields) => handleChangesKeyValue(newFields, setBodyCorrect)}
                  dataHeaders={headersCorrect}
                  dataParams={parametersCorrect}
                  dataBody={bodyCorrect}
                  data={callbackCorrect}
                  saveData={(campo, valor) => handleChangeCallback(campo, valor, setCallbackCorrect)}
                  required={true}
                  callEdit={edit}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                <Typography sx={{ color: "#4C5866", fontWeight: "500", fontSize: "16px", lineHeight: "24px" }}>
                  {t("Integrations.PayButton.Implementation.Add.Callbacks.TitleIncorrect")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: "#F8F9FA" }}>
                <Callback
                  desciptionRedirect="RedirectIncorrect"
                  desciptionCallback="DescriptionIncorrect"
                  updateChangesH={(newFields) => handleChangesKeyValue(newFields, setHeadersIncorrect)}
                  updateChangesP={(newFields) => handleChangesKeyValue(newFields, setParametersIncorrect)}
                  updateChangesB={(newFields) => handleChangesKeyValue(newFields, setBodyIncorrect)}
                  dataHeaders={headersIncorrect}
                  dataParams={parametersIncorrect}
                  dataBody={bodyIncorrect}
                  data={callbackIncorrect}
                  saveData={(campo, valor) => handleChangeCallback(campo, valor, setCallbackIncorrect)}
                  required={false}
                  callEdit={edit}
                />
              </AccordionDetails>
            </Accordion>
          </Stack>
        </CardContent>
      </Card>
      <ButtonsIntegrationsConfig
        typeIntegration="PayButton"
        onClickIntegration={props.onClickIntegration}
        edit={edit}
        handleSaveFields={handleSaveFields}
        handleUpdateFields={handleUpdateFields}
      />
    </Stack>
  );
};

export default AddEcommerce;

AddEcommerce.propTypes = {
  dataConfiguration: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickIntegration: PropTypes.func.isRequired,
};
