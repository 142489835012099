import React from "react";
import { useLocation } from "react-router-dom";

import CheckPermisions from "../components/check-permissions/checkPermissions";
import Sesion from "../components/commons/sesion";
import TransferDetail from "../components/insights/transfersDetail";

export default function TransfersDetail() {
  const location = useLocation();
  const type = location.state.type;

  return (
    <CheckPermisions>
      <div className={"Drawer"}>
        <Sesion screen={"Insights"} />
        <TransferDetail type={type} />
      </div>
    </CheckPermisions>
  );
}
