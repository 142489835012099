import { Fragment, React, useEffect, useState, useContext } from "react";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";

import Theme from "../../themes/Theme";

import BalanceDetail from "./balanceDetail";
import { Chart } from "./chart";
import Transfer from "./transfers";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { formatYMD, monthAdd } from "../../utils/validateExpression";
import { SnackBar } from "../commons/alert";
import GenericBackdrop from "../commons/GenericBackdrop";
import { ChatFormat, DataTableFormat } from "../commons/setDataTable";
import { permissionsContext } from "../check-permissions/checkPermissions";

export default function Insights() {
  const permissions = useContext(permissionsContext);
  const [t] = useTranslation("global");
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const useAuth = useAuthUser();
  const auth = useAuth();

  const [loader, setLoader] = useState(false);
  const [balance, setBalance] = useState([]);

  const [chartDataInfo, setChartDataInfo] = useState({
    legend: [],
    data: { labels: [], datasets: [] },
  });
  const [transfersData, setTransfersData] = useState({
    data: [],
    columns: [],
    dataBackUp: [],
    columnsSearch: [],
    datesFilter: [],
  });

  const [branches, setBranches] = useState([]);
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    setLoader(true);

    const getInsights = async () => {
      try {
        const today = new Date();
        const response = await axiosPrivate.get(`insight`, {
          params: {
            date_init: formatYMD(monthAdd(today, -1)),
            date_end: formatYMD(today),
          },
        });

        setBalance(response.data["balance"]);
        setChartDataInfo(ChatFormat(response.data["chart"], t));

        let permissionGranted = "resources.insights.actions.details".split(".").reduce((obj, i) => obj[i], auth?.roles);
        if (!permissionGranted) response.data["table"].columns.pop("Adjustment");

        setTransfersData(DataTableFormat(response.data["table"], t));
        setBranches(response?.data?.branches || []);
        setStatuses(response?.data?.statuses || []);

        setLoader(false);
      } catch (err) {
        setLoader(false);
        navigate("/SignIn", { state: { from: location }, replace: true });
        SnackBar(t("General.Message.ErrorService"), "error");
      }
    };

    getInsights();
  }, []);

  return (
    <Fragment>
      <GenericBackdrop open={loader} />
      <ThemeProvider theme={Theme}>
        {t("Insights.Message.Information")}
        <span style={{ fontWeight: "bold" }}>{t("Insights.Message.FilterCriteria")}</span>
        {permissions?.insights?.actions?.cards &&
          <BalanceDetail balance={balance} />
        }
        <Chart chartDataInfo={chartDataInfo} branches={branches} />
        <Transfer transfersData={transfersData} branches={branches} statuses={statuses} />
      </ThemeProvider>
    </Fragment>
  );
}
