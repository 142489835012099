import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import account from "../../assets/generic/PendingValidate.svg";

import AddBankAccount from "./addBankAccount";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { SnackBar } from "../commons/alert";
import GenericBackdrop from "../commons/GenericBackdrop";

export default function BankAccount() {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [addBankAccount, setAddBankAccount] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [urlImage, setUrlImage] = useState("");
  const axiosPrivate = useAxiosPrivate();

  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const [t] = useTranslation("global");

  const getAccounts = () => {
    handleLoaderOpen();

    axiosPrivate
      .get(`withdrawals/bank-accounts`)
      .then((response) => {
        response.data.results.map((item) => delete item.account_id);
        setData(response.data.results);
        handleLoaderClose();
      })
      .catch((_err) => {
        handleLoaderClose();
        SnackBar(t("General.Message.ErrorService"), "error");
      });
  };

  useEffect(() => {
    if (!addBankAccount) {
      getAccounts();
    }
  }, [addBankAccount]);

  const statementUrl = (type) => {
    let p_bucket = "";
    let p_key = "";
    let view = "view";
    let download = "download";
    let isView = view === type;
    let isDownload = download === type;

    data.forEach((urlInfo) => {
      p_bucket = urlInfo.statement[0];
      p_key = urlInfo.statement[1];
    });
    axiosPrivate
      .get(`merchants/files`, {
        params: {
          bucket: p_bucket,
          key: p_key,
          isView: isView,
          isDownload: isDownload,
        },
      })
      .then((response) => {
        setUrlImage(response.data);
        if (isView) {
          handleOpen();
        } else {
          window.location.assign(response.data);
        }
      })
      .catch((_) => {
        SnackBar(t("General.Message.ErrorService"), "error");
      });
  };

  const typeButtonStatus = (item) => {
    if (item[0] === "status") {
      if (item[1] === "PENDING") {
        return (
          <Grid container>
            <Button
              disabled
              style={{
                borderRadius: "10px",
                textTransform: "none",
                backgroundColor: "#FFEEB5",
                color: "#7D6000",
                borderColor: "white",
                fontWeight: "bold",
                marginTop: "10px",
              }}
              variant="outlined"
              startIcon={<AccessTimeIcon />}
            >
              {t(`Status.${item[1]}`)}
            </Button>
          </Grid>
        );
      } else if (item[1] === "REJECTED") {
        //setRejected(true)
        return (
          <Grid container>
            <Button
              disabled
              style={{
                borderRadius: "10px",
                textTransform: "none",
                backgroundColor: "#EFCCD2",
                color: "#B00020",
                borderColor: "white",
                fontWeight: "bold",
                marginTop: "10px",
              }}
              variant="outlined"
              startIcon={<CloseIcon />}
            >
              {t(`Status.${item[1]}`)}
            </Button>
          </Grid>
        );
      } else {
        return (
          <Grid container>
            <Button
              disabled
              style={{
                borderRadius: "10px",
                textTransform: "none",
                backgroundColor: "#DEF3DD",
                color: "#59C556",
                borderColor: "white",
                fontWeight: "bold",
                marginTop: "10px",
              }}
              variant="outlined"
              startIcon={<DoneIcon />}
            >
              {t(`Status.${item[1]}`)}
            </Button>
          </Grid>
        );
      }
    } else {
      return (
        <Typography
          align="left"
          style={{
            color: "#404A56",
            fontSize: "16px",
            marginTop: "15px",
            fontWeight: 600,
          }}
        >
          {item[1]}
        </Typography>
      );
    }
  };

  function showProofBankAccount() {
    if (data[0] === undefined) {
      return null;
    }
    if (data[0].statement[1].split(".")[1] === "pdf") {
      return <embed src={urlImage} alt="asd" width="100%" height="100%" />;
    } else {
      return <img src={urlImage} alt="asd" width="100%" height="100%" />;
    }
  }

  if (!addBankAccount) {
    return (
      <div className="App">
        <GenericBackdrop open={loader} />
        {data && data.length > 0 ? (
          data.map((bankAccount, index) => (
            <div key={index}>
              <Card
                style={{
                  borderRadius: "16px",
                  boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
                }}
              >
                <CardContent>
                  {Object.entries(bankAccount).map((item, indx) => (
                    <div key={indx}>
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <Typography
                            align="left"
                            style={{
                              color: "#708195",
                              fontSize: "16px",
                              marginTop: "15px",
                            }}
                          >
                            {t(`Account.${item[0]}`)}
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          {item[0] === "statement" ? (
                            <Grid container style={{ textAlign: "left" }}>
                              <Grid>
                                <IconButton
                                  style={{ marginTop: "5px" }}
                                  data-testid="btnView"
                                  aria-label="View"
                                  formtarget="_blank"
                                  onClick={() => statementUrl("view")}
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </Grid>
                              <Grid>
                                <IconButton
                                  style={{ marginTop: "5px" }}
                                  aria-label="Download"
                                  data-testid="btnDownload"
                                  formtarget="_blank"
                                  onClick={() => statementUrl("download")}
                                >
                                  <DownloadIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          ) : (
                            typeButtonStatus(item)
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </CardContent>
              </Card>
              <div>
                {bankAccount.status === "REJECTED" && (
                  <div
                    style={{
                      backgroundColor: "#FFFBEC",
                      borderRadius: "16px",
                      marginTop: "5%",
                      padding: "25px",
                    }}
                  >
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="right">
                      <p>Tu cuenta bancaria ha sido rechazada, por favor intenta subir otra cuenta</p>
                      <div>
                        <Button
                          style={{
                            borderRadius: "8px",
                            textTransform: "none",
                            backgroundColor: "#FFC10E",
                            color: "#404A56",
                            borderColor: "white",
                            fontWeight: "bold",
                            justifyContent: "flex-end",
                          }}
                          variant="outlined"
                          onClick={() => setAddBankAccount(true)}
                          className="action-button"
                        >
                          {t("Buttons.Add")}
                        </Button>
                      </div>
                    </Stack>
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <Card style={{ padding: "5%", borderRadius: "16px", textAlign: "center" }}>
            <CardContent>
              <Typography color={"#404A56"} style={{ fontSize: "24px", fontWeight: 700 }} gutterBottom component="div">
                {t("Account.Message.NoAccount")}
              </Typography>
              <Typography color={"Gray"} style={{ fontSize: "18px" }} gutterBottom component="div">
                {t("Account.Message.AddAccount")}
              </Typography>
              <img src={account} alt={"AddAccount"} />

              <CardActions style={{ justifyContent: "center" }}>
                <Button
                  style={{
                    background: "#FFC10E",
                    textTransform: "none",
                    minWidth: "180px",
                    height: "48px",
                    marginTop: "25px",
                    color: "#404A56",
                  }}
                  onClick={() => setAddBankAccount(true)}
                  className="action-button"
                >
                  {t("Buttons.Add")}
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        )}
        <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
          <DialogContent>{showProofBankAccount()}</DialogContent>
        </Dialog>
      </div>
    );
  } else {
    return <AddBankAccount onClose={() => setAddBankAccount(false)} />;
  }
}
