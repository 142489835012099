import axios from "axios";

const BASE_URL = process.env.REACT_APP_DOMAIN;
const APIKEY = process.env.REACT_APP_DOMAIN_APIKEY;

export default axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": APIKEY,
    Accept: "application/json",
  },
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": APIKEY,
    Accept: "application/json",
  },
  // withCredentials: true,
});
