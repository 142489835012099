import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";

import Questions from "./questions";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { permissionsContext } from "../check-permissions/checkPermissions";
import { Alert } from "../commons/alert";
import GenericBackdrop from "../commons/GenericBackdrop";
import { SearchBack } from "../commons/search";

export default function Topics() {
  const [t] = useTranslation("global");
  const [idTopic, setIdTopic] = useState(null);
  const [textTopic, setTextTopic] = useState(null);
  const [topics, setTopics] = useState([]);
  const [search, setSearch] = useState(null);

  const endPoint = "/help/faqs/search/filter";

  const [loader, setLoader] = useState(false);
  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);
  const axiosPrivate = useAxiosPrivate();
  const permissions = useContext(permissionsContext);

  useEffect(() => {
    if (idTopic === null) {
      handleLoaderOpen();

      axiosPrivate
        .get(`help/topics`)
        .then((response) => {
          setTopics(response.data);
          handleLoaderClose();
        })
        .catch((_err) => {
          handleLoaderClose();
          Alert("", t("General.Message.ErrorService"), "");
        });
    }
  }, [idTopic]);

  const handleTopic = (id, text) => {
    setIdTopic(id);
    setTextTopic(text);
  };

  const getTopics = () => {
    if (topics.length > 0)
      return topics.map((item, index) => (
        <Grid item md={3} sm={6} xs={12} key={index}>
          <Card className="mui-card">
            <CardActionArea
              name="topics"
              sx={{
                minHeight: "150px",
                height: "fit-content",
                borderRadius: "16px",
                padding: "5%",
                ":hover": {
                  border: "1px solid #FFC70D",
                },
              }}
              onClick={() => {
                handleTopic(item.topicid, item.name);
              }}
            >
              <Typography style={{ textAlign: "center" }} component="div" gutterBottom>
                <SvgIcon viewBox="0 -2 18 26" style={{ fontSize: "40px" }}>
                  <path d={item.path} />
                </SvgIcon>
              </Typography>
              <Typography variant="text18" style={{ textAlign: "center" }} component="div" gutterBottom>
                {item.name}
              </Typography>
              <Typography variant="text14" style={{ textAlign: "center" }} component="div" gutterBottom>
                {item.description}
              </Typography>
            </CardActionArea>
          </Card>
        </Grid>
      ));
  };
  
  return (
    <Fragment>
      <GenericBackdrop open={loader} />
      {idTopic !== null || (search !== null && search.search !== null) ? (
        <Questions
          idTopic={idTopic}
          setIdTopic={setIdTopic}
          textTopic={textTopic}
          setTextTopic={setTextTopic}
          topics={topics}
          search={search}
          setSearch={setSearch}
        />
      ) : (
        <Fragment>
          {permissions?.helps?.actions?.search && (
            <Card
              sx={{
                padding: "2em",
                borderRadius: "16px",
                background: "#F4F4F4",
                boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
              }}
            >
              <CardContent sx={{ justifyContent: "center", display: "flex" }}>
                <Typography variant="text24" color="#404A56">
                  {t("Help.HowHelp")}
                </Typography>
              </CardContent>
              <CardActions style={{ justifyContent: "center" }}>
                <SearchBack setSearch={setSearch} endPoint={endPoint} />
              </CardActions>
            </Card>
          )}
          {permissions?.helps?.actions?.view && (
            <Grid container spacing={2} sx={{ borderStyle: "none" }} mt={4}>
              {getTopics()}
            </Grid>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
