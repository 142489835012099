import React, { useContext } from "react";

import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

import { permissionsContext } from "../check-permissions/checkPermissions";

const ActionEditUser = ({ row, editUserActivate, type, activate }) => {
  const permissions = useContext(permissionsContext);
  return (
    <>
      {permissions?.configuration?.users?.actions?.edit && (
        <div
          style={{
            marginLeft: "15px",
            marginRight: "15px",
            color: "#404A56",
          }}
          data-testid={`EditUser_${row.user_id}`}
          onClick={() => editUserActivate(row, type, activate)}
        >
          {<DriveFileRenameOutlineIcon className="Cursor" />}
        </div>
      )}
    </>
  );
};

export default ActionEditUser;
