import React from "react";

import Sesion from "../components/commons/sesion";
import Transfers from "../components/tranfers";

export default function Transfer() {
  return (
    <div className={"Drawer"}>
      <Sesion screen={"Transfer"} />
      <Transfers />
    </div>
  );
}
