import React, { useState } from "react";

import { Box, Collapse, IconButton, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
    borderRadius: 16,
    margin: `${theme.spacing(1.5)}px auto`,
  },
  headerBox: {
    padding: theme.spacing(2, 2),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1, 1),
    },
  },
  titleLabel: {
    color: "#4C5866",
    fontWeight: 600,
    fontSize: 18,
  },
  expandIconBox: {
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.up("xs")]: {
      marginRight: theme.spacing(2),
    },
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  expanded: {
    marginTop: theme.spacing(2),
  },
  pointer: {
    cursor: "pointer",
  },
}));

export default function CollapseBox({ title, body }) {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Box
      className={clsx(classes.root, {
        [classes.pointer]: !expanded,
      })}
      onClick={handleToggle}
    >
      <Box className={classes.headerBox} display="flex" alignItems="center">
        <Box flex={1}>
          <label className={classes.titleLabel}>{title}</label>
        </Box>
        <Box display="flex" alignItems="center" className={classes.expandIconBox}>
          <IconButton
            data-testid="icon-button"
            size="small"
            aria-expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          >
            <ExpandMoreIcon
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
            />
          </IconButton>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          data-testid="collapse-box"
          container
          spacing={2}
          className={classes.expanded}
          onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => event.stopPropagation()}
        >
          {body}
        </Box>
      </Collapse>
    </Box>
  );
}
