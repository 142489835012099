import { RequireAuth, useAuthUser } from "react-auth-kit";
import TagManager from "react-gtm-module";
import { Route, Routes, useLocation } from "react-router-dom";

import "./App.css";

import Layout from "./components/Layout";
import Missing from "./components/Missing";
import RequireRole from "./components/RequireAuth";
import Unauthorized from "./components/Unauthorized";
import Configuration from "./views/Configuration";
import Contract from "./views/Contract";
import ForgotPassword from "./views/ForgotPassword";
import FormKym from "./views/FormKym";
import Help from "./views/Help";
import Insight from "./views/Insight";
import MessageScreen from "./views/MessageScreen";
import PaymentIntegration from "./views/PaymentIntegration";
import Payments from "./views/Payments";
import Privacy from "./views/Privacy";
import ResetPassword from "./views/ResetPassword";
import SideBar from "./views/sideBar";
import SignIn from "./views/SignIn";
import SignUp from "./views/SignUp";
import Transfer from "./views/Transfer";
import TransfersDetail from "./views/TransfersDetail";
import UploadContractScreen from "./views/UploadContract";
import CheckPermisions from "./components/check-permissions/checkPermissions";

const ROLES = {
  Read: "Read",
  Owner: "Owner",
  Standard: "Standard",
  DevUser: "Dev User",
  Viewer: "Viewer",
  Admin: "Admin",
  Legacy: "Legacy",
  Digital: "Digital",
};

function App() {
  const location = useLocation();
  const useAuth = useAuthUser();
  const auth = useAuth();

  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM,
    dataLayer: {
      merchant_name: auth?.businessName,
      merchant_id: auth?.userId,
      event: location.pathname,
    },
  };

  TagManager.initialize(tagManagerArgs);

  return (
    <CheckPermisions>
      <SideBar />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="SignIn" element={<SignIn />} />
          <Route path="SignUp" element={<SignUp />} />
          <Route path="ForgotPassword" element={<ForgotPassword />} />
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="Contract" element={<Contract />} />
          <Route path="Form" element={<FormKym />} />
          <Route path="Privacy" element={<Privacy />} />
          <Route path="MessageScreen" element={<MessageScreen />} />
          <Route path="UploadContractScreen" element={<UploadContractScreen />} />
          <Route
            element={
              <RequireRole
                allowedRoles={[
                  ROLES.Read,
                  ROLES.Owner,
                  ROLES.Standard,
                  ROLES.DevUser,
                  ROLES.Viewer,
                  ROLES.Admin,
                  ROLES.Legacy,
                  ROLES.Digital,
                ]}
              />
            }
          >
            <Route
              path="/"
              element={
                <RequireAuth loginPath={"/SignIn"}>
                  {" "}
                  <Insight />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/Insight"
              element={
                <RequireAuth loginPath={"/SignIn"}>
                  {" "}
                  <Insight />{" "}
                </RequireAuth>
              }
            />
            <Route path="ResetPassword" element={<ResetPassword />} />
            <Route path="Transfer" element={<Transfer />} />
            <Route path="Help" element={<Help />} />
          </Route>
          <Route element={<RequireRole allowedRoles={[ROLES.Owner, ROLES.Standard, ROLES.Admin, ROLES.Digital]} />}>
            <Route path="Configuration" element={<Configuration />} />
          </Route>

          <Route
            element={
              <RequireRole
                allowedRoles={[
                  ROLES.Read,
                  ROLES.Owner,
                  ROLES.Standard,
                  ROLES.DevUser,
                  ROLES.Viewer,
                  ROLES.Admin,
                  ROLES.Legacy,
                  ROLES.Digital,
                ]}
              />
            }
          >
            <Route path="TransfersDetail" element={<TransfersDetail />} />
            <Route path="Payments" element={<Payments />} />
            <Route path="PaymentIntegration" element={<PaymentIntegration />} />
          </Route>
          <Route path="*" element={<Missing />} />
        </Route>
      </Routes>
    </CheckPermisions>
  );
}

export default App;
