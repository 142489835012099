import React, { Fragment, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import axios from "axios";

import GenericBackdrop from "./../components/commons/GenericBackdrop";
import { SnackBar } from "./commons/alert";

import useAxiosPrivate from "../hooks/useAxiosPrivate";

const UploadContract = () => {
  const [loader, setLoader] = useState(false);
  const [t] = useTranslation("global");
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const useAuth = useAuthUser();
  const auth = useAuth();
  const styles = {
    contentView: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      webkitBoxShadow: "1px 3px 1px #9E9E9E",
      mozBoxShadow: "1px 3px 1px #9E9E9E",
      boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
      borderRadius: "16px",
      backgroundColor: "white",
      margin: "0px 72px 0px 72px",
    },

    contractContainer: {
      overflowY: "scroll",
      height: "80%",
      "&::-webkit-scrollbar": {
        width: 11,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#FFFFFF",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#C4C4C4",
        borderRadius: 10,
      },
    },

    containerView: {
      height: "600px",
      zIndex: "auto",
      scroll: "auto",
      marginTop: "100px",
      textAlign: "center",
    },
  };

  function internalOnChange(event) {
    setFile(event.target.files[0]);
  }

  function saveFile() {
    setLoader(true);
    let ext = file.type.split("/")[1];
    axiosPrivate
      .post(`merchants/files`, {
        filename: `contract.${ext}`,
      })
      .then((response) => {
        let url = response.data.url;
        let fields = response.data.fields;
        let formData = new FormData();
        Object.entries(fields).forEach(([k, v]) => {
          formData.append(k, v);
        });

        formData.append("file", file, file.name);

        let instance = axios.create({
          headers: {
            post: {
              "Content-Type": "multipart/form-data",
            },
          },
        });

        delete instance.defaults.headers.common["Authorization"];

        instance.post(url, formData).then((_) => {
          sendData(fields.key);
        });
      })
      .catch((_) => {
        SnackBar(t("General.Message.ErrorService"), "error");
      });
  }
  function sendData(key) {
    axiosPrivate
      .get(`/uploadcontract`, {
        params: {
          email: auth.email,
          filename: key,
        },
      })
      .then(() => {
        goToContract();
        setLoader(false);
      })
      .catch((_) => {
        SnackBar(t("General.Message.ErrorService"), "error");
      });
  }

  const goToContract = () => {
    navigate("/MessageScreen", {
      screen: "MessageScreen",
      state: {
        type: {
          data: {
            image: "pending-validate",
            title: "¡Gracias por completar tu información!",
            subTitle:
              "Nuestro equipo validará tu registro y te notificaremos por correo electrónico una vez que se complete el proceso.",
          },
        },
      },
    });
  };

  return (
    <Fragment>
      <GenericBackdrop open={loader} />
      <Box sx={styles.containerView} mt={12}>
        <Container
          sx={{
            height: "100%",
            paddingTop: "50px",
            textAlign: "center",
            position: "relative",
          }}
          maxWidth={false}
        >
          <Box
            style={{
              height: "100%",
              paddingLeft: "200px",
              paddingRight: "200px",
              marginTop: "20px",
            }}
          >
            <Grid
              container
              spacing={2}
              style={{
                boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
                borderRadius: "10px",
                height: "100%",
              }}
            >
              <Grid item xs={3} style={{ paddingLeft: "0px", paddingTop: "0px" }}>
                <Box
                  style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "10px 0px 0px 10px",
                    paddingTop: "130px",
                  }}
                >
                  <Box style={{ height: "100%", paddingLeft: "10%" }}>
                    <Box
                      style={{
                        float: "left",
                        color: file ? "#00377D" : "#343C46",
                      }}
                    >
                      <RadioButtonCheckedIcon />
                    </Box>
                    <Box
                      style={{
                        float: "left",
                        color: file ? "#00377D" : "#343C46",
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "18px",
                        lineHeight: "20px",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "3px",
                      }}
                    >
                      {" "}
                      Contrato
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={9} style={{ paddingLeft: "0px", paddingTop: "0px" }}>
                <Box
                  style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "#F8F9FA",
                    borderRadius: "0px 10px 10px 0px",
                    textAlign: "left",
                    paddingLeft: "3%",
                  }}
                >
                  <Box style={{ height: "20px" }}></Box>
                  <Box
                    style={{
                      height: "38px",
                      left: "530px",
                      top: "208px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "32px",
                      lineHeight: "38px",
                      color: "#343C46",
                    }}
                  >
                    Carga tu contrato firmado
                  </Box>
                  <Box
                    style={{
                      height: "23px",
                      left: "530px",
                      top: "254px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "20px",
                      lineHeight: "23px",
                      color: "#343C46",
                      marginTop: "1%",
                    }}
                  >
                    Ingresa los siguientes datos necesarios para completar tu información
                  </Box>
                  <Box style={{ marginTop: "40px" }}>
                    <Box style={{ float: "left" }}>
                      <Box
                        style={{
                          borderRadius: "50%",
                          backgroundColor: "rgb(255, 199, 13)",
                          height: "30px",
                          width: "30px",
                          textAlign: "center",
                          paddingTop: "3px",
                          color: "white",
                        }}
                      >
                        <InsertDriveFileOutlinedIcon />
                      </Box>
                    </Box>
                    <Box
                      style={{
                        float: "left",
                        width: "125px",
                        height: "26px",
                        left: "576px",
                        top: "328px",
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "22px",
                        lineHeight: "26px",
                        color: "#343C46",
                        marginLeft: "10px",
                        marginTop: "5px",
                      }}
                    >
                      Documentos
                    </Box>
                  </Box>
                  <Box
                    style={{
                      width: "600px",
                      height: "82px",
                      left: "532px",
                      top: "381px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "22px",
                      color: "#343C46",
                      marginTop: "90px",
                    }}
                  >
                    <Box>
                      Ten a la mano los siguientes documentos escaneados de tu negocio para subirlos a la plataforma y
                      así poder dar de alta tu negocio o empresa.
                    </Box>
                    <Box
                      style={{
                        marginTop: "10px",
                        fontStyle: "ITALIC",
                        fontSize: "14px",
                      }}
                    >
                      * El formato permitido es .pdf con un peso máximo de 10 MB.
                    </Box>
                  </Box>
                  <div
                    style={{
                      padding: "25px 16px",
                      marginTop: "15px",
                      backgroundColor: "#fff",
                      width: "70%",
                      height: "15px",
                      borderRadius: "4px",
                      border: "1px solid #CFD0DF",
                      display: "flex",
                      flexDirection: "row",
                    }}
                    className=""
                  >
                    <CheckCircleIcon
                      style={{
                        color: file ? "#66BC6B" : "#CFD0DF",
                        fontSize: "20px",
                        marginRight: 15,
                      }}
                    />
                    <div style={{ flex: 1 }}>
                      <Typography
                        variant="h5"
                        gutterBottom
                        component="div"
                        sx={{
                          fontWeight: 700,
                          fontSize: "12px",
                          color: "#343C46",
                          float: "left",
                        }}
                      >
                        Contrato firmado
                      </Typography>
                    </div>

                    <div style={{ position: "relative" }}>
                      {!file ? (
                        <Typography
                          variant="h5"
                          gutterBottom
                          component="div"
                          sx={{
                            fontSize: "12px",
                          }}
                        >
                          <a
                            style={{
                              color: "#363853",
                              backgroundColor: "#FABE00",
                              padding: "12px",
                              textDecoration: "none",
                              borderRadius: "4px",
                              fontWeight: 700,
                            }}
                            href="# "
                          >
                            Subir archivo
                          </a>
                        </Typography>
                      ) : (
                        <Typography
                          variant="h5"
                          gutterBottom
                          component="div"
                          sx={{
                            fontSize: "12px",
                          }}
                        >
                          <a
                            style={{
                              color: "#363853",
                              backgroundColor: "#FFF",
                              padding: "12px",
                              textDecoration: "none",
                              borderRadius: "4px",
                              fontWeight: 700,
                              border: "1px solid #FABE00",
                            }}
                            href="# "
                          >
                            Modificar
                          </a>
                        </Typography>
                      )}
                      <input
                        data-testid={"InputFile"}
                        id="file-id"
                        required
                        type="file"
                        multiple
                        accept="image/*,application/pdf"
                        style={{
                          position: "absolute",
                          margin: "0",
                          padding: "0",
                          width: "110px",
                          height: "38px",
                          opacity: "0",
                          outline: "none",
                          cursor: "pointer",
                          left: 0,
                          top: "-11px",
                          background: "red",
                        }}
                        onChange={internalOnChange}
                      />
                    </div>
                  </div>
                  <Box style={{ position: "absolute", bottom: "20px" }}>
                    <Button
                      variant="outlined"
                      style={{
                        background: "white",
                        color: "#404A56",
                        textTransform: "none",
                        minWidth: "180px",
                        borderColor: "#FFC10E",
                        height: "48px",
                      }}
                      onClick={() => {}}
                      className="action-button-outlined"
                    >
                      Atrás
                    </Button>
                    <Button
                      data-testid="btn-next"
                      style={{
                        background: file ? "#FFC10E" : "#CFD5DC",
                        color: file ? "#404A56" : "#808FA1",
                        marginLeft: "10px",
                        textTransform: "none",
                        minWidth: "180px",
                        height: "48px",
                      }}
                      onClick={saveFile}
                      className="action-button"
                      disabled={file ? false : true}
                    >
                      Finalizar
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box style={{ height: "100px" }}></Box>
        </Container>
      </Box>
    </Fragment>
  );
};

export default UploadContract;
