import React from "react";
import { useTranslation } from "react-i18next";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { currencyFormatter } from "../../utils/validateExpression";
import PropTypes from "prop-types";

function CardStyle(title, value = 0) {
  const [t] = useTranslation("global");
  return (
    <Card
      style={{
        width: "40vh",
        height: "112px",
        borderRadius: "16px",
        padding: 0,
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          padding: "8px 16px 8px 23px",
          position: "absolute",
          background: "#FABE00",
          borderRadius: "16px 0 8px 0",
        }}
      >
        <Typography sx={{ color: "#404a56", fontSize: "14px", fontWeight: "600" }}>{title}</Typography>
      </CardContent>
      <Stack direction="row" justifyContent="flex-start" mt={6}>
        <Typography
          sx={{
            margin: "0 24px",
            color: "#404a56",
            fontSize: "28px",
            fontWeight: "500",
          }}
        >
          {currencyFormatter(value, t("Locales.local"), t("Locales.currency"))}
        </Typography>
      </Stack>
    </Card>
  );
}

const BalanceDetail = ({ balance }) => {
  const [t] = useTranslation("global");
  return (
    <Stack direction="row" width="100%" spacing={3} mt={4}>
      {CardStyle(t("Insights.Date.Available"), balance.available)}
    </Stack>
  );
};

export default BalanceDetail;

BalanceDetail.propTypes = {
  balance: PropTypes.arrayOf(
    PropTypes.shape({
      available: PropTypes.string.isRequired,
    })
  ).isRequired,
};
