import React, { useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { SnackBar } from "../commons/alert";
import Cards from "../commons/cards";
import Modal from "../commons/modal";
import DataModal from "../config/my-api-keys-modal.json";
import Data from "../config/my-apy-keys-options.json";
import currentdate from "../utils/currentDate";
import { getClientId } from "../utils/jwt";

const MyApiKey = () => {
  const [t] = useTranslation("global");
  const useAuth = useAuthUser();
  const auth = useAuth();
  const [open, setOpen] = React.useState(false);
  const [dataModal, setDataModal] = useState(DataModal.modal);
  const handleClose = () => setOpen(false);
  const axiosPrivate = useAxiosPrivate();

  const handleClick = async (index) => {
    let newDataModal = { ...dataModal };
    let typeApi;

    if (index === 0) {
      typeApi = "prod";
      newDataModal.title = Data.options[0].title;
    } else {
      typeApi = "dev";
      newDataModal.title = Data.options[1].title;
    }

    try {
      const dynamo = await axiosPrivate.post(`integrations/apikey`, {
        client_id: getClientId(auth),
        name: auth.name,
        date: currentdate(),
      });
      if (dynamo.status === 200 || dynamo.status === 204) {
        const result = await axiosPrivate.post(`merchants/api-keys`, {
          client_id: getClientId(auth),
          api: typeApi,
        });
        newDataModal.buttonWithCopy.input.value = result.data;
        setDataModal(newDataModal);
        setOpen(true);
      }
    } catch (error) {
      SnackBar(t("General.Message.ErrorService"), "error");
    }
  };

  return (
    <div>
      <Modal open={open} handleClose={handleClose} data={dataModal} />
      <Grid container spacing={0} direction="column">
        <Grid container spacing={0} direction="column">
          <Typography gutterBottom style={{ fontWeight: 700, fontSize: "24px", color: "#404A56" }}>
            {t("ApiIntegrations.MyApiKeys.Title")}
          </Typography>
          <Typography style={{ fontWeight: 300, fontSize: "14px" }}>{t("ApiIntegrations.MyApiKeys.Text")}</Typography>
        </Grid>
      </Grid>

      <div className="App">
        <Cards data={Data.options} onClick={handleClick} />
      </div>
    </div>
  );
};

export default MyApiKey;
