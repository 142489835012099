import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Card, CardContent, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { v4 as uuidv4 } from "uuid";

import Reverse from "./reverse";

import { permissionsContext } from "../check-permissions/checkPermissions";
import checkPermission from "../utils/checkPermission";

export default function TransfersDetail(props) {
  const [t] = useTranslation("global");
  const [component, setComponentDetail] = useState(true);
  const permissions = useContext(permissionsContext);

  useEffect(() => {
    if (props) {
      setButtons(props.type.status);
    }
  }, []);

  const setButtons = (status) => {
    if (status === "OK") {
      return (
        <Grid item>
          <Button
            disabled
            style={{
              borderRadius: "10px",
              textTransform: "none",
              backgroundColor: "#DEF3DD",
              color: "#59C556",
              borderColor: "white",
              fontWeight: "bold",
              marginTop: "15px",
            }}
            variant="outlined"
            startIcon={<CheckIcon />}
          >
            {t(`Insights.Transactions.Table.StatusLiquidated`)}
          </Button>
        </Grid>
      );
    } else if (status === "ERROR") {
      return (
        <Grid item>
          <Button
            disabled
            style={{
              borderRadius: "10px",
              textTransform: "none",
              backgroundColor: "#EFCCD2",
              color: "#B00020",
              borderColor: "white",
              fontWeight: "bold",
              marginTop: "15px",
            }}
            variant="outlined"
            startIcon={<CloseIcon />}
          >
            {t(`Insights.Transactions.Table.StatusError`)}
          </Button>
        </Grid>
      );
    } else if (status === "REVERTED") {
      return (
        <Button
          disabled
          style={{
            borderRadius: "10px",
            textTransform: "none",
            backgroundColor: "#FFEEB5",
            color: "#7D6000",
            borderColor: "white",
            fontWeight: "bold",
            marginTop: "15px",
          }}
          variant="outlined"
          startIcon={<AttachMoneyIcon />}
        >
          {t(`Insights.Transactions.Table.StatusRefunded`)}
        </Button>
      );
    } else {
      return (
        <Button
          disabled
          style={{
            borderRadius: "10px",
            textTransform: "none",
            backgroundColor: "#FFEEB5",
            color: "#7D6000",
            borderColor: "white",
            fontWeight: "bold",
            marginTop: "15px",
          }}
          variant="outlined"
          startIcon={<AttachMoneyIcon />}
        >
          {t(`Insights.Transactions.Table.StatusReversed`)}
        </Button>
      );
    }
  };

  return (
    <Fragment>
      {component ? (
        <Stack spacing={2}>
          {" "}
          <Card
            sx={{
              boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
              borderRadius: "16px",
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{
                  paddingTop: "32px",
                  fontWeight: 600,
                  fontSize: "18px",
                  color: "#404A56",
                }}
              >
                {t("Insights.Transactions.DetailTransactions")}
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  <Grid container spacing={0}>
                    {Object.entries(props.type).map(
                      (item, _index) =>
                        !item[0].endsWith("findByStav") && (
                          <React.Fragment key={_index}>
                            <Grid item xs={6} key={uuidv4()}>
                              <Typography
                                align="left"
                                style={{
                                  color: "#708195",
                                  fontSize: "16px",
                                  marginTop: "15px",
                                  fontWeight: "400",
                                }}
                              >
                                {t(`Insights.Transactions.Table.${item[0]}`)}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              {item[0] === "status" ? (
                                setButtons(item[1])
                              ) : (
                                <Typography
                                  align="left"
                                  style={{
                                    color: "#404A56",
                                    fontSize: "16px",
                                    marginTop: "15px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {item[1]}
                                </Typography>
                              )}
                            </Grid>
                          </React.Fragment>
                        )
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <div
                        style={{
                          float: "right",
                          height: "96px",
                          width: "354px",
                          left: "846px",
                          top: "32px",
                        }}
                      >
                        <Stack spacing={2}>
                          <Card
                            style={{
                              backgroundColor: "rgba(239, 241, 243, 0.3)",
                              borderRadius: "16px",
                              boxShadow: "none",
                              border: "1px solid #EFF1F3",
                            }}
                          >
                            <CardContent>
                              <Stack spacing={2}>
                                <Typography
                                  className="text"
                                  style={{
                                    display: "inline",
                                    fontSize: "14px",
                                    color: "#708195",
                                  }}
                                >
                                  {t(`Insights.Transactions.TotalAmount`)}
                                </Typography>
                                <Typography
                                  className="text"
                                  style={{
                                    display: "inline",
                                    fontSize: "28px",
                                    color: "#404A56",
                                  }}
                                >
                                  {props.type.amount}
                                </Typography>
                              </Stack>
                            </CardContent>
                          </Card>
                          {permissions?.insights?.actions?.cardsDetails && (
                            <Card style={{ borderRadius: "16px" }}>
                              <CardContent>
                                <Stack spacing={2}>
                                  <Typography
                                    className="text"
                                    style={{
                                      display: "inline",
                                      height: "5px",
                                      fontSize: "14px",
                                      color: "#708195",
                                    }}
                                  >
                                    {t(`Insights.Transactions.ActualAmount`)}
                                  </Typography>
                                  <Typography
                                    className="text"
                                    style={{
                                      display: "inline",
                                      fontSize: "28px",
                                      color: "#404A56",
                                    }}
                                  >
                                    {props.type.amount}
                                  </Typography>
                                </Stack>
                              </CardContent>
                            </Card>
                          )}
                        </Stack>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Stack spacing={4} direction="row" justifyContent="flex-end" alignItems="flex-start">
            <Button
              data-testid="buttonBack"
              variant="outlined"
              onClick={() => window.history.back()}
              style={{
                background: "white",
                color: "#404A56",
                textTransform: "none",
                minWidth: "90px",
                maxWidth: "102px",
                borderColor: "#FFC10E",
                height: "40px",
                fontSize: "16px",
              }}
              className="action-button-outlined"
            >
              {t(`Buttons.Back`)}
            </Button>
            {props.type.status === "OK" && checkPermission("resources.insights.actions.adjustment") && (
              <Button
                data-testid="buttonAdjustment"
                onClick={() => setComponentDetail(false)}
                style={{
                  background: "#FFC10E",
                  color: "#404A56",
                  marginLeft: "10px",
                  textTransform: "none",
                  minWidth: "90px",
                  height: "40px",
                }}
                className="action-button"
              >
                {t(`Insights.Transactions.Adjustment`)}
              </Button>
            )}
          </Stack>
        </Stack>
      ) : (
        <Reverse props={props} setComponentDetail={setComponentDetail} showPin={false} />
      )}
    </Fragment>
  );
}
