const hn = `
  <style>
    .container {
      margin-top: 10px;
    }
    .text {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      color: #565656;
      text-align: justify;
    }
    th, td {
      border:1px solid #CCC;
      padding: 10px;
    }
  </style>

  <div class="text">
  Integraciones Electrónicas de Honduras, S.A. (en adelante “INTELEC”) como entidad responsable del tratamiento de los datos personales de los usuarios que se registren en la plataforma y de las personas que contacten con INTELEC a través de los formularios presentes en su plataforma a efecto de poder ofrecer los servicios solicitados por los usuarios a través de esta, reconoce la importancia de proteger la privacidad y confidencialidad de los datos de los usuarios (en adelante “el usuario” o “usted”), y le ofrece este Aviso de Privacidad (el “Aviso”), diseñado en cumplimiento con nuestras políticas globales de protección de datos, con la normativa y legislación  de Honduras. 
  </div>

  <div class="text container">
  Al contratar nuestros servicios y visitar la plataforma, INTELEC puede recolectar cierta información sobre usted. Este Aviso le informa acerca del tratamiento de sus datos personales, en particular (1) qué datos recopilamos sobre usted, (2) cómo los recopilamos, (3) con qué finalidad, (4) cómo puede acceder a ellos o rectificarlos, (5) con quién se comparten, (6) por cuánto tiempo se conservan, (7) qué ley aplica, y (8) cuáles son los datos de contacto de INTELEC, como responsable del tratamiento de sus datos personales.
  </div>
  
  <div class="text container">
  <strong>Es posible que INTELEC realice cambios al Aviso de manera periódica. En el evento en que realicemos cambios sustanciales al Aviso, INTELEC publicara dichos cambios a través de la plataforma. Usted podrá determinar cuándo fue la última revisión del Aviso en el “historial de versiones” al final del documento.<strong>
  </div>

  <div class="text container">
    <strong>1. ¿Qué datos personales recopilamos sobre usted?</strong>
    <div class="container">
    Con el fin de ofrecerle y prestarle nuestros productos o servicios, o para darle acceso a nuestra plataforma, INTELEC puede recopilar los siguientes datos personales:
    </div>
    <div class="container">
      <ul>
        <li class="container">
        <strong>Información de registro:</strong> su nombre completo, número de teléfono, dirección de correo electrónico, información de pago y financiera, dirección de facturación, usuario y contraseña.
        </li>
        <li class="container">
        <strong>Información sobre el uso de la Plataforma:</strong> la dirección de red y el sistema operativo de su computadora, tipo de navegador utilizado, el sitio web desde el cual se vinculó a nuestra página, su actividad en nuestra plataforma, así como el historial de visualización, la hora y la fecha en la cual visitó la plataforma
        </li>
        <li class="container">
        <strong>Información sobre su ubicación:</strong> Asimismo, INTELEC le informa que no solicita ni procesa, dentro de su conocimiento, datos personales a/de menores de 18 años.
        </li>
      </ul>
    </div>
  </div>

  <div class="text container">
    <strong>2. ¿Cómo recopilamos sus datos personales?</strong>
    <div class="container">
    Sus datos personales los obtenemos o bien porque usted nos los proporciona directamente al contratar servicios, cuando usted navega por la plataforma o utiliza nuestros servicios de diferentes maneras; o porque los obtenemos a través de terceros, o a través de cookies u otras tecnologías de recolección de datos.
    </div>
    <div class="container">
    Las “cookies” y tecnologías similares son un pequeño fichero de letras y cifras que se almacena en el navegador del usuario al acceder a determinadas páginas web, y que INTELEC puede utilizar con el objetivo de mejorar su experiencia y navegación en la plataforma, obtener información estadística sobre sus hábitos de navegación, y ofrecerles publicidad adaptada a sus preferencias, entre otros.
    </div>
    <div class="container">
    Las “cookies” pueden ser de sesión, que se eliminarán al terminar la sesión, o persistentes, que permanecerán en su ordenador o dispositivo durante un periodo de tiempo determinado. Modificando las preferencias de su explorador web, usted tiene la opción de aceptar todas la “cookies”, de ser notificado cuando se instalan, o de deshabilitarlas. No obstante, si usted elige rechazar las “cookies”, es posible que algunas funciones de la plataforma no estén disponibles para su utilización o que su funcionamiento no sea óptimo.
    </div>
  </div>

  <div class="text container">
    <strong>3. ¿Con qué finalidad tratamos sus datos personales?</strong>
    <div class="container">INTELEC puede tratar sus datos personales para las siguientes finalidades:</div>
    <ul>
      <li>
      <u>Adecuada prestación de los servicios:</u> Comprende toda actividad dirigida a la prestación de los productos y/o servicios contratados por el usuario y a analizar y desarrollar la factibilidad, contratación, instalación y ejecución del servicio, en aspectos tales como: determinación de consumos, mantenimiento y mejoramiento de servicios y de la plataforma, atención al cliente, personalización del contenido, servicios y ofertas, planes comerciales, satisfacción del cliente, creación de bases de datos, análisis de información y datos, creación de indicadores clave de rendimiento de aplicación (KPI), facturación, seguridad, prevención de fraude, lavado de activos, financiamiento al terrorismo y otras actividades ilegales de conformidad a la ley vigente del país, control de calidad y, en general, toda información indispensable para cumplir con nuestros contratos de productos o servicios, la regulación y la normatividad vigente aplicable
      </li>
      <li class="container">
      <u>Fines Comerciales de INTELEC:</u> Comprende toda actividad encaminada a presentar ofertas, promociones, productos, anuncios, publicidad, oportunidades, sorteos, campañas, programas de lealtad, fidelización, retención de clientes y, en general, información de productos y servicios nuevos, ya solicitados o contratados, o que puedan ser de interés de los usuarios.
      </li>
      <li class="container">
      <u>Relacionamiento con público de interés.</u> Comprende toda actividad orientada al manejo de las relaciones con nuestras afiliadas y/o subsidiarias, accionistas, autoridades y comunidad en general, de conformidad con el marco jurídico vigente, en desarrollo de la gestión empresarial para el adecuado cumplimiento del objeto social.
      </li>
      <li class="container">
      <u>Fines legales:</u> comprende todo tratamiento justificado por la necesidad de cumplir con los requisitos legales aplicables, como son el atender un requerimiento de una entidad gubernamental o administrativa en ejercicio de sus funciones o por orden escrita de autoridad judicial competente, o para proteger los derechos, la propiedad o la seguridad de INTELEC, nuestros clientes, empleados y el público en general, sean estos presentes y/o futuros.
      </li>
    </ul>
  </div>
  
  <div class="text container">
    <strong>4. ¿Cómo puede acceder o rectificar sus datos personales?</strong>
    <div class="container">
    Usted puede solicitar el acceso, actualización y/o rectificación de sus datos personales a INTELEC contactándonos a través de nuestro número de Atención marcando el *611.
    </div>
  </div>

  <div class="text container">
    <strong>5. ¿Con quién compartimos sus datos personales?</strong>
    <div class="container">
    INTELEC puede comunicar o transferir sus datos personales a terceros proveedores (por ejemplo, para almacenamiento y/o análisis de datos), a socios comerciales, o en caso de una fusión, adquisición, venta de activos de la compañía o transición del servicio a otro proveedor. Además de lo anterior, INTELEC puede comunicar o transferir sus datos por orden escrita de autoridad judicial competente o cuando así sea permitido o requerido en cumplimiento con la ley. Asimismo, INTELEC puede compartir o transferir sus datos personales con otras jurisdicciones.
    </div>
    <div class="container">
    El uso, comunicación y transferencia de la información proporcionada se realizará respetando su confidencialidad. INTELEC utiliza medidas de seguridad razonables, para evitar la alteración, perdida o el tratamiento no autorizado de sus datos personales.
    </div>
  </div>

  <div class="text container">
    <strong>6. ¿Por cuánto tiempo se guardan sus datos personales?</strong>
    <div class="container">
    INTELEC guarda sus datos personales únicamente por el período de tiempo que sea necesario para cumplir con la finalidad por la cual se recopilaron, y conforme a lo requerido por la regulación local vigente.
    </div>
  </div>
  
  <div class="text container">
    <strong>7. Ley Aplicable y Jurisdicción</strong>
    <div class="container">
    La interpretación, uso, alcance y terminación de este Aviso se rige por las leyes de Honduras y, en caso de controversia en relación con el presente contrato, cualquiera que fuere la causa alegada, se resolverá de forma amistosa entre las partes. En caso de que no haya una solución, las partes se someterán al procedimiento de arbitraje en Derecho de conformidad a las reglas del Centro de Conciliación y Arbitraje de la Cámara de Comercio e Industrias de Tegucigalpa (CCIT).
    </div>
    <div class="container">
    Las partes de común acuerdo fijan el número de árbitros en tres (3) y su nombramiento se hará uno por cada parte y uno por parte del Centro de Conciliación y Arbitraje adscrito al CCIT tomando en cuenta su especialidad entre los inscritos en el libro oficial de árbitros. Cada una de las partes correrá con sus gastos de arbitraje, debiendo las mismas cubrir con los honorarios de los árbitros nombrados en los términos de este contrato y demás gastos de arbitraje en partes iguales.
    </div>
    <div class="container">
    El laudo arbitral que emita en virtud del proceso el tribunal arbitral será definitivo, siendo por lo tanto el mismo de ejecución inmediata. En caso de que alguna de las partes interponga Recurso de Nulidad contra el Laudo Arbitral proferido por el Tribunal Arbitral respectivo, este recurso se tramitará y decidirá ante un Nuevo Tribunal Arbitral que se nombrará e instalará de conformidad a lo dispuesto en el Reglamento del Centro de Conciliación y Arbitraje de la CCIT. El Tribunal de alzada se constituirá únicamente para conocer del Recurso de Nulidad interpuesto.
    </div>
  </div>

  <div class="text container">
    <strong>8. Datos de Contacto de INTELEC</strong>
    <div class="container">
    Para más información sobre este aviso de Privacidad, sobre los derechos y posibilidades disponibles para proteger sus datos personales, puedes contactarnos a través de nuestro número de Atención marcando el *611.
    </div>
  </div>
  
  <div class="text container">
    <strong>9. Historial de Versiones</strong>
    <div class="container">
      <table style="width:100%" cellspacing="0">
        <tr>
          <th>Versión</th>
          <th>Fecha del Cambio</th>
          <th>Descripción</th>
        </tr>
        <tr>
          <td>1</td>
          <td>16 de septiembre, 2022</td>
          <td>Creación del Aviso</td>
        </tr>
      </table>
    </div>
  </div>
`;

const countryPrivacyNotices = {
  HONDURAS: hn,
  SALVADOR: hn,
  BOLIVIA: hn,
  PARAGUAY: hn,
  es: hn,
  en: hn,
};

const GenericPrivacyNotices = ({ country }) => {
  const PrivacyComponent = countryPrivacyNotices[[country]];
  if (!PrivacyComponent) {
    return null;
  }
  return <div dangerouslySetInnerHTML={{ __html: PrivacyComponent }} />;
};

export default GenericPrivacyNotices;
