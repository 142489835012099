import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  components: {
    MuiCheckbox: {
      checked: {
        backgroundColor: "#0067ED",
      },
      colorPrimary: {
        color: "#0067ED",
        "&$checked": {
          color: "#0067ED",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "contained" },
          style: {
            textTransform: "none",
            backgroundColor: "#FFC10E",
            color: "#404A56",
            width: "100%",
            fontFamily: "Roboto",
            fontSize: "16px",
            fontWeight: "500",
            borderRadius: "8px",
            boxShadow: "none",
          },
        },
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            border: "1px solid #FFC10E",
            backgroundColor: "#FFFFFF",
            color: "#404A56",
            width: "100%",
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            height: "4.2vh",
            borderRadius: "1vh",
            border: "0.10vh solid #FFC10E",
            color: "#343C46",
            textTransform: "none",
          },
        },
      ],
    },
  },
  typography: {
    allVariants: {
      fontFamily: ["Roboto"].join(","),
      textTransform: "none",
      color: "#404A56",
    },
    title: {
      fontSize: "42px",
      fontWeight: "600",
      color: "#404A56",
    },
    subTitle: {
      fontSize: "16px",
      fontWeight: "600",
      color: "#404A56",
    },
    subTitle18: {
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "21px",
      color: "#404A56",
    },
    subTitle18_700: {
      fontWeight: "700",
      fontSize: "18px",
      lineHeight: "18px",
      color: "#404A56",
    },
    text14: {
      fontWeight: "400",
      fontSize: "14px",
      color: "#404A56",
    },
    text18: {
      fontWeight: "500",
      fontSize: "18px",
      color: "#404A56",
    },
    text24: {
      fontWeight: "700",
      fontSize: "24px",
      color: "#404A56",
    },
    text28: {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "28px",
      lineHeight: "33px",
      color: "#404A56",
    },
    body: {
      fontSize: "16px",
      fontWeight: "300",
      color: "#565656",
    },
    checkbox: {
      fontSize: "16px",
      fontWeight: "400",
      color: "#565656",
    },
    textPathDoc: {
      fontWeight: "400",
      fontSize: "18px",
      color: "#404A56",
    },
    textMethodDoc: {
      fontWeight: "600",
      fontSize: "12px",
      color: "#FFFFFF",
    },
    textDescDoc: {
      fontWeight: "300",
      fontSize: "14px",
      color: "#404A56",
    },
    titleFieldIntegration: {
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#708195",
    },
    descFieldIntegration: {
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "20px",
      color: "#708195",
    },
  },
  tableStyle: {
    tableHeaders: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "700",
      fontWeightImportant: "700 !important",
      fontSize: "16px",
      lineHeight: "18px",
      color: "#343C46 !important",
    },
    tableBody: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#404A56 !important",
    },
  },
});

export default Theme;
