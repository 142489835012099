import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import AddKeyValue from "../commons/addKeyValue";

const Callback = (props) => {
  const [t] = useTranslation("global");
  const [campos, setCampos] = useState({
    redirect: "",
    callback: "",
    method: "",
  });

  const handleCampoChange = (campo, value) => {
    setCampos((prevCampos) => ({
      ...prevCampos,
      [campo]: value,
    }));
    props.saveData(campo, value);
  };

  useEffect(() => {
    const loadData = (data) => {
      setCampos({
        redirect: data.redirect,
        callback: data.callback,
        method: data.method,
      });
    };

    if (props.data) {
      loadData(props.data);
    }
  }, [props.data]);

  return (
    <Stack>
      <Stack sx={{ color: "#708195" }} spacing={2}>
        <Typography sx={{ fontWeight: "500", fontSize: "16px", lineHeight: "24px" }}>
          {t("Integrations.PayButton.Implementation.Add.Callbacks.Redirect")}
        </Typography>
        <Typography sx={{ fontSize: "12px", lineHeight: "20px" }}>
          {t(`Integrations.PayButton.Implementation.Add.Callbacks.${props.desciptionRedirect}`)}
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center" sx={{ margin: "12px" }}>
          <TextField
            fullWidth
            label={t("Integrations.PayButton.Implementation.Add.Callbacks.UrlRedirect")}
            size="small"
            data-testid="test_redirect"
            required={props.required}
            value={campos.redirect}
            onChange={(e) => handleCampoChange("redirect", e.target.value)}
            sx={{ backgroundColor: "#FFFFFF", margin: "12px" }}
          />
        </Stack>
      </Stack>
      <Stack sx={{ color: "#708195", marginTop: "12px" }} spacing={2}>
        <Typography sx={{ fontWeight: "500", fontSize: "16px", lineHeight: "24px" }}>
          {t("Integrations.PayButton.Implementation.Add.Callbacks.Callback")}
        </Typography>
        <Typography sx={{ fontSize: "12px", lineHeight: "20px" }}>
          {t(`Integrations.PayButton.Implementation.Add.Callbacks.${props.desciptionCallback}`)}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center" sx={{ margin: "12px" }}>
        <TextField
          fullWidth
          label={t("Integrations.PayButton.Implementation.Add.Callbacks.UrlCallback")}
          size="small"
          required={true}
          value={campos.callback}
          onChange={(e) => handleCampoChange("callback", e.target.value)}
          sx={{ backgroundColor: "#FFFFFF" }}
        />
        <FormControl sx={{ m: 1, width: "25ch", paddingLeft: "20px", paddingBottom: "20px" }}>
          <FormLabel id="demo-row-radio-buttons-group-label">
            {t("Integrations.PayButton.Implementation.Add.Callbacks.Method")}
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={campos.method}
            onChange={(e) => handleCampoChange("method", e.target.value)}
          >
            <FormControlLabel value="GET" control={<Radio required={true} />} label="Get" />
            <FormControlLabel value="POST" control={<Radio required={true} />} label="Post" />
          </RadioGroup>
        </FormControl>
      </Stack>
      <Stack sx={{ color: "#708195" }}>
        <Typography sx={{ fontWeight: "500", fontSize: "16px", lineHeight: "24px" }}>
          {t("Integrations.PayButton.Implementation.Add.Callbacks.Headers")}
        </Typography>
        <Typography sx={{ fontSize: "12px", lineHeight: "20px" }}>
          {t("Integrations.PayButton.Implementation.Add.Callbacks.HeadersDescription")}
        </Typography>
      </Stack>
      <AddKeyValue
        saveKeys={props.updateChangesH}
        required={false}
        data={props.dataHeaders}
        callEdit={props.callEdit}
      />
      <Stack sx={{ color: "#708195" }}>
        <Typography sx={{ fontWeight: "500", fontSize: "16px", lineHeight: "24px" }}>
          {t("Integrations.PayButton.Implementation.Add.Callbacks.Parameters")}
        </Typography>
        <Typography sx={{ fontSize: "12px", lineHeight: "20px" }}>
          {t("Integrations.PayButton.Implementation.Add.Callbacks.ParametersDescription")}
        </Typography>
      </Stack>
      <AddKeyValue saveKeys={props.updateChangesP} required={false} data={props.dataParams} callEdit={props.callEdit} />
      <Stack sx={{ color: "#708195" }}>
        <Typography sx={{ fontWeight: "500", fontSize: "16px", lineHeight: "24px" }}>
          {t("Integrations.PayButton.Implementation.Add.Callbacks.Body")}
        </Typography>
        <Typography sx={{ fontSize: "12px", lineHeight: "20px" }}>
          {t("Integrations.PayButton.Implementation.Add.Callbacks.BodyDescription")}
        </Typography>
      </Stack>
      <AddKeyValue saveKeys={props.updateChangesB} required={true} data={props.dataBody} callEdit={props.callEdit} />
    </Stack>
  );
};

Callback.propTypes = {
  data: PropTypes.array.isRequired,
  saveData: PropTypes.func.isRequired,
  desciptionRedirect: PropTypes.string.isRequired,
  desciptionCallback: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  updateChangesH: PropTypes.func.isRequired,
  updateChangesP: PropTypes.func.isRequired,
  updateChangesB: PropTypes.func.isRequired,
  dataHeaders: PropTypes.array.isRequired,
  dataParams: PropTypes.array.isRequired,
  dataBody: PropTypes.array.isRequired,
  callEdit: PropTypes.bool.isRequired,
};

export default Callback;
