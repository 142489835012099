import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Card, CardContent, Divider, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

import Redirect from "./redirect";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Alert } from "../../commons/alert";
import GenericBackdrop from "../../commons/GenericBackdrop";
import AddKeyValue from "../commons/addKeyValue";
import ButtonsIntegrationsConfig from "../commons/buttonsIntegrationsConfig";
import { convertKeyValue, creatObjetFormat } from "../utils/utils";
import DataConfig from "../../config/integrations-config-options.json";

const AddReservationOTP = (props) => {
  const [t] = useTranslation("global");
  const axiosPrivate = useAxiosPrivate();
  const defaultValues = DataConfig.otp?.defaultValues;
  const [loader, setLoader] = useState(false);
  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);
  const [edit, setEdit] = useState(false);
  const [parameters, setParameters] = useState(defaultValues.parameters);
  const [redirectData, setRedirectData] = useState({
    url: "",
    message: "",
  });

  const addDataConfigParameters = (dataParameters) => {
    if (defaultValues.parameters.length > 0) {
      defaultValues.parameters.forEach((param) => {
        const searchIndex = dataParameters.findIndex((item) => item.key == param.key);
        dataParameters[searchIndex] = param;
      });
      return dataParameters;
    } else {
      return dataParameters;
    }
  };

  useEffect(() => {
    const getConfiguration = (redirectConfig) => {
      const dataParameters = convertKeyValue(redirectConfig.redirect?.params);
      setParameters(addDataConfigParameters(dataParameters));
      setRedirectData({
        url: redirectConfig.redirect.url,
        message: redirectConfig.redirect.message,
      });
    };

    if (props.dataConfiguration && !edit) {
      setEdit(true);
      getConfiguration(props.dataConfiguration);
    }
  }, [props.dataConfiguration, edit]);

  const handleChangesKeyValue = (newFields, fieldSetter) => {
    fieldSetter(newFields);
  };

  const handleChangeCallback = (campo, valor, fieldSetter) => {
    fieldSetter((prevCampos) => ({
      ...prevCampos,
      [campo]: valor,
    }));
  };

  const handleSaveFields = () => {
    handleLoaderOpen();
    if (validateFields()) {
      const body = {
        params: JSON.parse(JSON.stringify(creatObjetFormat(parameters, 0))),
        url_redirect: redirectData.url,
        message_redirect: redirectData.message,
      };
      axiosPrivate
        .post("integration/otp", body)
        .then((response) => {
          handleLoaderClose();
          Alert(t("Integrations.PayButton.Implementation.Message.SavedSuccess"), "success");
          props.onClose(response.data);
        })
        .catch((_) => {
          handleLoaderClose();
          return Alert(t("General.Message.ErrorService"), "error");
        });
    } else {
      handleLoaderClose();
      Alert(t("Integrations.PayButton.Implementation.Message.FieldsRequire"), "warning");
    }
  };

  const handleUpdateFields = () => {
    handleLoaderOpen();
    if (validateFields()) {
      const body = {
        params: JSON.parse(JSON.stringify(creatObjetFormat(parameters, 0))),
        url_redirect: redirectData.url,
        message_redirect: redirectData.message,
      };
      axiosPrivate
        .put("integration/otp", body)
        .then((response) => {
          handleLoaderClose();
          Alert(t("Integrations.PayButton.Implementation.Message.UpdateSuccess"), "success");
          props.onClose(response.data);
        })
        .catch((_) => {
          handleLoaderClose();
          return Alert(t("General.Message.ErrorService"), "error");
        });
    } else {
      handleLoaderClose();
      Alert(t("Integrations.PayButton.Implementation.Message.FieldsRequire"), "warning");
    }
  };

  const validateFields = () => {
    const validations = [
      {
        fields: [redirectData.message, redirectData.url],
      },
    ];
    const invalidValidations = validations.filter((validation) => validation.fields.some((field) => field === ""));

    return invalidValidations.length === 0;
  };

  return (
    <Stack style={{ fontFamily: "Roboto" }}>
      <GenericBackdrop open={loader} />
      <Card>
        <CardContent sx={{ margin: "10px 30px 10px 30px" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: "18px", lineHeight: "32px" }}>
            {t("Integrations.ReservationOTP.Implementation.SectionTitle.Configuration")}
          </Typography>
          <CardContent
            sx={{
              backgroundColor: "#F8F9FA",
              color: "#708195",
              marginTop: "32px",
              borderRadius: "0px 0px 8px 8px",
              borderTop: 0.5,
              gap: "24px",
              borderColor: "#C4C6C8",
              boxShadow: "0 4px 12px rgba(194, 209, 217, 0.46)",
            }}
          >
            <Stack spacing={2}>
              <Redirect
                data={redirectData}
                saveData={(campo, valor) => handleChangeCallback(campo, valor, setRedirectData)}
                required={true}
                callEdit={edit}
              />
              <Divider variant="middle" />
              <Typography variant="titleFieldIntegration">
                {t("Integrations.ReservationOTP.Implementation.Add.CustomParams.Title")}
              </Typography>
              <Typography variant="descFieldIntegration">
                {t("Integrations.ReservationOTP.Implementation.Add.CustomParams.Description")}
              </Typography>
              <AddKeyValue
                saveKeys={(newFields) => handleChangesKeyValue(newFields, setParameters)}
                required={false}
                callEdit={edit}
                data={parameters}
                itemMax={5}
              />
            </Stack>
          </CardContent>
        </CardContent>
      </Card>
      <ButtonsIntegrationsConfig
        typeIntegration="ReservationOTP"
        onClickIntegration={props.onClickIntegration}
        edit={edit}
        handleSaveFields={handleSaveFields}
        handleUpdateFields={handleUpdateFields}
      />
    </Stack>
  );
};

export default AddReservationOTP;

AddReservationOTP.propTypes = {
  dataConfiguration: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickIntegration: PropTypes.func.isRequired,
};
