import { useTranslation } from "react-i18next";

import { Box, Button, Grid } from "@mui/material";

export function DownloadContract({ handleDownload, handleNextUpload }) {
  const [t] = useTranslation("global");

  return (
    <Box sx={{ height: "180px", margin: "2% 5% 0 5%" }} mt={5}>
      <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
        <Grid item xs={2} md={2}>
          <Button
            variant="contained"
            size="large"
            onClick={handleDownload}
            className="action-button"
            data-testid="btn-download-contract"
          >
            {t("Contract.DownloadButton")}
          </Button>
        </Grid>
        <Grid item xs={2} md={2}>
          <Button
            variant="contained"
            size="large"
            onClick={handleNextUpload}
            className="action-button"
            data-testid="btn-continuar"
          >
            {t("Contract.NextUploadButton")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
