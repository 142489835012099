export function validateexp(currentvalue, exp, value, set, description) {
  if (exp) {
    if (exp.test(currentvalue)) {
      set({
        ...value,
        value: currentvalue,
        error: false,
        errorDescription: "",
      });
      return true;
    } else {
      set({ ...value, error: true, errorDescription: description });
    }
  }
}

export function simpleValidateExp(currentvalue, exp, value, set) {
  if (exp) {
    if (exp.test(currentvalue)) {
      set({
        ...value,
        value: currentvalue,
        error: false,
      });
      return true;
    } else {
      set({ ...value, error: true });
    }
  }
}

export function currencyFormatter(number, local, currency) {
  return new Intl.NumberFormat(local, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: local === "es-PY" ? 0 : 2,
    maximumFractionDigits: local === "es-PY" ? 0 : 2,
  }).format(number);
}

export function formatYMD(date) {
  if (date) {
    return date.toISOString().slice(0, 10);
  } else {
    return "";
  }
}

export function monthAdd(date, month) {
  let temp = new Date(date.getFullYear(), date.getMonth(), 1);

  temp.setMonth(temp.getMonth() + month + 1);
  temp.setDate(temp.getDate() - 1);

  if (date.getDate() < temp.getDate()) temp.setDate(date.getDate());

  return temp;
}

export function isEmptyObject(obj) {
  if (!obj) return true; //null, 0, NaN, "", undefined
  return Object.keys(obj).length === 0;
}
