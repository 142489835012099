import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Typography from "@mui/material/Typography";

import Cards from "../commons/cards";
import Data from "../config/integrations-options.json";
import CheckPermission from "../utils/checkPermission";

const Integrations = (props) => {
  const [t] = useTranslation("global");
  const [dataOpts, setDataOpts] = useState([]);

  useEffect(() => {
    Data.options.forEach((element) => {
      setDataOpts((old) => [...old, element]);
    });
  }, []);

  return (
    <div className="generic-card">
      <Typography
        color={"#404A56"}
        mb={6}
        style={{ fontSize: "14px", fontWeight: 300, textAlign: "justify", lineHeight: "21.5px" }}
        gutterBottom
        component="div"
      >
        {t("Integrations.Title")}
      </Typography>
      <Cards
        data={dataOpts}
        onClick={props.onClick}
        permissionButton={CheckPermission("resources.configuration.integrations.actions.viewImplementacion")}
      />
    </div>
  );
};

export default Integrations;
