import { useAuthUser } from "react-auth-kit";

function CheckPermission(scopes) {
  const useAuth = useAuthUser();
  const auth = useAuth();
  let permissionGranted = auth?.roles && scopes.split(".").reduce((obj, i) => obj[i], auth?.roles);
  return permissionGranted;
}

export default CheckPermission;
