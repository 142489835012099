import React from "react";

import { Box, Grid, Typography } from "@mui/material";

import "./documentationLayaout.css";

import CollapseBox from "../commons/collapseBox";
import MonacoEditor from "../monaco/MonacoEditor";
import TransactionCard from "../payment-integration/transactionCard";

const DocumentationLayaout = ({ data }) => {
  const dataDocumentation = data;
  const boxStyle = {
    width: "100px",
    height: "auto",
    wordWrap: "break-word",
  };
  const buildCard = (info) => <TransactionCard information={info} />;
  const BuildCollapseBoxes = () => {
    return (
      <div>
        {dataDocumentation.requirementsApi.map((dataRequest, index) => (
          <div key={index}>
            {Object.keys(dataRequest).map((key) => {
              const card = buildCard(dataRequest[key]);
              const titleBase = key === "parameters" || key === "header" ? "" : "Parameters - ";
              return (
                <Box key={`${index}-${key}`} sx={{ mb: 5, mr: 2 }}>
                  <CollapseBox title={`${titleBase}${key[0].toUpperCase() + key.slice(1)}`} body={card} />
                </Box>
              );
            })}
          </div>
        ))}
      </div>
    );
  };
  return (
    <Grid container className="GridContainer">
      <Grid className="GridDescription" item xs={6} lg={8}>
        <Box>
          <Typography variant="text24">{dataDocumentation.title}</Typography>
          <Grid container spacing={1} mt={1}>
            <Grid item xs={2} lg={1}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                className={"Box" + dataDocumentation.method}
                sx={{ borderRadius: "4px", height: "100%" }}
              >
                <Typography variant="textMethodDoc">{dataDocumentation.method}</Typography>
              </Box>
            </Grid>
            <Grid sx={boxStyle} item xs={9} lg={11}>
              <Typography variant="textPathDoc">{dataDocumentation.path}</Typography>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Typography variant="textDescDoc">{dataDocumentation.desc}</Typography>
          </Box>
          <Box mt={5}>
            <BuildCollapseBoxes />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Box mt={5}>
          <MonacoEditor dataIn={dataDocumentation.examples} />
        </Box>
        <Box mt={5}>
          <MonacoEditor dataIn={dataDocumentation.responses} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default DocumentationLayaout;
