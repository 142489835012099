import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography } from "@mui/material";
import { useField } from "formik";

const useStyles = (_value) =>
  makeStyles((_theme) => ({
    root: {
      "& .Mui-error": {
        color: "#000000 !important",
      },
      "& .MuiFormHelperText-root": {
        color: "#b00020 !important",
      },
      "& Label.Mui-error": {
        color: "#b00020 !important",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: `8px`,
        },
      },
    },
  }));

export default function Email({ props }) {
  const [field, meta] = useField(props.name);
  const [t] = useTranslation("global");
  const configTextField = {
    ...field,
    ...props,
    label: t(props.label),
  };

  const getHelperText = (condition) => {
    if (condition.created) return t(condition.msgError);

    if (meta.touched && meta.error) return t(meta.error);
    return "";
  };

  const helperText = getHelperText(props.conditions[0]);

  const classes = useStyles(helperText)();
  return (
    <div>
      <Typography className="text" style={{ marginBottom: "10px" }}>
        {t(props.lbltitle)}
      </Typography>

      <TextField
        error={(meta.touched && meta.error !== undefined) || props.conditions[0].created}
        {...configTextField}
        variant="outlined"
        style={{ width: "100%" }}
        helperText={helperText}
        className={classes.root}
        onInput={(event) => {
          if (event.target.value === "") {
            props.conditions[0].created = false;
          }
        }}
        onChange={(e) => field.onChange(e)}
      />

      {props.conditions[0].created ? (
        <div>
          <span className="text">
            {t(props.conditions[0].text)} <Link to={props.conditions[0].route}>{t(props.conditions[0].link)}</Link>
          </span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
