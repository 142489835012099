import { Fragment, React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { SnackBar } from "./commons/alert";
import GenericBackdrop from "./commons/GenericBackdrop";
import dataJson from "./config/reset-password.json";
import GenericForm from "./genericForm";
import { getYup, schema } from "./utils/yup";

import useAxiosPrivate from "../hooks/useAxiosPrivate";

export default function FormResetPassword() {
  const [t] = useTranslation("global");
  const [validates, setValidates] = useState({});
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [loader, setLoader] = useState(false);

  const location = useLocation();
  const email = location.state.email;

  const handleSubmit = async (values) => {
    let dataRequest = {
      old_password: values.old_password,
      password: values.password,
      email: email,
    };

    setLoader(true);
    await axiosPrivate
      .put(`users/password`, dataRequest)
      .then(() => {
        setLoader(false);
        localStorage.setItem("reset", true);
        navigate("/SignIn");
      })
      .catch((_err) => {
        setLoader(false);
        SnackBar(t("General.Message.ErrorService"), "error");
      });
  };

  useEffect(() => {
    let _validates = {};
    dataJson.forms.fields.forEach(({ props, validations }) => {
      _validates[props.name] = schema(validations, props.type);
    });
    setValidates(_validates);
  }, []);

  return (
    <Fragment>
      <GenericBackdrop open={loader} />
      <GenericForm form={dataJson.forms} initialValue={{}} validates={getYup(validates)} onSubmit={handleSubmit} />
    </Fragment>
  );
}
