import React from "react";

import Sesion from "../components/commons/sesion";
import Insights from "../components/insights/insights";

export default function Insight() {
  return (
    <div className={"Drawer"}>
      <Sesion screen={"Insights"} />
      <Insights view="insights" />
    </div>
  );
}
