import React from "react";
import PropTypes from "prop-types";
import ConfigIntegration from "../commons/configIntegration";

const ConfigReservationOTP = (props) => {
  return <ConfigIntegration onClickIntegration={props.onClickIntegration} typeIntegration="ReservationOTP" />;
};

ConfigReservationOTP.propTypes = {
  onClickIntegration: PropTypes.func.isRequired,
};

export default ConfigReservationOTP;
