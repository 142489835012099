import { Fragment, useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";

import { Button, Card, CardContent, FormControlLabel, FormGroup, Grid, Paper, Stack } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import axios from "axios";

import DynamicForm from "./dynamicForm";
import UploadFileArea from "./uploadFileArea";

import { getCountry } from "../../components/utils/getCountry";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { SnackBar } from "../commons/alert";
import { getClientId } from "../utils/jwt";

export default function AddBankAccount({ onClose }) {
  const [t] = useTranslation("global");

  const useAuth = useAuthUser();
  const auth = useAuth();
  const [proofBankAccount, setProofBankAccount] = useState(null);
  const [accountData, setAccountData] = useState({});
  const [savingData, setSavingData] = useState(false);
  const [checked, setChecked] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [country, setCountry] = useState("");

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: "center",
    boxShadow: "none",
    border: "1px solid #fff !important",
    color: "black",
  }));

  function handleSubmit(event) {
    event.preventDefault();
    if (checked || country == "PARAGUAY") {
      setSavingData(true);
      if (country == "PARAGUAY") {
        sendData();
      } else {
        saveFile();
      }
    } else {
      SnackBar(t("Users.Message.ErrorFields"), "error");
    }
  }

  function sendData(key) {
    let proof_account = "";
    if (key) {
      proof_account = key;
    }
    axiosPrivate
      .post("withdrawals/bank-accounts", {
        client_id: getClientId(auth),
        account: JSON.stringify(accountData),
        proof_account: proof_account,
      })
      .then(() => {
        onClose();
        setSavingData(false);
      })
      .catch((_) => {
        setSavingData(false);
        SnackBar(t("General.Message.ErrorService"), "error");
      });
  }

  function saveFile() {
    let ext = proofBankAccount.type.split("/")[1];
    axiosPrivate
      .post(`merchants/files`, {
        folder: "topic",
        filename: `proof_bank_account.${ext}`,
      })
      .then((response) => {
        let url = response.data.url;
        let fields = response.data.fields;
        let formData = new FormData();
        Object.entries(fields).forEach(([k, v]) => {
          formData.append(k, v);
        });

        formData.append("file", proofBankAccount, proofBankAccount.name);

        let instance = axios.create({
          headers: {
            post: {
              "Content-Type": "multipart/form-data",
            },
          },
        });

        delete instance.defaults.headers.common["Authorization"];

        instance.post(url, formData).then((_) => sendData(fields.key));
      })
      .catch((_) => {
        SnackBar(t("General.Message.ErrorService"), "error");
        setSavingData(false);
      });
  }

  useEffect(() => {
    const countryValue = getCountry();
    setCountry(countryValue);
  });

  function bankAccountForm() {
    if (savingData) {
      return <CircularProgress />;
    } else {
      return (
        <Fragment>
          <form onSubmit={handleSubmit} data-testid="form">
            <Card>
              <CardContent>
                <Stack spacing={2}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    component="div"
                    sx={{
                      fontWeight: 700,
                      fontSize: "18px",
                      color: "#404A56",
                    }}
                  >
                    {t("Account.Message.AddAccountTitle")}
                  </Typography>
                  <Typography
                    variant="h5"
                    gutterBottom
                    component="div"
                    sx={{
                      fontSize: "14px",
                      color: "#404A56",
                      paddingBottom: "32px",
                    }}
                  >
                    {t("Account.Message.AddAccountSubTitle")}
                  </Typography>
                </Stack>
                <div style={{ width: "450px" }}>
                  <Stack spacing={2}>
                    <Stack spacing={2} width="fit-content">
                      <DynamicForm
                        onChange={(e) => {
                          setAccountData({
                            ...accountData,
                            [e.target.name]: e.target.value,
                          });
                        }}
                        params={accountData}
                      />
                      {country !== "PARAGUAY" && (
                        <Fragment>
                          <Typography
                            variant="h5"
                            gutterBottom
                            component="div"
                            sx={{
                              paddingTop: "20px",
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "#404A56",
                            }}
                          >
                            {t("Account.Message.Bankreceipt")}
                          </Typography>

                          <UploadFileArea
                            onChange={(e) => {
                              setProofBankAccount(e.target.files[0]);
                            }}
                          />
                        </Fragment>
                      )}
                    </Stack>
                  </Stack>
                </div>
              </CardContent>
            </Card>
            <div
              style={{
                textAlign: "right",
                marginBlockEnd: "30px",
                marginTop: "30px",
              }}
            >
              <Grid container spacing={2} style={{ border: "none" }}>
                <Grid item xs={9} style={{ border: "none" }}>
                  {country !== "PARAGUAY" && (
                    <Item style={{ border: "none" }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <input
                              data-testid="chkAccount"
                              type="checkbox"
                              checked={checked}
                              onChange={(e) => setChecked(e.target.checked)}
                            />
                          }
                          style={{ paddingLeft: "10px" }}
                          label={
                            <Typography style={{ fontSize: "12px", paddingLeft: "10px" }}>
                              {t("Account.checkLabel")}
                            </Typography>
                          }
                        />
                      </FormGroup>
                    </Item>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <Item>
                    <Button
                      variant="outlined"
                      style={{
                        background: "white",
                        color: "#404A56",
                        textTransform: "none",
                        minWidth: "135px",
                        borderColor: "#FFC10E",
                        height: "48px",
                      }}
                      onClick={onClose}
                      className="action-button-outlined"
                    >
                      {t("Buttons.Cancel")}
                    </Button>
                    <Button
                      type="submit"
                      variant="outlined"
                      style={{
                        background: "#FFC10E",
                        color: "#404A56",
                        marginLeft: "10px",
                        textTransform: "none",
                        minWidth: "135px",
                        height: "48px",
                      }}
                      className="action-button"
                    >
                      {t("Buttons.Save")}
                    </Button>
                  </Item>
                </Grid>
              </Grid>
            </div>
          </form>
        </Fragment>
      );
    }
  }

  return (
    <div>
      <Stack>{bankAccountForm()}</Stack>
    </div>
  );
}
