import { default as btn } from "./inputs/button";
import Submit from "./inputs/submit";

const buttonsComponents = {
  button: btn,
  submit: Submit,
};

const Button = ({ type, data, btnBefore, btnNext, submit }) => {
  const ButtonsComponent = buttonsComponents[[type]];
  if (!ButtonsComponent) {
    return null;
  }
  return <ButtonsComponent btn={data} btnBefore={btnBefore} btnNext={btnNext} submit={submit} />;
};

const GenericButton = ({ props, btnBefore, btnNext, submit }) => {
  return <Button type={props.type} data={props} btnBefore={btnBefore} btnNext={btnNext} submit={submit} />;
};

export default GenericButton;
