import { Fragment, React } from "react";
import { useTranslation } from "react-i18next";

import { Button, Card, CardActions, CardContent, Stack, Typography } from "@mui/material";

const Cards = (props) => {
  const [t] = useTranslation("global");

  return (
    <Fragment>
      <Stack direction={{ xs: "column", sm: "column", md: "row" }} width="100%" spacing={3} mt={4}>
        {props.data.map((option, index) => (
          <Card
            key={index}
            style={{
              width: "100%",
              borderRadius: "16px",
              padding: option.style.padding,
              boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
            }}
          >
            <CardContent sx={{ padding: option.style.padding }} style={{ textAlign: option.style.textAlign }}>
              <img src={`/images/${option.image.name}`} alt="icon" />
              <Typography
                color={"#404A56"}
                mb={2}
                style={{ fontSize: "28px", fontWeight: 700 }}
                gutterBottom
                component="div"
              >
                {t(option.title)}
              </Typography>
              {option.subtitle.map((subtitles, subindex) => (
                <Typography
                  key={subindex}
                  color={"Gray"}
                  mb={4}
                  style={{ fontSize: "14px", textAlign: "justify" }}
                  gutterBottom
                >
                  {t(subtitles)}
                </Typography>
              ))}
              <CardActions style={{ justifyContent: "center" }}>
                {(props.permissionButton == undefined || props.permissionButton) && (
                  <Button
                    style={{
                      background: option.button.outlined ? "" : "#FFC10E",
                      textTransform: "none",
                      minWidth: option.button.style.minWidth,
                      height: "48px",
                      marginTop: "25px",
                      color: "#404A56",
                    }}
                    onClick={() => props.onClick(index)}
                    variant={option.button.outlined ? "outlined" : ""}
                    className={option.button.outlined ? "action-button-outlined-secondary" : "action-button-secondary"}
                  >
                    {t(option.button.text)}
                  </Button>
                )}
              </CardActions>
            </CardContent>
          </Card>
        ))}
      </Stack>
    </Fragment>
  );
};

export default Cards;
