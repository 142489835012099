import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconButton, Stack, TextField, Typography, Grid } from "@mui/material";
import PropTypes from "prop-types";

import deletekey from "../../../assets/icon/iconDelete.svg";
import moreKey from "../../../assets/icon/iconMore.svg";
import moreKeyDisable from "../../../assets/icon/iconMoreDisable.svg";

import { Alert } from "../../commons/alert";

const AddKeyValue = (props) => {
  const [t] = useTranslation("global");
  const [fields, setFields] = useState([{ key: "", value: "" }]);
  const heighLine = 40;
  const itemMax = props.itemMax;

  useEffect(() => {
    const loadData = (data) => {
      const newFields = data.map((element) => ({
        key: element.key,
        value: element.value,
        editable: element.editable != undefined ? element.editable : true,
        erasable: element.erasable != undefined ? element.erasable : true,
      }));
      setFields(newFields);
    };
    if (props.callEdit || props.data?.length > 0) {
      loadData(props.data);
    }
  }, [props]);

  const addField = () => {
    const hasEmptyFields = fields.some((field) => field.key === "" || field.value === "");
    if (!hasEmptyFields) {
      const newFields = [...fields, { key: "", value: "" }];
      setFields(newFields);
      props.saveKeys(newFields);
    } else {
      Alert(t("Integrations.PayButton.Implementation.Message.IncompleteField"), "warning");
    }
  };

  const deleteField = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
    props.saveKeys(updatedFields);
  };

  const handleFieldChange = (index, fieldName, value) => {
    const updatedFields = [...fields];
    updatedFields[index][fieldName] = value;
    setFields(updatedFields);
    props.saveKeys(updatedFields);
  };

  return (
    <div>
      {fields.map((field, index) => (
        <Stack key={field.key} direction="row" spacing={2} alignItems="center" sx={{ marginTop: "25px" }}>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 0.5, sm: 0.5, md: 1 }}>
            <Grid item xs={11}>
              <Stack direction="row" spacing={2} alignItems="center" sx={{ display: "flex" }}>
                <TextField
                  fullWidth
                  label={t("Integrations.PayButton.Implementation.Add.Key")}
                  defaultValue={field.key}
                  size="small"
                  required={props.required}
                  sx={{ backgroundColor: "#FFFFFF" }}
                  onBlur={(e) => handleFieldChange(index, "key", e.target.value)}
                  inputProps={{ sx: { height: heighLine } }}
                  InputProps={{ sx: { borderRadius: 2 } }}
                  disabled={field.editable != undefined && !field.editable}
                />
                <Typography
                  sx={{
                    fontSize: "24px",
                    lineHeight: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  :
                </Typography>
                <TextField
                  fullWidth
                  label={t("Integrations.PayButton.Implementation.Add.Value")}
                  defaultValue={field.value}
                  size="small"
                  sx={{ backgroundColor: "#FFFFFF" }}
                  required={props.required}
                  onBlur={(e) => handleFieldChange(index, "value", e.target.value)}
                  inputProps={{ sx: { height: heighLine } }}
                  InputProps={{ sx: { borderRadius: 2 } }}
                  disabled={field.editable != undefined && !field.editable}
                />
              </Stack>
            </Grid>
            <Grid item xs={1} sx={{ paddingTop: 5 }}>
              <Stack direction="row" alignItems="center" sx={{ display: "flex" }}>
                {(index > 0 || fields.length > 0) && field.erasable && (
                  <IconButton onClick={() => deleteField(index)} data-testid="deleteField">
                    <img src={deletekey} alt="deletekey" width={heighLine} height={heighLine} />
                  </IconButton>
                )}
                {(index === 0 && fields.length == 0) ||
                  (index == fields.length - 1 && (
                    <IconButton
                      onClick={() => addField()}
                      data-testid="addField"
                      disabled={itemMax != undefined && fields.length == itemMax}
                    >
                      <img
                        src={itemMax != undefined && fields.length == itemMax ? moreKeyDisable : moreKey}
                        alt="moreKey"
                        width={heighLine}
                        height={heighLine}
                      />
                    </IconButton>
                  ))}
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      ))}
    </div>
  );
};

AddKeyValue.propTypes = {
  data: PropTypes.array.isRequired,
  saveKeys: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  callEdit: PropTypes.bool.isRequired,
  itemMax: PropTypes.number,
};

export default AddKeyValue;
