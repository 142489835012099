import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Form, Formik } from "formik";

import GenericButton from "./genericButton";
import GenericImage from "./genericImage";
import GenericInput from "./genericInput";
import ErrorMessage from "./inputs/errorMessage";
import Title from "./inputs/title";

const GenericForm = (props) => {
  const [t] = useTranslation("global");

  const HtmlText = (code) => {
    return <div dangerouslySetInnerHTML={{ __html: code }} />;
  };

  const LegendPassword = (element) => {
    return <>{element.code && <div style={{ ...element.code.style }}>{HtmlText(t(element.code.legend))}</div>}</>;
  };

  const LegendErrorPassword = (element) => {
    return (
      <>{element.code && <div style={{ ...element.code.errorStyle }}>{HtmlText(t(element.code.legendError))}</div>}</>
    );
  };

  const LegendErrorForgotPassword = (element) => {
    return (
      <>{element.code && <div style={{ ...element.code.errorstyle }}>{HtmlText(t(element.code.legenderror))}</div>}</>
    );
  };

  const buildTxt = (txt) => {
    return txt.replace("newEmail", `<b>${props.form.email}</b>`);
  };

  return (
    <Formik
      initialValues={props.initialValue}
      validationSchema={props.validates}
      onSubmit={(valores) => props.onSubmit(valores)}
    >
      {({ errors, submit }) => (
        <Form className="formulario">
          <Title title={t(props.form.title)} />
          <Box>{props.form.email && <Typography>{HtmlText(buildTxt(t(props.form.subtitle)))}</Typography>}</Box>
          {props.form.fields.map((field) => (
            <div key={field.props.id}>
              {!field.props.hide && <GenericInput {...field} />}
              {field.props.type !== "email" ? <ErrorMessage name={field.props.name} errors={errors} /> : ""}
              {field.props.legend && <LegendErrorPassword code={field.props} />}
              {field.props.legend && <LegendPassword code={field.props} />}
              {field.props.legenderror && <LegendErrorForgotPassword code={field.props} />}
            </div>
          ))}

          {props.form.images &&
            props.form.images.map((img) => (
              <Grid item xs={12} md={props.form.images.length > 1 ? 6 : 12} key={img.id}>
                <div>{<GenericImage props={img.props} />}</div>
              </Grid>
            ))}

          <div style={{ marginTop: "20px" }}>
            <Grid container spacing={2} className="grid-buttons">
              {props.form.actionsButtons.map((btn) => (
                <Grid item xs={12} md={props.form.actionsButtons.length > 1 ? 6 : 12} key={btn.id}>
                  <div>{!btn.hide && <GenericButton props={btn} submit={submit} />}</div>
                </Grid>
              ))}
            </Grid>
          </div>

          {props.form.footer.created && (
            <div className="footer">
              <Typography className="text" style={{ display: "inline" }}>
                {t(props.form.footer.text)}
              </Typography>
              <Link to={props.form.footer.route} className="text">
                {t(props.form.footer.textlink)}
              </Link>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default GenericForm;
