import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import SvgIcon from "@mui/material/SvgIcon";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

import Answers from "./answers";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import TabPanel from "../../utils/tabPanel";
import { Alert } from "../commons/alert";
import GenericBackdrop from "../commons/GenericBackdrop";

export default function Questions({ idTopic, setIdTopic, textTopic, setTextTopic, topics, search, setSearch }) {
  const [t] = useTranslation("global");
  const [value, setValue] = useState(idTopic !== null ? parseInt(idTopic) : parseInt(search.topicId));
  const [text, seText] = useState();
  const [idQuestion, setIdQuestion] = useState();
  const [textQuestion, setTextQuestion] = useState();
  const [useSearch, setUseSearch] = useState(search);

  const handleChange = (event, newValue) => {
    setUseSearch(null);
    setValue(newValue);
    setIdQuestion(null);
    seText(event.target.innerText);
  };

  const [questions, setQuestions] = useState([]);

  const [loader, setLoader] = useState(false);
  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getQuestion = async () => {
      try {
        const response = await axiosPrivate.get(`help/topics/questions/${value}`, {
          signal: controller.signal,
        });
        isMounted && setQuestions(response.data);
        isMounted && handleLoaderClose();
      } catch {
        handleLoaderClose();
        Alert(t("General.Message.ErrorService"), "");
      }
    };

    if (value) {
      handleLoaderOpen();
      getQuestion();
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [value]);

  const styleTab = {
    justifyContent: "left",
    padding: "0 6px",
    minHeight: "50px",
    borderBottom: "1px solid #DFE3E7",
    alignItems: "left !important",
    textAlign: "left !important",
    fontWeight: "400",
    "&.Mui-selected": {
      borderColor: "#FABE00",
      color: "#4C5866",
      fontWeight: "600",
    },
  };

  const styleMenu = {
    margin: "0 5px",
    width: "313px",
  };

  const viewQuestions = () => {
    if (
      (idQuestion !== undefined && idQuestion !== null) ||
      (useSearch !== undefined &&
        useSearch !== null &&
        useSearch.questionId !== undefined &&
        useSearch.questionId !== null &&
        useSearch.question !== undefined &&
        useSearch.question !== null)
    )
      return (
        <Answers idQuestion={idQuestion || useSearch.questionId} textQuestion={textQuestion || useSearch.question} />
      );

    if (questions.length > 0)
      return (
        <Fragment>
          {questions.map((item, index) => (
            <Typography key={index} component={"div"} margin={"0 30px 20px 30px"}>
              <Link
                component="button"
                variant="body1"
                color="#404A56"
                underline="none"
                onClick={(e) => {
                  e.preventDefault();
                  setIdQuestion(item.questionId);
                  setTextQuestion(item.question);
                }}
              >
                {item.question}
              </Link>
            </Typography>
          ))}
        </Fragment>
      );
  };

  return (
    <Fragment>
      <GenericBackdrop open={loader} />
      <Card
        sx={{
          padding: "1px",
          borderRadius: "16px",
          height: "fit-content",
          minHeight: "500px",
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            sx={styleMenu}
            TabIndicatorProps={{ style: { width: 0 } }}
          >
            {topics.length > 0
              ? topics.map((item, index) => (
                  <Tab
                    key={index}
                    label={item.name}
                    sx={styleTab}
                    icon={
                      <SvgIcon style={{ fontSize: "20px" }}>
                        <path d={item.path} />
                      </SvgIcon>
                    }
                    iconPosition="start"
                    value={item.topicid}
                  />
                ))
              : null}
          </Tabs>
          <Divider orientation="vertical" flexItem />
          <Box
            sx={{
              padding: "10px 15px",
              height: "fit-content",
              minHeight: "700px",
              width: "100%",
            }}
          >
            <Link
              component="button"
              variant="body2"
              color="#004FB6"
              underline="none"
              onClick={(e) => {
                e.preventDefault();

                setIdTopic(null);
                setTextTopic(null);
                setSearch(null);
              }}
              sx={{ fontWeight: 600 }}
            >
              Home ayuda &nbsp;&nbsp; {">"} &nbsp;&nbsp;
            </Link>
            <Link
              component="button"
              variant="body2"
              color="#004FB6"
              underline="none"
              onClick={(e) => {
                e.preventDefault();
                setUseSearch(null);
                setIdQuestion(null);

                if (
                  search !== undefined &&
                  search !== null &&
                  search.questionId !== undefined &&
                  search.questionId !== null
                )
                  setSearch({ ...search, questionId: null });
              }}
              sx={{ fontWeight: 600 }}
            >
              {text || textTopic || search.topic}
            </Link>
            <TabPanel value={value} index={value} style={{ margin: "40px 0" }}>
              {viewQuestions()}
            </TabPanel>
          </Box>
        </Box>
      </Card>
    </Fragment>
  );
}
