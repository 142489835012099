import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { Stack } from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import { FilterDetails } from "./filterDetails";

import { ExportExcel } from "../../utils/exportExcel";
import { SendEmail } from "../../utils/SendEmail";
import { permissionsContext } from "../check-permissions/checkPermissions";
import Search from "../commons/search";

export default function Buttons({
  data,
  columns,
  backUp,
  setSearch,
  columnsSearch,
  setIsSearch,
  dataFilter,
  dateDefaultFilter,
  setInputSearch,
  inputSearch,
  filterEmail,
  branches,
  statuses,
}) {
  const [t] = useTranslation("global");
  const permissions = useContext(permissionsContext);
  
  return (
    <Stack mb={2} mt={2}>
      <Typography variant="subTitle18">{t("Insights.Message.Movements")}</Typography>
      <Stack direction="row" width="100%" mt={2}>
        {permissions?.insights?.actions?.viewFilter && (
          <Stack direction="row" width="150vh" spacing={1} justifyContent="start">
            <Search
              backUp={backUp}
              setSearch={setSearch}
              columnsSearch={columnsSearch}
              setIsSearch={setIsSearch}
              setInputSearch={setInputSearch}
              inputSearch={inputSearch}
            />
          </Stack>
        )}
        {permissions?.insights?.actions?.exportData && (
          <Stack direction="row" width="150vh" spacing={1} justifyContent="end">
            <SendEmail fileData={filterEmail} fileName={"Transfers"} />
            <ExportExcel fileType={"xlsx"} fileName={"Transfers"} data={data} columns={columns} />
            <ExportExcel fileType={"csv"} fileName={"Transfers"} data={data} columns={columns} />
          </Stack>
        )}
      </Stack>
      <Card
        sx={{
          margin: "1vh 0",
          padding: "4vh 0",
          backgroundColor: "#EFF1F399",
        }}
      >
        <FilterDetails
          data={data}
          dataFilter={dataFilter}
          dateDefaultFilter={dateDefaultFilter}
          setInputSearch={setInputSearch}
          branches={branches}
          statuses={statuses}
        ></FilterDetails>
      </Card>
    </Stack>
  );
}
