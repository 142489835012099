import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { SnackBar } from "./../components/commons/alert";

import OnBoardingLayout from "../components/on-boarding-layout";
import Verify from "../components/verify";
import VerifyEmail from "../components/verify-email";
import VerifyPassword from "../components/verify-password";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export default function SignUp() {
  const [email, setEmail] = useState("");
  const [stage, setStage] = useState(0);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [t] = useTranslation("global");
  const location = useLocation();
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    let abortController = new AbortController();
    const query = new URLSearchParams(location.search);
    const paramToken = query.get("token");
    if (paramToken) {
      axiosPrivate
        .put(`verifyEmailToken`, {
          token: paramToken,
        })
        .then((response) => {
          switch (response.data.message) {
            case "General.Message.ValidToken":
              setEmail(response.data.email);
              setStage(2);
              break;
            case "General.Message.ExpiredToken":
              navigate("/SignIn");
              SnackBar(t(response.data.message), "error");
              break;
            default:
              navigate("/SignUp");
              SnackBar(t(response.data.message), "error");
          }
        })
        .catch((err) => {
          navigate("/SignUp");
          SnackBar(err, "error");
        });
    }

    return () => {
      setIsMounted(false);
      abortController.abort();
    };
  }, []);

  const onNext = (values) => {
    isMounted && setEmail(values["email"]);
    isMounted && setStage(1);
  };

  const onBefore = (_) => {
    setStage(0);
  };
  const onSubmit = (values) => {
    navigate("/form", {
      screen: "Form",
      state: {
        password: values["password"],
        email: email,
      },
    });
  };

  switch (stage) {
    case 0:
      return <OnBoardingLayout screen={<VerifyEmail onSubmit={onNext} />} />;
    case 1: // Aqui va pantalla de correo enviado
      return <OnBoardingLayout screen={<Verify onBefore={onBefore} email={email} />} />;
    case 2:
      return <OnBoardingLayout screen={<VerifyPassword onSubmit={onSubmit} />} />;
  }
}
