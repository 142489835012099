import React from "react";
import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material";

import Logotigo from "../assets/logoTigo/Tigo-logo.png";

export default function OnBoardingLayout({ screen, withoutContainer = false }) {
  const [t] = useTranslation("global");

  return (
    <div>
      {/* Header */}
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className="head-login-container"
      >
        <Grid item xs={3} display="flex" justifyContent="center">
          <img src={Logotigo} alt="tigo money" />
        </Grid>
      </Grid>

      {!withoutContainer ? (
        <Grid container direction="row" alignItems="center" justifyContent="center">
          <Grid item xl={3} lg={4} md={5} sm={11} xs={11} className="body-login-container">
            {screen}
          </Grid>
        </Grid>
      ) : (
        screen
      )}
      {/* Body */}

      {/* Footer */}
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        className="footer-login-container"
        m={"auto"}
      >
        <Grid item md={5} sm={12} xs={12} display="flex" justifyContent="left" textAlign="left">
          <Typography className="text">{t("General.Message.Rights")}</Typography>
        </Grid>

        <Grid item md={5} sm={12} xs={12} display="flex" justifyContent="space-evenly"></Grid>
      </Grid>
    </div>
  );
}
