import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

import pending from "../../../assets/generic/PendingValidate.svg";
import Edit from "../../../assets/icon/iconEdit.svg";

import AddReservationOTP from "../reservation-otp/addReservationOTPConfig";
import AddEcommerceConfig from "../ecommerce/addEcommerceConfig";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Alert } from "../../commons/alert";
import GenericBackdrop from "../../commons/GenericBackdrop";
import ActiveSwitch from "./activeSwitch";

const ConfigIntegration = (props) => {
  const [t] = useTranslation("global");
  const axiosPrivate = useAxiosPrivate();
  const [loader, setLoader] = useState(false);
  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [addIntegration, setAddIntegration] = useState(false);
  const [configExists, setConfigExists] = useState(false);
  const [saveConfig, setSaveConfig] = useState(false);
  const [merchantId, setMerchantId] = useState("");
  const [activeControls, setActiveControls] = useState(false);
  const [activeCheck, setActiveCheck] = useState(false);
  const [data, setData] = useState(null);
  let urlIntegration = "";

  switch (props.typeIntegration) {
    case "ReservationOTP":
      urlIntegration = "otp";
      break;
    case "PayButton":
      urlIntegration = "ecommerce";
      break;
  }
  const setDataOtp= (data)=>{
    if (data.redirect) {
      setData(data);
      setMerchantId(data.redirect.redirect_id);
      setActiveControls(!data.redirect.active);
      setActiveCheck(data.redirect.active);
      setAddIntegration(false);
      setConfigExists(true);
    } else {
      setAddIntegration(true);
      setConfigExists(false);
    }
  }

  const setDataPayButton= (data)=>{
    if (data.callbacks?.length > 0 && data.redirects?.length > 0) {
      setData(data);
      setMerchantId(data.integrated.app_id);
      setActiveControls(!data.integrated.active);
      setActiveCheck(data.integrated.active);
      setAddIntegration(false);
      setConfigExists(true);
    } else {
      setAddIntegration(true);
      setConfigExists(false);
    }
  }

  useEffect(() => {
    const checkConfiguration = () => {
      handleLoaderOpen();

      axiosPrivate
        .get("integration/" + urlIntegration)
        .then((response) => {
          if (response.data) {
            let data = response.data;
            switch (props.typeIntegration) {
              case "ReservationOTP":
                setDataOtp(data);
                break;
              case "PayButton":
                setDataPayButton(data);
                break;
            }
          }
          handleLoaderClose();
        })
        .catch((_) => {
          handleLoaderClose();
          return Alert(t("General.Message.ErrorService"), "error");
        });
    };
    if (saveConfig) {
      setConfigExists(true);
    } else if (!addIntegration) {
      checkConfiguration();
    }
  }, [addIntegration, saveConfig, axiosPrivate, t, configExists]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleActive = (status) => {
    handleLoaderOpen();
    const body = { active: status };
    axiosPrivate
      .patch(`integration/${urlIntegration}/${merchantId}/active`, body)
      .then((response) => {
        if (response.data.message === "success") {
          let disabledControls = !status;
          setActiveControls(disabledControls);
          handleLoaderClose();
        }
      })
      .catch((_) => {
        handleLoaderClose();
        return Alert(t("General.Message.ErrorService"), "error");
      });
  };

  const handleCloseAddIntegration = (data) => {
    setAddIntegration(false);
    setSaveConfig(true);
    setMerchantId(data.app_id);
  };

  if (!addIntegration) {
    return (
      <Stack style={{ fontFamily: "Roboto" }}>
        <GenericBackdrop open={loader} />
        {configExists ? (
          <Stack>
            <Card style={{ borderRadius: "16px" }}>
              <CardContent style={{ margin: "20px" }}>
                <Stack container direction="row" justifyContent="space-between" alignItems="center">
                  <Typography
                    color={"#404A56"}
                    style={{ fontSize: "18px", fontWeight: 700, lineHeight: "32px" }}
                    gutterBottom
                    component="div"
                  >
                    {t("Integrations." + props.typeIntegration + ".Implementation.SectionTitle.CheckConfiguration")}
                  </Typography>
                  <FormControlLabel
                    label={t("Integrations." + props.typeIntegration + ".Implementation.CheckConfiguration.Active")}
                    labelPlacement="start"
                    sx={{ marginRight: "50px" }}
                    control={
                      <ActiveSwitch
                        sx={{ m: 1 }}
                        defaultChecked={activeCheck}
                        onChange={(event) => {
                          if (event.target.checked) {
                            handleActive(true);
                          } else {
                            handleActive(false);
                          }
                        }}
                      />
                    }
                  />
                </Stack>
                <CardActions>
                  <Stack spacing={1}>
                    <Typography
                      color={"#404A56"}
                      style={{ fontSize: "16px", color: "#708195" }}
                      gutterBottom
                      component="div"
                    >
                      {t("Integrations." + props.typeIntegration + ".Implementation.CheckConfiguration.NameId")}
                    </Typography>
                    <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
                      <OutlinedInput
                        readOnly
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        value={merchantId}
                        sx={{
                          border: "none",
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          width: "400px",
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              disabled={activeControls}
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <VisibilityOff disabled={activeControls} />
                              ) : (
                                <Visibility disabled={activeControls} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <Button
                      style={{
                        background: "#FFC10E",
                        textTransform: "none",
                        minWidth: "155px",
                        height: "40px",
                        marginTop: "40px",
                        color: "#404A56",
                      }}
                      disabled={activeControls}
                      onClick={() => setAddIntegration(true)}
                      className="action-button"
                    >
                      <img src={Edit} alt="Edit" width={20} height={20} style={{ marginRight: "10px" }} />
                      {t("Integrations." + props.typeIntegration + ".Implementation.CheckConfiguration.ButtonEdit")}
                    </Button>
                  </Stack>
                </CardActions>
              </CardContent>
            </Card>
            <Stack spacing={2} direction="row" justifyContent="flex-end" sx={{ marginTop: "30px" }}>
              <Button
                variant="outlined"
                size="large"
                sx={{ width: "155px", height: "48px" }}
                onClick={props.onClickIntegration}
              >
                {t("Integrations." + props.typeIntegration + ".Implementation.CheckConfiguration.Back")}
              </Button>
            </Stack>
          </Stack>
        ) : (
          <Card style={{ padding: "5%", borderRadius: "16px", textAlign: "center" }}>
            <CardContent>
              <Typography color={"#404A56"} style={{ fontSize: "24px", fontWeight: 700 }} gutterBottom component="div">
                {t("Integrations." + props.typeIntegration + ".Implementation.SectionTitle.NoConfiguration")}
              </Typography>
              <img src={pending} alt={"AddConfig"} />
              <CardActions style={{ justifyContent: "center" }}>
                <Button
                  style={{
                    background: "#FFC10E",
                    textTransform: "none",
                    minWidth: "180px",
                    height: "48px",
                    marginTop: "25px",
                    color: "#404A56",
                  }}
                  data-testid="button_add"
                  onClick={() => setAddIntegration(true)}
                  className="action-button"
                >
                  {t("Integrations." + props.typeIntegration + ".Implementation.ButtonAdd")}
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        )}
      </Stack>
    );
  } else {
    switch (props.typeIntegration) {
      case "ReservationOTP":
        return (
          <AddReservationOTP
            onClose={handleCloseAddIntegration}
            onClickIntegration={props.onClickIntegration}
            dataConfiguration={data}
          />
        );
      case "PayButton":
        return (
          <AddEcommerceConfig
            onClose={handleCloseAddIntegration}
            onClickIntegration={props.onClickIntegration}
            dataConfiguration={data}
          />
        );
    }
  }
};

ConfigIntegration.propTypes = {
  onClickIntegration: PropTypes.func.isRequired,
  typeIntegration: PropTypes.string.isRequired,
};

export default ConfigIntegration;
