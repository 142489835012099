import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, Checkbox, Grid, Link, Typography } from "@mui/material";

export default function AcceptContract({ handleReturn, handleNext, checkedView }) {
  const [t] = useTranslation("global");
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (checkedView) {
      setChecked(true);
    }
  });

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Box sx={{ height: "180px" }} mt={5}>
      <Grid container direction="row" pl={5} pr={5}>
        <Grid item xs={12} md={5} lg={8}>
          <Grid container direction="row" alignItems="center">
            {checkedView !== true && (
              <Box>
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{
                    "aria-label": "controlled",
                    "data-testid": "check-id",
                  }}
                />
                <Typography variant="checkbox">
                  {t("Contract.CheckboxText")}
                  <Link href="/privacy" color="inherit" target="_blank">
                    {t("Contract.LinkText")}
                  </Link>
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={7} lg={4}>
          <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item xs={6} md={3}>
              <Button
                color="secondary"
                variant="contained"
                size="large"
                onClick={handleReturn}
                className="action-button-outlined"
              >
                {t("Contract.NextButton")}
              </Button>
            </Grid>
            <Grid item xs={6} md={3}>
              <Button
                variant="contained"
                size="large"
                disabled={!checked}
                onClick={handleNext}
                className="action-button"
                data-testid="btn-continuar"
              >
                {t("Contract.PrevButton")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
