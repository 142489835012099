import React, { useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";

import { MenuItem, Stack, TextField } from "@mui/material";
import PropTypes from "prop-types";

import { Alert } from "../../components/commons/alert";
import { formatYMD } from "../../utils/validateExpression";
import { CleanFilterButton } from "../commons/cleanFilterButton";
import { FilterButton } from "../commons/filterButton";
import { getClientId } from "../utils/jwt";

export function FilterDetails({ data, dataFilter, dateDefaultFilter, setInputSearch, branches, statuses }) {
  const [t] = useTranslation("global");

  const [dateInit, setDateInit] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [branch, setBranch] = useState("");
  const [status, setStatus] = useState("");
  const [payment, setPayment] = useState("");
  const [paymentSelect, setPaymentSelect] = useState([]);
  const [initFiltersDate, setInitFiltersDate] = useState(true);

  const BRANCH_TYPE = "branch";
  const STATUS_TYPE = "status";

  const useAuth = useAuthUser();
  const auth = useAuth();

  const filterData = () => {
    setInputSearch("");
    setInitFiltersDate(false);

    if (dateEnd === "" && dateInit === "") {
      let parameters = {
        status: status,
        branch: branch,
        payment: payment,
        date_init: formatYMD(dateInit),
        date_end: formatYMD(dateEnd),
        client_id: getClientId(auth),
      };

      dataFilter(parameters);
    } else if (dateEnd === "" || dateInit === "") {
      return Alert(t("Filter.Message.NotDates"), "error");
    } else if (dateEnd !== "" || dateInit !== "") {
      if (dateEnd >= dateInit) {
        let parameters = {
          status: status,
          branch: branch,
          payment: payment,
          date_init: formatYMD(dateInit),
          date_end: formatYMD(dateEnd),
          client_id: getClientId(auth),
        };
        dataFilter(parameters);
      } else {
        return Alert(t("Filter.Message.DateIncorrect"), "error");
      }
    }
  };

  const cleanFilter = () => {
    setInputSearch("");
    setInitFiltersDate(true);
    setDateInit("");
    setDateEnd("");
    setBranch("");
    setStatus("");
    setPayment("");
    setPaymentSelect([]);
    let parameters = {
      params: {
        status: "",
        branch: "",
        payment: "",
        date_init: "",
        date_end: "",
        client_id: getClientId(auth),
      },
    };

    dataFilter(parameters);
  };

  useEffect(() => {
    let paymentList = [];

    if (initFiltersDate) {
      setDateInit(dateDefaultFilter.dateInit);
      setDateEnd(dateDefaultFilter.dateEnd);
    }

    data.forEach((obj) => {
      Object.entries(obj).forEach(([key, value]) => {
        if (key === "method_payment" && !paymentList.find((element) => element === value)) paymentList.push(value);
      });
    });

    setPaymentSelect(paymentList);
    if (!branch && branches && branches.length > 0) {
      setBranch(branches[0].store_name);
    }
  }, [data, dateDefaultFilter, initFiltersDate, branches, branch]);

  const dateToday = new Date();
  const nameDateEnd = "dateEnd";
  const nameDateInit = "dateInit";

  const handleDate = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    if (!value) return;

    const dateSelected = new Date(
      parseInt(value.slice(0, 4)),
      parseInt(value.slice(5, 7)) - 1, //los meses empiezan con 0=enero
      parseInt(value.slice(8, 10))
    );

    if (name === nameDateInit) {
      if (dateSelected > dateEnd) {
        event.target.value = formatYMD(dateInit);

        return Alert(t("Insights.Transactions.DateInit.MaxDate"), "warning");
      }
    } else if (name === nameDateEnd) {
      if (dateSelected < dateInit) {
        event.target.value = formatYMD(dateEnd);

        return Alert(t("Insights.Transactions.DateEnd.MinDate"), "warning");
      } else if (dateSelected > dateToday) {
        event.target.value = formatYMD(dateEnd);

        return Alert(t("Insights.Transactions.DateEnd.MaxDate", { today: formatYMD(dateToday) }), "warning");
      }
    }

    if (name === nameDateInit) setDateInit(dateSelected);
    else if (name === nameDateEnd) setDateEnd(dateSelected);
  };

  const menuItem = (value, type) => {
    if (type === BRANCH_TYPE)
      return (
        <MenuItem key={value.client_id} value={value.store_name}>
          {value.store_name}
        </MenuItem>
      );
    else if (type === STATUS_TYPE)
      return (
        <MenuItem key={value.id} value={value.name}>
          {t(`Insights.Transactions.Table.${value.translation}`)}
        </MenuItem>
      );
    else
      return (
        <MenuItem key={value} value={value}>
          {value}
        </MenuItem>
      );
  };

  return (
    <Stack direction="row" width="100%" spacing={1} justifyContent="start">
      <Stack direction="row" width="550vh" spacing={1} justifyContent="start" sx={{ marginInlineStart: 2 }}>
        <TextField
          name={nameDateInit}
          id="dateInit"
          data-testid="dateInit"
          label={t("Filter.DateInit")}
          type="date"
          fullWidth
          size="small"
          onChange={handleDate}
          sx={{ background: "white", width: "155px" }}
          InputLabelProps={{
            shrink: true,
          }}
          value={formatYMD(dateInit)}
        />
        <TextField
          name={nameDateEnd}
          id="dateEnd"
          data-testid="dateEnd"
          label={t("Filter.DateEnd")}
          type="date"
          fullWidth
          size="small"
          onChange={handleDate}
          sx={{ background: "white", width: "155px" }}
          InputLabelProps={{
            shrink: true,
          }}
          value={formatYMD(dateEnd)}
        />
        <TextField
          id="outlined-branch"
          label={t("Filter.Branch")}
          select
          value={branch}
          onChange={(event) => {
            setBranch(event.target.value);
          }}
          sx={{ background: "white", width: "180px" }}
          fullWidth
          size="small"
          inputProps={{ id: "select", "data-testid": "select" }}
          InputLabelProps={{ style: { color: "#00377D" } }}
        >
          {branches.map((value) => menuItem(value, BRANCH_TYPE))}
        </TextField>
        <TextField
          id="outlined-status"
          label={t("Filter.Status")}
          select
          value={status}
          size="small"
          inputProps={{ "data-testid": "selectStatus" }}
          onChange={(event) => {
            setStatus(event.target.value);
          }}
          sx={{ background: "white", width: "160px" }}
          fullWidth
          InputLabelProps={{ style: { color: "#00377D" } }}
        >
          {statuses.map((value) => menuItem(value, STATUS_TYPE))}
        </TextField>
        <TextField
          id="outlined-payment"
          label={t("Filter.Payment")}
          value={payment}
          size="small"
          inputProps={{ "data-testid": "selectPayment" }}
          onChange={(event) => {
            setPayment(event.target.value);
          }}
          select
          sx={{ background: "white", width: "160px" }}
          fullWidth
          InputLabelProps={{ style: { color: "#00377D" } }}
        >
          {paymentSelect.map((value) => menuItem(value))}
        </TextField>
      </Stack>
      <Stack direction="row" width="100%" spacing={1} justifyContent="end">
        <CleanFilterButton filterFnc={cleanFilter} label={t("Filter.Clean")} />
        <FilterButton filterFnc={filterData} label={t("Filter.Filter")} />
      </Stack>
    </Stack>
  );
}

FilterDetails.propTypes = {
  branches: PropTypes.arrayOf(
    PropTypes.shape({
      store_name: PropTypes.string.isRequired,
    })
  ).isRequired,
};
