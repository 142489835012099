import React from "react";

import Sesion from "../components/commons/sesion";
import Topics from "../components/help/topics";

export default function Help() {
  return (
    <div className={"Drawer"}>
      <Sesion screen={"Help"} />
      <Topics />
    </div>
  );
}
