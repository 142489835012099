import countries from "../../translations/country.json";

function getCountry() {
  const result = window.location.origin;
  const div = result?.split(".");
  let country = "HONDURAS";
  for (const x in div) {
    for (const y in Object.keys(countries)) {
      if (div[x] === Object.keys(countries)[y]) {
        country = Object.values(countries)[y];
      }
    }
  }
  return country;
}

export { getCountry };
