import Email from "./inputs/email";
import Password from "./inputs/password";

const inputsComponents = {
  password: Password,
  email: Email,
};

const Input = ({ type, data }) => {
  const InputsComponent = inputsComponents[[type]];

  if (!InputsComponent) {
    return null;
  }
  data.props.touch = "false";
  if (!data.props.value) {
    let x = data.props.value;
    if (x === "" || x === null) {
      data.props.value = undefined;
    }
  }
  return <InputsComponent {...data} />;
};

const GenericInput = (props) => {
  return <Input type={props.props.type} data={props} />;
};

export default GenericInput;
