import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import BodyCard from "./body-card";

const MenuCard = ({ elements, contents, title, name }) => {
  const [value, setValue] = React.useState(0);
  const [content, setContent] = useState("");
  const [t] = useTranslation("global");
  const [subTitle, setSubTitle] = useState("");
  elements = elements.filter((data, index, array) => index === array.findIndex((i) => i.name === data.name));

  const handleChange = (_, newValue) => {
    setValue(newValue);
    setContent(contents[newValue]);
    setSubTitle(elements[newValue].name);
  };

  useEffect(() => {
    setContent(contents[value]);
    setSubTitle(elements[value].name);
  }, [contents, elements]);

  const styleTab = {
    justifyContent: "left",
    padding: "0 6px",
    minHeight: "50px",
    borderBottom: "1px solid #DFE3E7",
    alignItems: "left !important",
    textAlign: "left !important",
    fontWeight: "400",
    "&.Mui-selected": {
      borderColor: "#FABE00",
      color: "#4C5866",
      fontWeight: "600",
      minWidth: "200px",
    },
  };
  const styleTitle = {
    padding: "15px 10px 30px 10px",
  };
  return (
    <Fragment>
      <Card
        sx={{
          padding: "1px",
          borderRadius: "16px",
          height: "fit-content",
          minHeight: "500px",
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box>
            <Typography style={styleTitle}>{t(name)}</Typography>
            <Tabs
              orientation="vertical"
              value={value}
              onChange={handleChange}
              sx={{
                margin: "0 5px 0 5px",
              }}
              TabIndicatorProps={{ style: { width: 0 } }}
            >
              {elements.map((item, index) => (
                <Tab
                  key={index}
                  label={t(item.name)}
                  sx={styleTab}
                  iconPosition="start"
                  value={index}
                  data-testid={`${t(item.name)}-${index}`}
                />
              ))}
            </Tabs>
          </Box>
          <Divider orientation="vertical" flexItem />
          <BodyCard title={t(title)} subTitle={t(subTitle)} headerHandler={() => handleChange({}, 0)}>
            {content}
          </BodyCard>
        </Box>
      </Card>
    </Fragment>
  );
};
export default MenuCard;
