import * as Yup from "yup";

function schema(validations, type) {
  let y;
  switch (type) {
    case "email":
      y = Yup.string().email();
      break;
    case "password-confirm":
      y = Yup.string();
      break;
    case "number":
      y = Yup.number();
      break;
    case "date":
      y = Yup.date();
      break;
    default:
      y = Yup.string();
      break;
  }

  validations.forEach(({ type, params }) => {
    switch (type) {
      case "required":
        y = y.required(params[0].err);
        break;
      case "min":
        y = y.min(params[0], params[1]);
        break;
      case "max":
        y = y.max(params[0], params[1]);
        break;
      case "password-confirm":
        y = y.oneOf([Yup.ref("password"), null], params[0].err);
        break;
      default:
        break;
    }
  });

  return y;
}

function getYup(schemas) {
  return Yup.object().shape(schemas);
}

export { schema, getYup };
