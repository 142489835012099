import { axiosPrivate } from "../api/axios";
import { useAuthUser } from "react-auth-kit";



import useLogout from "./useLogout";


const useAxiosPrivate = () => {
  const useAuth = useAuthUser();
  const auth = useAuth();
  const logout = useLogout();

  const requestIntercept = axiosPrivate.interceptors.request.use(
    (config) => {
      if (!config.headers["Authorization"]) {
        config.headers["Authorization"] = auth?.authToken?.IdToken;
      }
      if (!config.headers["mts-token"]) {
        config.headers["mts-token"] = auth?.authToken?.IdToken;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  const responseIntercept = axiosPrivate.interceptors.response.use(
    (response) => response,
    async (error) => {
      logout();
      return Promise.reject(error);
    }
  );

  axiosPrivate.interceptors.request.use(requestIntercept);
  axiosPrivate.interceptors.response.use(responseIntercept);

  return axiosPrivate;
};

export default useAxiosPrivate;