import React from "react";

import { Button as _Button } from "@mui/material";

const Button = ({ btn, btnNext, btnBefore }) => {
  return (
    <_Button
      color="secondary"
      variant="contained"
      fullWidth
      type={btn.type}
      name={btn.name}
      onClick={btn.name === "next" ? () => btnNext() : btnBefore}
      className={btn.styleClassName}
      style={btn.styles}
    >
      {btn.label}
    </_Button>
  );
};
export default Button;
