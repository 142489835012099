import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Stack, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";

const Redirect = (props) => {
  const CHARACTER_LIMIT = 120;
  const [t] = useTranslation("global");
  const [campos, setCampos] = useState({
    url: "",
    message: "",
  });
  const [urlValid, setUrlValid] = useState(true);

  const handleCampoChange = (campo, value) => {
    setCampos((prevCampos) => ({
      ...prevCampos,
      [campo]: value,
    }));
    props.saveData(campo, value);
  };

  const validateUrlFormat = (e) => {
    let validUrl = false;
    try {
      new URL(e.target.value);
      validUrl = true;
    } catch (err) {
      validUrl = false;
    }
    setUrlValid(validUrl);
  };

  useEffect(() => {
    const loadData = (data) => {
      setCampos({
        url: data.url,
        message: data.message,
      });
    };

    if (props.data) {
      loadData(props.data);
    }
  }, [props.data]);

  return (
    <Stack>
      <Stack sx={{ marginBottom: "32px" }} spacing={1}>
        <Typography variant="titleFieldIntegration">
          {t("Integrations.ReservationOTP.Implementation.Add.Redirect.URLTitle")}
        </Typography>
        <Typography variant="descFieldIntegration">
          {t("Integrations.ReservationOTP.Implementation.Add.Redirect.URLDesc")}
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center" sx={{ margin: "12px" }}>
          <TextField
            fullWidth
            label={t("Integrations.ReservationOTP.Implementation.Add.Redirect.URLLabel")}
            size="small"
            data-testid="url_redirect"
            required={props.required}
            value={campos.url}
            onChange={(e) => handleCampoChange("url", e.target.value)}
            onBlur={(e) => validateUrlFormat(e)}
            sx={{ backgroundColor: "#FFFFFF", marginTop: "12px" }}
            error={!urlValid}
            helperText={
              <Box
                component="span"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#B00020",
                }}
              >
                <span>
                  {`${!urlValid ? t("Integrations.ReservationOTP.Implementation.Add.Redirect.URLHelper") : ""}`}
                </span>
              </Box>
            }
            FormHelperTextProps={{ sx: { backgroundColor: "#F8F9FA", margin: "0px", paddingTop: "10px" } }}
          />
        </Stack>
      </Stack>
      <Stack sx={{ marginBottom: "32px" }} spacing={1}>
        <Typography variant="titleFieldIntegration">
          {t("Integrations.ReservationOTP.Implementation.Add.Redirect.MessageTitle")}
        </Typography>
        <Typography variant="descFieldIntegration">
          {t("Integrations.ReservationOTP.Implementation.Add.Redirect.MessageDesc")}
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center" sx={{ margin: "12px" }}>
          <TextField
            fullWidth
            label={t("Integrations.ReservationOTP.Implementation.Add.Redirect.MessageLabel")}
            size="small"
            data-testid="message_redirect"
            required={props.required}
            value={campos.message}
            onChange={(e) => handleCampoChange("message", e.target.value)}
            sx={{ backgroundColor: "#FFFFFF", marginTop: "12px" }}
            inputProps={{
              maxLength: CHARACTER_LIMIT,
            }}
            helperText={
              <Box
                component="span"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: campos.message.length < CHARACTER_LIMIT ? "#708195" : "#E29836",
                }}
              >
                <span>
                  {t("Integrations.ReservationOTP.Implementation.Add.Redirect.MessageHelper", {
                    maxCharacter: CHARACTER_LIMIT,
                  })}
                </span>
                <span>{`${campos.message != undefined ? campos.message.length : 0} / ${CHARACTER_LIMIT}`}</span>
              </Box>
            }
            FormHelperTextProps={{ sx: { backgroundColor: "#F8F9FA", margin: "0px", paddingTop: "10px" } }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

Redirect.propTypes = {
  data: PropTypes.array.isRequired,
  saveData: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
};

export default Redirect;
