import React from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

import ExampleImage from "../../assets/example/paymentexample1.svg";

const Authentication = ({ Title, Text, TopicTitle, TextTopic }) => {
  const [t] = useTranslation("global");
  const generalText = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#404A56",
    margin: "0 10px 0 10px",
  };

  const styles = {
    Title: {
      ...generalText,
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "18px",
    },
    Text: {
      ...generalText,
      fontSize: "14px",
      fontWeight: 300,
      lineHeight: "22px",
    },
    TopicTitle: {
      ...generalText,
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "21px",
    },
    TextTopic: {
      ...generalText,
      fontSize: "14px",
      fontWeight: 300,
      lineHeight: "22px",
    },
  };
  const stylesBox = {
    General: {
      margin: "15px 10px 15px 0",
    },
    Text: {
      margin: "15px 10px 25px 0",
    },
    Image: {
      left: "422px",
      top: "553px",
      borderRadius: "0px",
    },
  };
  const Topic = () => {
    return (
      <Box>
        <Box sx={stylesBox.General}>
          <Typography style={styles.TextTopic}>{t(TextTopic)}</Typography>
        </Box>
        <Box data-testid="Authentication-example" sx={stylesBox.Image}>
          <img style={{ width: "100%" }} src={ExampleImage} alt="Example"></img>
        </Box>
      </Box>
    );
  };
  return (
    <Box>
      <Box sx={stylesBox.General}>
        <Typography style={styles.Title}>{t(Title)}</Typography>
        <Box sx={stylesBox.Text}>
          <Typography style={styles.Text}>{t(Text)}</Typography>
        </Box>
      </Box>
      <Box>
        <Box sx={stylesBox.General}>
          <Typography style={styles.TopicTitle}>{t(TopicTitle)}</Typography>
        </Box>

        <Box>
          <Topic />
        </Box>
      </Box>
    </Box>
  );
};
export default Authentication;
