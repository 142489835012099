import React, { Fragment, useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";

import { Button, Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { validateexp } from "../../utils/validateExpression";
import { Alert, Confirm } from "../commons/alert";
import GenericBackdrop from "../commons/GenericBackdrop";
import MultipleSelect from "../commons/MultipleSelect";

const Editusers = ({ type, data, activate }) => {
  const useAuth = useAuthUser();
  const auth = useAuth();
  const [t] = useTranslation("global");
  const axiosPrivate = useAxiosPrivate();
  const [loader, setLoader] = useState(false);
  const [firstName, setFirstName] = useState({
    value: data.names,
    error: false,
    errorDescription: "",
  });
  const [lastName, setLastName] = useState({
    value: type === "create" ? "" : `${data.first_name} ${data.second_name}`,
    error: false,
    errorDescription: "",
  });
  const [email, setEmail] = useState({
    value: data.email,
    error: false,
    errorDescription: "",
  });
  const [store, setStore] = useState(data.store_id);
  const [role, setRole] = useState(data.role_id);
  const [disabled, setDisabled] = useState(false);
  const [getStore, setGetStore] = useState(data.store_id ? [{ id: data.store_id }] : [{ "": "" }]);
  const [getRole, setGetRole] = useState(data.role_id ? [{ id: data.role_id }] : [{ "": "" }]);
  let fields = false;
  let saveValues = {};
  let isConfirm = false;
  const expressions = {
    user: /^[a-zA-Z0-9_-]{4,16}$/, // Letras, numeros, guion y guion_bajo
    name: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
    password: /^.{4,12}$/, // 4 a 12 digitos.
    email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    phoneNumber: /^\d{7,14}$/, // 7 a 14 numeros.
  };

  const getValueStore = (value) => {
    setStore(value);
  };

  const getValueRole = (value) => {
    setRole(value);
  };

  const validateData = () => {
    let lastName1 = lastName.value;
    let lastName2;
    if (lastName.value.split(" ").length > 1) {
      lastName1 = lastName.value.split(" ")[0];

      lastName2 = lastName.value.split(" ");
      lastName2 = lastName2.slice(1).join(" ");
    } else {
      lastName2 = "";
    }

    saveValues = {
      names: firstName.value,
      first_name: lastName1,
      second_name: lastName2,
      email: email.value,
      store,
      role,
    };

    for (const x in saveValues) {
      if (saveValues[x] === undefined || saveValues[x] === null || saveValues[x] === "") {
        if (x !== "second_name") {
          return Alert(t("Users.Message.ErrorFields"), "error");
        } else {
          fields = true;
        }
      }
    }
    if (firstName.error || lastName.error || email.error) {
      return Alert(t("Users.Message.ErrorValue"), "error");
    } else {
      fields = true;
    }
    editData().then(() => {
      if (isConfirm) {
        saveData();
      }
    });
  };

  const editData = async () => {
    isConfirm = type === "edit" ? await Confirm("", t("General.Message.Edit"), "warning") : true;
  };

  const setGTM = (event) => {
    let tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM,
      dataLayer: {
        merchant_name: auth.businessName,
        merchant_id: auth.userId,
        event: event,
      },
    };
    TagManager.initialize(tagManagerArgs);
  };

  const saveData = () => {
    setLoader(true);
    if (fields) {
      if (type === "create") {
        axiosPrivate
          .post(`users`, JSON.stringify(saveValues))
          .then((response) => {
            setLoader(false);
            return response?.data?.hasOwnProperty("messageDescription")
              ? Alert(response?.data?.messageDescription, "warning")
              : (setGTM("newUserCreated"), activate(false), Alert(t("Users.Message.SuccessText"), "success"));
          })
          .catch((err) => {
            setLoader(false);
            if (err?.["response"]?.["data"]["errorDescription"]) {
              return Alert(err?.["response"]?.["data"]["errorDescription"], "error");
            } else {
              return Alert(t("Users.Message.ErrorService"), "error");
            }
          });
      } else if (type === "edit") {
        saveValues.id = data.user_id;
        axiosPrivate
          .patch(`users`, JSON.stringify(saveValues))
          .then(() => {
            setGTM("userEdited");
            setLoader(false);
            Alert(t("Users.Message.SuccessEdit"), "success").then(() => {
              activate(false);
            });
          })
          .catch(() => {
            setLoader(false);
            Alert(t("Users.Message.ErrorService"), "error");
          });
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getStores = async () => {
      try {
        const response = await axiosPrivate.get(`stores`, {
          signal: controller.signal,
        });

        const newJson = [];
        for (const x in response.data.data) {
          const json = {
            id: response.data.data[x].store_id,
            name: response.data.data[x].name.split("|")[0],
          };
          newJson.push(json);
        }
        isMounted && setGetStore(newJson);
      } catch {
        await Alert(t("General.Message.ErrorService"), "error");
      }
    };

    const getRoles = async () => {
      try {
        const response = await axiosPrivate.get(`users/roles`, {
          signal: controller.signal,
        });
        isMounted && setGetRole(response.data);
      } catch {
        await Alert(t("General.Message.ErrorService"), "error");
      }
    };

    getStores();
    getRoles();
    if (type === "create") {
      setDisabled(false);
    } else if (type === "edit") {
      setDisabled(true);
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const titleSelector = () => {
    if (type === "edit") return t("Users.EditUser");
    if (type === "create") return t("Users.AddUser");
  };

  return (
    <Fragment>
      <GenericBackdrop open={loader} />
      <Card
        style={{
          borderRadius: "16px",
          boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
        }}
      >
        <div style={{ width: "330px" }}>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            sx={{
              paddingTop: "32px",
              fontWeight: 700,
              fontSize: "18px",
              color: "#404A56",
              textAlign: "initial",
              marginLeft: "30px",
            }}
          >
            {titleSelector()}
          </Typography>
          <Grid style={{ paddingTop: "10px" }} container direction="row" align="center">
            <Grid item xs={1}></Grid>
            <Grid style={{ paddingBottom: "60px" }} item xs={11} textAlign={"center"} align="center">
              <TextField
                onChange={(e) =>
                  validateexp(e.target.value, expressions.name, firstName, setFirstName, t("Users.Errors.Letters"))
                }
                label={t("Users.Name")}
                defaultValue={firstName.value}
                id="margin-normal"
                margin="normal"
                size="small"
                style={{ width: "100%", borderRadius: "8px" }}
                helperText={firstName.errorDescription}
                error={firstName.error}
                InputLabelProps={{
                  style: { color: "#404A56", fontSize: "14px" },
                }}
                InputProps={{
                  inputProps: { "data-testid": "firstNametxt" },
                  style: {
                    fontSize: "14px",
                    color: "#404A56",
                    fontWeight: 400,
                  },
                }}
              />
              <TextField
                onChange={(e) =>
                  validateexp(e.target.value, expressions.name, lastName, setLastName, t("Users.Errors.Letters"))
                }
                label={t("Users.LastName")}
                defaultValue={lastName.value}
                id="margin-normal"
                margin="dense"
                size="small"
                style={{ width: "100%" }}
                helperText={lastName.errorDescription}
                error={lastName.error}
                InputLabelProps={{
                  style: { color: "#404A56", fontSize: "14px" },
                }}
                InputProps={{
                  inputProps: { "data-testid": "lastNametxt" },
                  style: {
                    fontSize: "14px",
                    color: "#404A56",
                    fontWeight: 400,
                  },
                }}
              />
              <TextField
                disabled={disabled}
                onChange={(e) =>
                  validateexp(e.target.value, expressions.email, email, setEmail, t("Users.Errors.Email"))
                }
                label={t("Users.Email")}
                defaultValue={email.value}
                id="margin-normal"
                margin="dense"
                size="small"
                style={{ width: "100%" }}
                helperText={email.errorDescription}
                error={email.error}
                InputLabelProps={{
                  style: { color: "#404A56", fontSize: "14px" },
                }}
                InputProps={{
                  inputProps: { "data-testid": "emailtxt" },
                  style: {
                    fontSize: "14px",
                    color: "#00377D",
                    fontWeight: 400,
                  },
                }}
              />
              <MultipleSelect
                title={"Users.Store"}
                data={getStore}
                getvalue={getValueStore}
                select={"Users.SelectStore"}
              ></MultipleSelect>
              <MultipleSelect
                title={"Users.Role"}
                data={getRole}
                getvalue={getValueRole}
                select={"Users.SelectRole"}
              ></MultipleSelect>
            </Grid>
          </Grid>
        </div>
      </Card>
      <div
        style={{
          textAlign: "right",
          marginBlockEnd: "30px",
          marginTop: "30px",
        }}
      >
        <Button
          variant="outlined"
          style={{
            background: "white",
            color: "#404A56",
            textTransform: "none",
            minWidth: "180px",
            borderColor: "#FFC10E",
            height: "48px",
          }}
          onClick={() => activate(false)}
          className="action-button-outlined"
        >
          {t("Buttons.Cancel")}
        </Button>

        <Button
          style={{
            background: "#FFC10E",
            color: "#404A56",
            marginLeft: "10px",
            textTransform: "none",
            minWidth: "180px",
            height: "48px",
          }}
          onClick={() => validateData()}
          className="action-button"
        >
          {t("Buttons.Save")}
        </Button>
      </div>
    </Fragment>
  );
};

export default Editusers;
