import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { v4 as uuidv4 } from "uuid";

import Theme from "../../themes/Theme";

import { isEmptyObject } from "../../utils/validateExpression";

const useStyles = makeStyles(() => ({
  header: {
    fontWeight: Theme.tableStyle.tableHeaders.fontWeightImportant,
  },
}));

const BasicTable = ({
  dataTable,
  dataColumns,
  action,
  condition = [],
  align = "center",
  columnsSort = ["amount", "store_name", "date"],
}) => {
  const [t] = useTranslation("global");
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [rowData, setRowData] = useState(dataTable);

  //sort columns
  const ascending = "asc";
  const descending = "desc";

  const initArrowColumns = () => {
    let arrow = {};

    if (!isEmptyObject(columnsSort)) {
      columnsSort.forEach((column) => {
        arrow[column] = ascending;
      });
    }

    return arrow;
  };

  const [arrowColumns, setArrowColumns] = useState(initArrowColumns);

  useEffect(() => {
    setRowData(dataTable);
    setPage(0);
  }, [dataTable]);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const stringParse = (text) => {
    if (isEmptyObject(text)) return null;

    return text.toString().toLowerCase();
  };

  const numberParse = (text) => {
    if (isEmptyObject(text)) return null;

    return Number(text.toString().replace(/[^0-9.-]+/g, ""));
  };

  const sortColumn = (fieldName, fun = stringParse) => {
    return rowData.sort(sortBy(fieldName, arrowColumns[fieldName] === descending, fun));
  };

  const sortBy = (fieldName, reverse, fun) => {
    const key = function (x) {
      return fun(x[fieldName]);
    };

    reverse = !reverse ? 1 : -1;

    return function (a, b) {
      a = key(a);
      b = key(b);
      return reverse * ((a > b) - (b > a));
    };
  };

  const handleSortRequest = (col) => {
    const fieldName = col.split(".").pop();

    switch (fieldName) {
      case "amount":
      case "transaction_id":
        setRowData(sortColumn(fieldName, numberParse));
        break;
      case "date":
        setRowData(sortColumn(fieldName, Date.parse));
        break;
      default:
        setRowData(sortColumn(fieldName));
        break;
    }

    directionArrow(fieldName);
  };

  const directionArrow = (fieldName) => {
    arrowColumns[fieldName] = arrowColumns[fieldName] === ascending ? descending : ascending;
    setArrowColumns({ ...arrowColumns });
  };

  if (Object.keys(dataTable).length === 0)
    return <div className="no-information-message">{t("Users.Delete.NoUsers")}</div>;

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  const getOrder = (fieldName) => {
    return arrowColumns[fieldName];
  };

  const getTableCell = (column, idx) => {
    const fieldName = column.split(".").pop();

    return (
      <TableCell key={idx} align={align} className={classes.header}>
        {columnsSort.includes(fieldName) ? (
          <TableSortLabel active={true} direction={getOrder(fieldName)} onClick={() => handleSortRequest(column)}>
            {t(column)}
          </TableSortLabel>
        ) : (
          t(column)
        )}
      </TableCell>
    );
  };

  const removeColumns = (row) => {
    const rowClone = { ...row };
    const notShow = "findByStav";

    if (Object.hasOwn(rowClone, notShow)) delete rowClone[notShow];

    return rowClone;
  };

  return (
    <div>
      <div>
        <Paper
          sx={{
            boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
            borderRadius: "16px",
          }}
        >
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {dataColumns.map((col, idx) =>
                    col.includes("Action") ? (
                      <TableCell key={idx} align="center" colSpan="2" className={classes.header}>
                        {t(col)}
                      </TableCell>
                    ) : (
                      getTableCell(col, idx)
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                  const rowClone = removeColumns(row);

                  return (
                    <StyledTableRow key={uuidv4()}>
                      {Object.keys(rowClone).map((cell, index) => {
                        if (index + 1 === Object.keys(rowClone).length) {
                          if (condition.includes(cell)) {
                            return action !== undefined && action(rowClone, i);
                          } else {
                            return (
                              <Fragment key={uuidv4()}>
                                <TableCell key={uuidv4()} align={align} style={Theme.tableStyle.tableBody}>
                                  {rowClone[cell]}
                                </TableCell>
                                {action !== undefined && action(rowClone, i)}
                              </Fragment>
                            );
                          }
                        } else {
                          if (!condition.includes(cell)) {
                            return (
                              <TableCell key={uuidv4()} align={align} style={Theme.tableStyle.tableBody}>
                                {rowClone[cell]}
                              </TableCell>
                            );
                          }
                        }
                      })}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={dataTable.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t("Insights.Transactions.Table.LabelRowsPerPage")}
          />
        </Paper>
      </div>
    </div>
  );
};

export default BasicTable;
