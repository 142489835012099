import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Iframe from "react-iframe";
import { useLocation, useNavigate } from "react-router-dom";

import { Box, Container } from "@mui/material";

import { SnackBar } from "../components/commons/alert";
import GenericBackdrop from "../components/commons/GenericBackdrop";
import OnBoardingLayout from "../components/on-boarding-layout";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function FormScreen() {
  const i18n = useTranslation("global").i18n;
  const [t] = useTranslation("global");
  const [loader, setLoader] = useState(false);
  const [userId, setUserId] = useState("");
  const [url, setUrl] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const queryParameters = new URLSearchParams(location.search);
  const action = queryParameters.get("action");
  const token = queryParameters.get("token");
  const email = action === null && token === null ? location.state?.email : queryParameters.get("email");
  const phone = action === null && token === null ? location.state?.phone : queryParameters.get("phone");
  const password = location.state?.password;

  const styles = {
    contentView: {
      height: "100%",
      webkitBoxShadow: "1px 3px 1px #9E9E9E",
      mozBoxShadow: "1px 3px 1px #9E9E9E",
      boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
      borderRadius: "16px",
      backgroundColor: "white",
      margin: "50px 0px",
    },
  };
  const goToContract = () => {
    navigate("/MessageScreen", {
      screen: "MessageScreen",
      state: {
        type: {
          data: {
            image: "pending-validate",
            title: "¡Gracias por completar tu información!",
            subTitle:
              "Nuestro equipo validará tu registro y te notificaremos por correo electrónico una vez que se complete el proceso.",
          },
        },
      },
    });
  };

  //Function to validate token to access to form kym
  const validateToken = (token) => {
    axiosPrivate
      .put(`verifyEmailToken`, {
        token: token,
      })
      .then((response) => {
        if (
          response.data.message === "General.Message.ExpiredToken" ||
          response.data.message === "General.Message.InvalidToken"
        ) {
          navigate("/SignIn");
          SnackBar(t(response.data.message), "error");
        } else if (response.data.message === "General.Message.ValidToken") {
          setUrl(
            `${process.env.REACT_APP_KYM_DOMAIN}/?form_id=F0009&country=${i18n.language}&email=${email}&phone=${phone}&action=${action}`
          );
        }
      })
      .catch((err) => {
        navigate("/SignIn");
        SnackBar(err, "error");
      });
  };

  const runAction = (action, token) => {
    validateToken(token);
  };

  useEffect(() => {
    /*Add this validation to verify to token in case that find the action param
      -In case that token is invalid redirect the user at signin page
      -In case that toke is valid add in the url of the kym the action param
    */
    if (action !== null) {
      runAction(action, token);
    } else {
      setUrl(`${process.env.REACT_APP_KYM_DOMAIN}/?form_id=F0009&country=${i18n.language}&email=${email}`);
    }
    window.addEventListener("message", (e) => {
      if (e.origin.match(process.env.REACT_APP_KYM_DOMAIN)) {
        if (e.data.message === "LOADING") {
          setLoader(true);
        }
        if (e.data.message === "UPDATED" || (e.data.message === "SUCCESS" && userId !== "")) {
          goToContract();
        } else if (e.data.message === "CREATE") {
          setLoader(true);
          e.data.data["password"] = password;
          e.data.data["email"] = email;
          axiosPrivate
            .post(`merchants`, e.data.data)
            .then((response) => {
              setUserId(response.data.id);
            })
            .catch((err) => {
              setLoader(false);
              SnackBar(err?.response?.data?.error, "error");
            });
        }
      }
    });
  }, []);
  useEffect(() => {
    if (userId !== "") {
      setLoader(false);
      goToContract();
    }
  }, [userId]);
  return (
    <Fragment>
      <GenericBackdrop open={loader} />
      <Box sx={{ height: "75%", zIndex: 100, width: "100%" }} mt={12}>
        <Container sx={{ height: "100%", position: "absolute", padding: "50px 0 0 0" }} maxWidth={false}>
          <Box sx={styles.contentView}>
            <Iframe url={url} width="100%" height="950px" className="iFrame" />
          </Box>
        </Container>
      </Box>
    </Fragment>
  );
}
export default function FormKym() {
  return <OnBoardingLayout screen={<FormScreen />} withoutContainer={true} />;
}
