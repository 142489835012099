import { Fragment, React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { SnackBar } from "./../components/commons/alert";
import GenericBackdrop from "./commons/GenericBackdrop";
import dataJson from "./config/forgot-password.json";
import GenericForm from "./genericForm";
import { getYup, schema } from "./utils/yup";

import useAxiosPrivate from "../hooks/useAxiosPrivate";

export default function FormForgotPassword() {
  const [t] = useTranslation("global");
  const [data, setData] = useState(dataJson.forms);
  const [validates, setValidates] = useState({});
  const [step, setStep] = useState(1);
  const [loader, setLoader] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    setLoader(true);

    await axiosPrivate
      .patch(`users/password`, {
        email: values.email,
      })
      .then(() => {
        msgFn(false);
      })
      .catch((err) => {
        msgFn(err?.response?.status !== 404);
      });
  };

  const msgFn = (hasError) => {
    let newData = { ...data };
    console.log("hasError: ", hasError);
    if (hasError || null) {
      SnackBar(t("General.Message.ErrorService"), "error");
    } else {
      newData.fields[0].props.legend = t("ForgotPassword.Step1.FieldEmail.Props.Legend");
      newData.actionsButtons[0].label = "ForgotPassword.Step1.ActionsButtons.LabelReturnLogin";
      newData.fields[0].props.type = "";
      newData.footer.textlink = "";
      setData(newData);
      setStep(2);
    }
    setLoader(false);
  };

  const backToLogin = () => {
    navigate("/SignIn", {
      replace: true,
    });
  };

  useEffect(() => {
    let _validates = {};
    dataJson.forms.fields.forEach(({ props, validations }) => {
      _validates[props.name] = schema(validations, props.type);
    });
    setValidates(_validates);
  }, []);

  return (
    <Fragment>
      <GenericBackdrop open={loader} />
      <GenericForm
        form={dataJson.forms}
        initialValue={{}}
        validates={getYup(validates)}
        onSubmit={step == 1 ? handleSubmit : backToLogin}
      />
    </Fragment>
  );
}
