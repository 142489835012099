import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import FaceIcon from "@mui/icons-material/Face";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import Account from "../components/bank-account/bankAccount";
import { Alert } from "../components/commons/alert";
import Sesion from "../components/commons/sesion";
import Integrations from "../components/integrations/integrations";
import ConfigEcommerce from "../components/integrations/ecommerce/ecommerce";
import ConfigReservationOTP from "../components/integrations/reservation-otp/reservationOTP";
import Stores from "../components/stores/stores";
import Users from "../components/users/users";
import { permissionsContext } from "../components/check-permissions/checkPermissions";

export default function Configuration() {
  const [value, setValue] = React.useState(0);
  const arrTabs = [];
  const [t] = useTranslation("global");
  const [render, setRender] = useState("");
  const [renderEcommerce, setRenderEcommerce] = useState(false);
  const [renderOTP, setRenderOTP] = useState(false);
  const permissions = useContext(permissionsContext);

  const handleIntegration = (index) => {
    if (index.toString() === "0") {
      setRender("Stores");
      setValue(1);
      if (localStorage.getItem("storesEmpty") === "true") {
        Alert(t("Integrations.Alert.AddStores"), "");
      } else {
        Alert(t("Integrations.Alert.Stores"), "");
      }
    } else if (index.toString() === "1") {
      setRenderOTP(true);
    } else if (index.toString() === "2") {
      setRenderEcommerce(true);
    } else {
      setRenderEcommerce(false);
      setRenderOTP(false);
    }
  };

  const fnGate = (tabName) => {
    arrTabs.push(tabName);    
    return (
      <Tab
        icon={tabName === "Users" ? <FaceIcon /> : ""}
        iconPosition={"end"}
        onClick={() => handleTabs(tabName)}
        label={t("Configuration." + tabName)}
        style={{
          width: "172px",
          fontFamily: "Roboto",
          fontSize: "18px",
          fontWeight: "400",
          color: "#4C5866",
        }}
      />
    );
  };

  const handleTabs = (tabName) => {
    setRender(tabName);
    const index = arrTabs.indexOf(tabName);
    setValue(index);
  };

  const renderiza = () => {
    switch (render) {
      case "Users": {
        return <Users />;
      }
      case "Stores": {
        return <Stores />;
      }
      case "BankAccount": {
        return <Account />;
      }
      case "Integrations": {
        if (renderEcommerce) {
          return <ConfigEcommerce onClickIntegration={handleIntegration} />;
        } else if (renderOTP) {
          return <ConfigReservationOTP onClickIntegration={handleIntegration} />;
        } else {
          return <Integrations onClick={handleIntegration} />;
        }
      }
      default:
        return permissions?.configuration?.users?.actions?.view === true ? <Users /> : <Stores />;
    }
  };

  return (
    <div className={"Drawer"}>
      <Sesion screen={"Configuration"} />

      <Tabs value={value} variant="scrollable" scrollButtons allowScrollButtonsMobile>
        {fnGate("Users")}
        {fnGate("Stores")}
        {fnGate("BankAccount")}
        {fnGate("Integrations")}
      </Tabs>
      <Divider sx={{ background: "#DFE3E7" }} />
      <div style={{ paddingTop: "50px" }}>{renderiza()}</div>
    </div>
  );
}
