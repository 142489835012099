import React from "react";

import CheckPermisions from "../components/check-permissions/checkPermissions";
import Sesion from "../components/commons/sesion";
import QrGenerate from "../components/qr/qrGenerate";

export default function Payments() {
  return (
    <div className={"Drawer"}>
      <Sesion screen={"Payments"} />
      <CheckPermisions>
        <QrGenerate merchant="Costa del Este-Café Espresso" idMerchant="345GDK13"></QrGenerate>
      </CheckPermisions>
    </div>
  );
}
