import { useSignOut } from "react-auth-kit";

const useLogout = () => {
  const signOut = useSignOut();
  return () => {
    signOut();
    localStorage.clear();
  };
};

export default useLogout;
