import { default as img } from "./inputs/image";

const imageComponents = {
  image: img,
};

const Image = ({ type, data }) => {
  const ImageComponent = imageComponents[[type]];
  if (!ImageComponent) {
    return null;
  }
  return <ImageComponent img={data} />;
};

const GenericImage = ({ props }) => {
  return <Image type={props.type} data={props} />;
};

export default GenericImage;
