import React, { Fragment, useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import Iframe from "react-iframe";
import { useLocation, useNavigate } from "react-router-dom";

import { Box, Container, Stack, Typography } from "@mui/material";
import FileSaver from "file-saver";
import parse from "html-react-parser";

import signContract from "../assets/generic/signContract.svg";

import { SnackBar } from "./../components/commons/alert";
import GenericBackdrop from "./../components/commons/GenericBackdrop";
import { AcceptContract, DownloadContract } from "./../components/contract-actions/contractActions";

import OnBoardingLayout from "../components/on-boarding-layout";
import { getCountry } from "../components/utils/getCountry";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useLogout from "../hooks/useLogout";
import { anexoA, anexoB, contractHN } from "../translations/contracts";

function ContractScreen() {
  const useAuth = useAuthUser();
  const auth = useAuth();
  const logout = useLogout();
  const [loader, setLoader] = useState(false);
  const [loaderToSign, setLoaderToSign] = useState(false);
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const email = location.state.email;
  const [dataKym, setDataKym] = useState(null);
  const [commission, setCommission] = useState(null);
  const contractKYM = contractHN;
  const [country] = useState(getCountry());
  const [urlContract, setUrlContract] = useState("");
  const months = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  const date = new Date();
  let typeContract;
  let typeContractPerson;
  let nameContract;
  let lastNameContract;
  const actions = [{ component: AcceptContract }];

  const HtmlText = (code) => {
    return parse(code);
  };

  const handleGetContractVoucher = async (download) => {
    const bucket = process.env.REACT_APP_BUCKET_CONTRACT;
    let key = download ? "v1/voucher.pdf" : "v1/Contrato base merchant.pdf";

    axiosPrivate
      .get(`/merchants/files`, {
        params: { bucket: bucket, key: key },
      })
      .then((urlOriginal) => {
        if (download) {
          FileSaver.saveAs(urlOriginal.data, "voucher.pdf");
        } else {
          setUrlContract(urlOriginal.data);
        }
      })
      .catch((_err) => {
        SnackBar(t("General.Message.ErrorService"), "error");
      });

    setLoaderToSign(false);
  };

  const handleNextUpload = () => {
    navigate("/UploadContractScreen");
  };

  const handleNext = () => {
    setLoaderToSign(true);
    axiosPrivate
      .patch(`signContract`, {
        user_id: auth?.userId,
      })
      .then((response) => {
        if (response.data.message === "Sended email") {
          navigate("/messageScreen", {
            screen: "MessageScreen",
            state: {
              type: response.data.data_kym,
            },
          });
        }
        setLoaderToSign(false);
      })
      .catch((_) => {
        setLoaderToSign(false);
        SnackBar(t("General.Message.ErrorService"), "error");
      });
    logout();
  };

  const handleReturn = () => {
    navigate("/SignIn");
    logout();
  };

  const styles = {
    contentView: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      webkitBoxShadow: "1px 3px 1px #9E9E9E",
      mozBoxShadow: "1px 3px 1px #9E9E9E",
      boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
      borderRadius: "16px",
      backgroundColor: "white",
      margin: "0px 72px 0px 72px",
    },

    contractContainer: {
      overflowY: "scroll",
      height: "80%",
      "&::-webkit-scrollbar": {
        width: 11,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#FFFFFF",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#C4C4C4",
        borderRadius: 10,
      },
    },
    contractContainerPDF: {
      height: "80%",
    },
    containerView: {
      height: "55%",
      position: "absolute",
      zIndex: "auto",
      scroll: "auto",
      width: "100%",
    },
  };

  const renderAction = () => {
    let ActionComponent;
    ActionComponent = actions[0].component;
    if (typeContract == "digital" || typeContract == "digitalWOTerms") {
      let checkValue = typeContract == "digitalWOTerms";
      return <ActionComponent handleReturn={handleReturn} handleNext={handleNext} checkedView={checkValue} />;
    } else if (typeContract == "download") {
      return (
        <DownloadContract handleDownload={() => handleGetContractVoucher(true)} handleNextUpload={handleNextUpload} />
      );
    }
  };

  const renderContract = () => {
    if (typeContract == "digital") {
      return (
        <Box mt={4} pr={2} sx={styles.contractContainer}>
          <Typography align="justify" variant="body" component="div" gutterBottom>
            {HtmlText(
              t(typeContractPerson, {
                name: nameContract,
                lastname: lastNameContract,
                identityDocument: dataKym.Negocio["general-data"]["identity-document"],
                idDocument: dataKym.Negocio["general-data"]["id-document"],
                street: dataKym.Negocio["comertial-information"].street,
                city: dataKym.Negocio["comertial-information"].city,
                state: dataKym.Negocio["comertial-information"].state,
                country: country,
                businessName: dataKym.Negocio["comertial-information"]["business-name"],
                email: dataKym.email,
                rtn: dataKym.Negocio["comertial-information"].rtn,
                contract: contractKYM,
                currentDate: date.getDate() + " de " + months[date.getMonth()] + " de " + date.getFullYear(),
              })
            )}
            {HtmlText(anexoA.replace("[CONTRACT_TYPE]", commission))}
            {HtmlText(anexoB)}
          </Typography>
        </Box>
      );
    } else if (typeContract == "download" || typeContract == "digitalWOTerms") {
      return (
        <Box mt={4} pr={2} sx={styles.contractContainerPDF}>
          <Iframe
            title="Contract pdf"
            url={urlContract + "#toolbar=0" + "&view=FitH"}
            width="100%"
            height="100%"
            data-testid="iframe"
            styles={{ height: "100%", with: "100vw !important" }}
          />
        </Box>
      );
    }
  };

  const getCommission = async (controller) => {
    try {
      const commisionResp = await axiosPrivate.get(`commissions/${email}`, {
        signal: controller.signal,
      });

      setCommission(commisionResp.data.commission.percent);
    } catch (error) {
      navigate("/SignIn");
      SnackBar(t("General.Message.ErrorService"), "error");
    }

    setLoader(false);
  };

  const getDataKym = () => {
    const controller = new AbortController();
    setLoader(true);

    axiosPrivate
      .get(`kym-user`, {
        params: {
          email: email,
        },
        signal: controller.signal,
      })
      .then((dataKYMResp) => {
        setDataKym(dataKYMResp.data);
        getCommission(controller);
      })
      .catch((_err) => {
        navigate("/SignIn");
        SnackBar(t("General.Message.ErrorService"), "error");
      });
    setLoader(false);
    return () => {
      controller.abort();
    };
  };

  const contractFLow = () => {
    if (dataKym !== null) {
      typeContract = "digital";
      typeContractPerson = "Contract.Contract";
      nameContract = dataKym.Negocio["general-data"].name;
      lastNameContract = dataKym.Negocio["general-data"].lastname;
      switch (country) {
        case "PARAGUAY":
          if (dataKym?.Negocio["Tipo-empresa"]?.type_of_company == "persona_natural") {
            typeContractPerson = "Contract.ContractNP";
            typeContract = "digitalWOTerms";
            nameContract = dataKym.Negocio["general-data"].name;
            lastNameContract = dataKym.Negocio["general-data"].lastname;
          } else if (dataKym?.Negocio["Tipo-empresa"]?.type_of_company == "persona_juridica") {
            typeContract = "download";
            typeContractPerson = "Contract.ContractJP";
            nameContract = dataKym.Negocio["general-data"]["legal-name"];
            lastNameContract = dataKym.Negocio["general-data"]["legal-lastname"];
          }
          break;
        case "HONDURAS":
          break;
        case "BOLIVIA":
          break;
        case "SALVADOR":
          break;
        case "PANAMA":
          break;
        case "GUATEMALA":
          break;
      }
      return (
        <Container sx={{ height: "100%", paddingTop: "50px" }} maxWidth={false}>
          <Box sx={styles.contentView} p={5}>
            <Box>
              <Stack direction="row" width="100%">
                <Stack>
                  <Typography variant="title" component="div" gutterBottom>
                    {t("Contract.Title")}
                  </Typography>
                  <Typography variant="subTitle" component="div" gutterBottom>
                    {t("Contract.SubTitle")}
                  </Typography>
                </Stack>
                <Stack sx={{ marginLeft: "40px", marginTop: "-25px" }} justifyContent="center" alignItems="center">
                  <img width="114px" src={signContract} />
                </Stack>
              </Stack>
            </Box>
            {renderContract()}
          </Box>
          {renderAction()}
        </Container>
      );
    } else {
      return <GenericBackdrop open={loader} />;
    }
  };

  useEffect(async () => {
    handleGetContractVoucher(false);
    getDataKym();
  }, []);

  useEffect(() => {}, []);

  return (
    <Fragment>
      <GenericBackdrop open={loaderToSign} />
      <Box sx={styles.containerView} mt={12}>
        {contractFLow()}
      </Box>
    </Fragment>
  );
}

export default function Contract() {
  return <OnBoardingLayout screen={<ContractScreen />} withoutContainer={true} />;
}
