import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Dialog, DialogContent, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Alert } from "../commons/alert";
import GenericBackdrop from "../commons/GenericBackdrop";

export default function Answers({ idQuestion, textQuestion }) {
  const [t] = useTranslation("global");
  const [answers, setAnswers] = useState([]);
  const [image, setImage] = useState(null);
  const [loader, setLoader] = useState(false);
  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);
  const axiosPrivate = useAxiosPrivate();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [openImage, setOpenImage] = useState(false);
  const handleOpen = () => setOpenImage(true);
  const handleClose = () => setOpenImage(false);
  const [urlImage, setUrlImage] = useState("");

  useEffect(() => {
    if (idQuestion !== undefined && idQuestion !== null) {
      handleLoaderOpen();

      axiosPrivate
        .get(`help/topics/questions/answers/${idQuestion}`)
        .then((response) => {
          setAnswers(response.data);
          setImage(response.data[0].image);
          handleLoaderClose();
        })
        .catch(() => {
          handleLoaderClose();
          Alert("", t("General.Message.ErrorService"), "");
        });
    }
  }, [idQuestion]);

  const imageClick = (item) => {
    if (item.target.alt === "img") {
      handleOpen();
      setUrlImage(item.target.src);
    }
  };

  return (
    <Fragment>
      <GenericBackdrop open={loader} />
      <Typography margin={"0 30px 0 30px"} sx={{ fontWeight: 600, fontSize: "18px", color: "#4C5866" }}>
        {textQuestion}
      </Typography>
      <div
        onClick={imageClick}
        style={{ margin: "20px 30px 0 30px" }}
        dangerouslySetInnerHTML={{
          __html: answers.length > 0 ? answers.map((item) => item.answer.split("\n").join("<br>")) : "",
        }}
      ></div>
      <div style={{ margin: "20px 30px 0px", textAlign: "center" }}>{image && <img src={answers[0]["image"]} />}</div>
      <div
        style={{ margin: "20px 30px 0 30px" }}
        dangerouslySetInnerHTML={{
          __html: answers.length > 0 ? answers.map((item) => item.additional_text) : "",
        }}
      ></div>

      <Dialog fullScreen={fullScreen} open={openImage} onClose={handleClose}>
        <DialogContent>
          <img data-testid="asd" src={urlImage} alt="asd" width="100%" height="100%" />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
