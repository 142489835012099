import { React } from "react";
import { useTranslation } from "react-i18next";

import { Button, Stack } from "@mui/material";
import PropTypes from "prop-types";

const ButtonsIntegrationsConfig = (props) => {
  const [t] = useTranslation("global");

  return (
    <Stack spacing={2} direction="row" justifyContent="flex-end" sx={{ marginTop: "30px" }}>
      <Button
        variant="outlined"
        size="large"
        sx={{ width: "155px", height: "48px" }}
        onClick={props.onClickIntegration}
      >
        {t("Integrations." + props.typeIntegration + ".Implementation.Add.ButtonCancel")}
      </Button>
      <Button
        variant="contained"
        size="large"
        onClick={props.handleSaveFields}
        style={{
          width: "155px",
          height: "48px",
          display: !props.edit ? "block" : "none",
          background: false ? "" : "#FFC10E",
        }}
      >
        {t("Integrations." + props.typeIntegration + ".Implementation.Add.ButtonSave")}
      </Button>
      <Button
        variant="contained"
        size="large"
        onClick={props.handleUpdateFields}
        style={{
          width: "155px",
          height: "48px",
          display: props.edit ? "block" : "none",
          background: false ? "" : "#FFC10E",
        }}
      >
        {t("Integrations." + props.typeIntegration + ".Implementation.Add.ButtonUpdate")}
      </Button>
    </Stack>
  );
};

ButtonsIntegrationsConfig.propTypes = {
  onClickIntegration: PropTypes.func.isRequired,
  handleUpdateFields: PropTypes.func.isRequired,
  handleSaveFields: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  typeIntegration: PropTypes.string.isRequired,
};

export default ButtonsIntegrationsConfig;
