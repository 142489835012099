import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { permissionsContext } from "../check-permissions/checkPermissions";
import { Confirm } from "../commons/alert";

const ActionDeleteUser = ({ row, handleDeleteUser }) => {
  const [t] = useTranslation("global");
  const permissions = useContext(permissionsContext);

  return (
    <>
      {permissions?.configuration?.users?.actions?.delete && (
        <div
          style={{
            marginLeft: "15px",
            marginRight: "15px",
            color: "#404A56",
          }}
          data-testid={`Delete_${row.user_id}`}
          onClick={async () => {
            let isConfirmed = await Confirm("", t("Users.Delete.Message", { user: row.name }), "warning");
            if (isConfirmed) {
              handleDeleteUser(row.user_id);
            }
          }}
        >
          {<DeleteForeverIcon style={{ cursor: "hand" }} />}
        </div>
      )}
    </>
  );
};

export default ActionDeleteUser;
