import { axiosPrivate } from "../../api/axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import DeleteIcon from "@mui/icons-material/Delete";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import QrCodeIcon from "@mui/icons-material/QrCode";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, Stack } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { v4 as uuidv4 } from "uuid";

import CreateStores from "./createStores";
import Detailstores from "./detailstores";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { permissionsContext } from "../check-permissions/checkPermissions";
import { Alert, Confirm } from "../commons/alert";
import BasicTable from "../commons/basicTable";
import GenericBackdrop from "../commons/GenericBackdrop";
import Search from "../commons/search";
import QrGenerate from "../qr/qrGenerate";

export default function Stores() {
  const [data, setData] = useState([]);
  const [urlTigo, setUrlTigo] = useState("");
  const [dataToEdit, setDataToEdit] = useState([]);
  const [dataDetail, setDataDetail] = useState({});
  const [dataqrdetail, setdataqrdetail] = useState({});
  const [refetch, setRefetch] = useState(false);
  const columns = ["Stores.Name", "Stores.Identifier", "Stores.Actions"];
  const condition = ["store_id", "msisdn", "telephone", "address", "latitude", "longitude"];
  const axiosPrivateUse = useAxiosPrivate();
  const [t] = useTranslation("global");
  const [loader, setLoader] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [dataBackUp, setDataBackUp] = useState([]);
  const [screen, setScreen] = useState("stores");
  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);
  const permissions = useContext(permissionsContext);

  const createaction = (row) => {
    return (
      <Fragment key={uuidv4()}>
        <TableCell
          align="center"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#404A56 !important",
          }}
        >
          {permissions?.configuration?.stores?.actions?.qr && (
            <div
              style={{
                marginLeft: "15px",
                marginRight: "15px",
                color: "#404A56",
              }}
              data-testid={`Qr-${row.store_id}`}
              onClick={() => qrdetails(row, "qrDetails")}
              role="qr-icon"
            >
              {<QrCodeIcon className="Cursor" />}
            </div>
          )}
          {permissions?.configuration?.stores?.actions?.edit && (
            <div
              style={{
                marginLeft: "15px",
                marginRight: "15px",
                color: "#404A56",
              }}
              data-testid={`detailStore-${row.store_id}`}
              onClick={() => details(row, "detailStore")}
            >
              {<VisibilityIcon className="Cursor" />}
            </div>
          )}
          {permissions?.configuration?.stores?.actions?.edit && (
            <div
              style={{
                marginLeft: "15px",
                marginRight: "15px",
                color: "#404A56",
              }}
              data-testid={`DetailEdit-${row.store_id}`}
              onClick={() => handleEditstore(row, "editStore")}
              role="detail-edit"
            >
              {<DriveFileRenameOutlineIcon className="Cursor" />}
            </div>
          )}
          {permissions?.configuration?.stores?.actions?.delete && (
            <div
              style={{
                marginLeft: "15px",
                marginRight: "15px",
                color: "#404A56",
              }}
              role="delete-store"
              data-testid={`DeleteStore-${row.store_id}`}
              onClick={async () => {
                let isConfirm = await Confirm("", t("Stores.MessageDelete", { store: row.name }), "warning");
                if (isConfirm) {
                  handleDeleteStore(row.store_id);
                }
              }}
            >
              {<DeleteIcon className="Cursor" />}
            </div>
          )}
        </TableCell>
      </Fragment>
    );
  };

  const handleDeleteStore = (storeid) => {
    setLoader(true);
    axiosPrivate
      .delete(`stores`, {
        params: {
          storeid: storeid,
        },
      })
      .then((response) => {
        setLoader(false);
        if (response.status === 204 || response.data === "Store deleted.") {
          Alert(t("Stores.StoreDeleted"), "warning");
          setRefetch(true);
        }
      })
      .catch((err) => {
        if (err.response.status === 410 && err.response.data === "Store already deleted.") {
          Alert(t("Stores.AlreadyDeleted"), "error");
        } else if (err.response.status === 404 && err.response.data === "Store not exist.") {
          Alert(t("Stores.StoreNotFound"), "error");
        } else {
          Alert(t("General.Message.ErrorService"), "error");
        }
        setLoader(false);
      });
  };

  const details = (row, screenDetails) => {
    setScreen(screenDetails);
    setDataDetail(row);
    setRefetch(true);
    let idx = data.findIndex((e) => e.key == row.key);
    let newData = [...data];
    newData[idx] = row;
    setData(newData);
  };

  const qrdetails = (row, screenQrDetails) => {
    setScreen(screenQrDetails);
    setdataqrdetail(row);
  };

  const handleEditstore = (row, screenEditstore) => {
    let editRow = row;
    editRow.name = editRow.name.split("|", 1)[0].trim();
    setScreen(screenEditstore);
    setDataToEdit(editRow);
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getStores = async () => {
      try {
        handleLoaderOpen();
        const response = await axiosPrivateUse.get(`stores`, {
          signal: controller.signal,
        });
        handleLoaderClose();
        const statusCode = response.status;
        setUrlTigo(response.data.urlTigo);
        setData(response.data.data);
        setDataBackUp(response.data.data);
        setRefetch(false);
        if (isMounted && statusCode === 204) {
          localStorage.setItem("storesEmpty", "true");
        } else {
          localStorage.setItem("storesEmpty", "false");
        }
      } catch {
        return Alert(t("General.Message.ErrorService"), "error");
      }
    };
    getStores();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [refetch, isSearch]);

  const selectScreen = () => {
    setRefetch(true);
    setScreen("stores");
  };

  const screenStores = () => {
    if (screen === "detailStore") {
      return <Detailstores data={dataDetail} back={details} />;
    } else if (screen === "qrDetails") {
      return (
        <Fragment>
          <QrGenerate
            url={urlTigo}
            name={dataqrdetail.name}
            id_number={String(dataqrdetail.msisdn)}
            origin="merchant-payment"
            amount={0}
            idSucursal={dataqrdetail.key}
            alg="HS256"
            account={String(dataqrdetail.msisdn)}
            reason=""
          ></QrGenerate>
          <div style={{ textAlign: "end", paddingTop: "20px" }}>
            <Button
              variant="outlined"
              style={{
                background: "white",
                color: "#404A56",
                textTransform: "none",
                minWidth: "139px",
                borderColor: "#FFC10E",
                height: "48px",
              }}
              onClick={() => setScreen("stores")}
              className="action-button-outlined"
            >
              {t("Buttons.Back")}
            </Button>
          </div>
        </Fragment>
      );
    } else if (screen === "createStore") {
      return <CreateStores showCreateStore={selectScreen} type={"create"} />;
    } else if (screen === "editStore") {
      return <CreateStores showCreateStore={selectScreen} type={"edit"} data={dataToEdit} />;
    } else {
      return (
        <Fragment>
          <GenericBackdrop open={loader} />
          <Stack direction="row" width="100%" mt={2}>
            {permissions?.configuration?.stores?.actions?.create && (
              <Stack direction="row" width="150vh" spacing={1} justifyContent="start" sx={{ maxWidth: 312 }}>
                <Search
                  backUp={dataBackUp}
                  setSearch={setData}
                  columnsSearch={["name", "key"]}
                  setIsSearch={setIsSearch}
                  setInputSearch={setInputSearch}
                  inputSearch={inputSearch}
                  placeholder={"Buttons.Search"}
                />
              </Stack>
            )}
            {permissions?.configuration?.stores?.actions?.create && (
              <Stack direction="row" width="150vh" spacing={1} justifyContent="end" style={{ paddingBottom: "20px" }}>
                <Button
                  style={{
                    background: "#FFC10E",
                    color: "#404A56",
                    marginLeft: "10px",
                    textTransform: "none",
                    minWidth: "133px",
                    height: "40px",
                    borderRadius: "8px",
                  }}
                  endIcon={<GroupAddIcon />}
                  onClick={() => setScreen("createStore")}
                  className="action-button-secondary"
                >
                  {t("Buttons.New2")}
                </Button>
              </Stack>
            )}
          </Stack>
          <GenericBackdrop open={loader} />
          <BasicTable dataTable={data} dataColumns={columns} action={createaction} condition={condition} align="left" />
        </Fragment>
      );
    }
  };

  return <div className="App">{screenStores()}</div>;
}
