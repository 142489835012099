const convertKeyValue = (cadena) => {
  const keyValue = cadena.split(",").map((pair) => {
    const [key, value] = pair
      .trim()
      .replace(/'/g, "")
      .split(":")
      .map((item) => item.trim().replace(/[{}]/g, ""));
    return { key, value };
  });
  const filteredKeyValue = keyValue.filter((item) => item.key && item.value);
  return filteredKeyValue.length === 0 ? [{ key: "", value: "" }] : filteredKeyValue;
};

const creatObjetFormat = (object, type = 1) => {
  object = object.filter((item) => item.key !== "" || item.value !== "");
  if (object && object.length === 0) {
    return "";
  } else {
    const objetFormat = object
      .map((item) => {
        const key = `'${item.key.trim()}'`;
        const value = type !== 1 ? `'${item.value.trim()}'` : `'{${item.value.trim()}}'`;
        return `${key}: ${value}`;
      })
      .join(", ");
    return objetFormat;
  }
};

export { convertKeyValue, creatObjetFormat };
