import React, { useContext, useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import CircleIcon from "@mui/icons-material/Circle";
import { Card, CardContent, FormControlLabel, MenuItem, Stack, Switch, TextField, Typography } from "@mui/material";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Tooltip } from "chart.js";
import PropTypes from "prop-types";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { formatYMD, monthAdd } from "../../utils/validateExpression";
import { permissionsContext } from "../check-permissions/checkPermissions";
import { Alert, SnackBar } from "../commons/alert";
import { CleanFilterButton } from "../commons/cleanFilterButton";
import { FilterButton } from "../commons/filterButton";
import GenericBackdrop from "../commons/GenericBackdrop";
import { ChatFormat } from "../commons/setDataTable";
import { getClientId } from "../utils/jwt";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

//debe cargarse antes que el componente
const options = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: "#fff",
      titleColor: "#515151",
      titleFont: {
        weight: 300,
      },
      bodyColor: "#515151",
      bodySpacing: 4,
      bodyFont: {
        weight: 600,
      },
      borderWidth: 2,
      borderColor: "rgba(0, 0, 0, 0.20)",
      usePointStyle: true,
      cornerRadius: 12,
      padding: 12,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: false,
      grid: {
        drawBorder: false,
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      stacked: false,
      grid: {
        drawBorder: false,
        borderDash: [5, 5],
      },
    },
  },
};

const chartStyle = {
  labelsControls: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#5C5C5C",
    marginLeft: "25px",
  },
  dateControls: {
    maxWidth: "180px",
    marginLeft: "8px !important",
    "& .MuiInputBase-formControl": {
      borderRadius: "8px",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#404A56",
    },
  },
};

export const Chart = ({ chartDataInfo, branches }) => {
  const [t] = useTranslation("global");

  const [loader, setLoader] = useState(false);

  const nameDone = "Done";
  const nameReimbursed = "Reimbursed";
  const nameError = "Error";
  const nameReverted = "Reverted";
  let [isDone, setIsDone] = useState(true);
  let [isReimbursed, setIsReimbursed] = useState(true);
  let [isError, setIsError] = useState(true);
  let [isReverted, setIsReverted] = useState(true);

  const nameEnd = "dateEnd";
  const nameInit = "dateInit";
  const today = new Date();
  const dateMin = monthAdd(today, -6); //periodo maximo ultimos 6 meses
  const [branch, setBranch] = useState("");

  let [dateInit, setDateInit] = useState(monthAdd(today, -1)); //periodo inicial, ultimo mes
  let [dateEnd, setDateEnd] = useState(today);

  const [chartData, setChartData] = useState(null);
  const [chartLegend, setChartLegend] = useState(null);
  const axiosPrivate = useAxiosPrivate();
  const useAuth = useAuthUser();
  const auth = useAuth();
  const permissions = useContext(permissionsContext);

  const getTransfers = async () => {
    try {
      setLoader(true);
      const response = await axiosPrivate
        .get("transactions/chart", {
          params: {
            date_init: formatYMD(dateInit),
            date_end: formatYMD(dateEnd),
            is_done: isDone,
            is_reimbursed: isReimbursed,
            is_error: isError,
            is_reverted: isReverted,
            client_id: getClientId(auth),
            branch: branch,
          },
        })
        .catch(() => {
          SnackBar(t("General.Message.ErrorService"), "error");
        });

      const formatChart = ChatFormat(response.data, t);
      setChartData(formatChart["data"]);
      setChartLegend(formatChart["legend"]);

      setLoader(false);
    } catch (err) {
      console.error(err);
      setLoader(false);
      Alert(t("General.Message.ErrorService"), "error");
    }
  };

  const filterData = () => {
    getTransfers();
  };

  const cleanFilter = () => {
    setDateInit(monthAdd(today, -1));
    setDateEnd(today);
    setIsDone(true);
    setIsReimbursed(true);
    setIsError(true);
    setIsReverted(true);
    isReverted = isError = isReimbursed = isDone = true;
    dateInit = monthAdd(today, -1);
    dateEnd = today;
    getTransfers();
    setBranch("");
  };

  const boolToInt = (bol) => {
    return bol ? 1 : 0;
  };

  const handleSwitchChange = (event) => {
    event.preventDefault();
    const { name, checked } = event.target;

    let result =
      boolToInt(checked) + boolToInt(isReimbursed) + boolToInt(isError) + boolToInt(isReverted) + boolToInt(isDone);

    if (result === 1) {
      return Alert(t("Insights.Transactions.Switch.None"), "warning");
    }

    switch (name) {
      case nameDone:
        setIsDone(checked);
        break;
      case nameReimbursed:
        setIsReimbursed(checked);
        break;
      case nameReverted:
        setIsReverted(checked);
        break;
      default:
        setIsError(checked);
        break;
    }
  };

  const handleDateChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    if (!value) return;

    const selectedDate = new Date(
      parseInt(value.slice(0, 4)),
      parseInt(value.slice(5, 7)) - 1, //los meses empiezan con 0=enero
      parseInt(value.slice(8, 10))
    );

    if (name === nameInit) {
      if (selectedDate > dateEnd) {
        event.target.value = formatYMD(dateInit);

        return Alert(t("Insights.Transactions.DateInit.MaxDate"), "warning");
      } else if (selectedDate < dateMin) {
        event.target.value = formatYMD(dateInit);

        return Alert(
          t("Insights.Transactions.DateInit.MinDate", {
            minDate: formatYMD(dateMin),
          }),
          "error"
        );
      }
    } else if (name === nameEnd) {
      if (selectedDate < dateInit) {
        event.target.value = formatYMD(dateEnd);

        return Alert(t("Insights.Transactions.DateEnd.MinDate"), "warning");
      } else if (selectedDate > today) {
        event.target.value = formatYMD(dateEnd);

        return Alert(
          t("Insights.Transactions.DateEnd.MaxDate", {
            today: formatYMD(today),
          }),
          "warning"
        );
      }
    }

    if (name === nameInit) setDateInit(selectedDate);
    else if (name === nameEnd) setDateEnd(selectedDate);
  };

  const getLegends = () => {
    let legends = chartLegend ?? chartDataInfo["legend"];

    if (legends.length > 0)
      return legends.map((item, index) => (
        <Stack key={index} spacing={1} direction="row">
          <CircleIcon sx={{ color: item.color }} />
          <Stack>
            <Typography variant="subTitle">{item.label}</Typography>
            <Typography variant="text28">{item.amount}</Typography>
          </Stack>
        </Stack>
      ));
  };

  const menuItem = (value) => {
    return (
      <MenuItem key={value.client_id} value={value.store_name}>
        {value.store_name}
      </MenuItem>
    );
  };

  useEffect(() => {
    if (!branch && branches && branches.length > 0) {
      setBranch(branches[0].store_name);
    }
  }, [branch, branches]);
  return (
    <Card className="mui-card" sx={{ margin: "20px 0", height: "595px" }}>
      <GenericBackdrop open={loader} />
      {permissions?.insights?.actions?.charts && (
        <CardContent>
          <Stack spacing={2} direction="row" sx={{ margin: "20px 0" }} display={{ display: "block" }}>
            <Stack sx={{ width: "100%" }}>
              <Typography gutterBottom variant="text18">
                {t("Insights.Transactions.Title")}
              </Typography>
            </Stack>
            {permissions?.insights?.actions?.chartFilters && (
              <Stack>
                <Stack spacing={3} direction="row">
                  <Stack
                    spacing={3}
                    direction="row"
                    sx={{ width: "100%" }}
                    className="date-container"
                    justifyContent="flex-start"
                    mt={2}
                  >
                    <TextField
                      name={nameInit}
                      label={t("Filter.DateInit")}
                      type="date"
                      size="small"
                      sx={chartStyle.dateControls}
                      className="date-control"
                      onChange={handleDateChange}
                      value={formatYMD(dateInit)}
                      InputProps={{
                        inputProps: { "data-testid": nameInit },
                      }}
                    />
                    <TextField
                      name={nameEnd}
                      label={t("Filter.DateEnd")}
                      type="date"
                      size="small"
                      sx={chartStyle.dateControls}
                      className="date-control"
                      onChange={handleDateChange}
                      value={formatYMD(dateEnd)}
                      InputProps={{
                        inputProps: { "data-testid": nameEnd },
                      }}
                    />
                    <TextField
                      id="outlined-branch"
                      label={t("Filter.Branch")}
                      select
                      value={branch}
                      onChange={(event) => {
                        setBranch(event.target.value);
                      }}
                      sx={chartStyle.dateControls}
                      className="date-control"
                      fullWidth
                      size="small"
                      inputProps={{ id: "select", "data-testid": "select" }}
                      InputLabelProps={{ style: { color: "#00377D" } }}
                    >
                      {branches.map((value) => menuItem(value))}
                    </TextField>
                  </Stack>
                </Stack>
                <Stack spacing={2} direction={"row"}>
                  <Stack spacing={2} direction="row" sx={{ width: "100%" }} justifyContent="flex-start" mt={3}>
                    <FormControlLabel
                      className="from-control-switch"
                      control={
                        <Switch name={nameDone} data-testid={nameDone} checked={isDone} onChange={handleSwitchChange} />
                      }
                      labelPlacement="start"
                      label={
                        <Typography style={chartStyle.labelsControls} className="chart-labels-controls">
                          {t("Insights.Transactions.Keys.Done")}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      className="from-control-switch"
                      control={
                        <Switch
                          name={nameReimbursed}
                          data-testid={nameReimbursed}
                          checked={isReimbursed}
                          onChange={handleSwitchChange}
                        />
                      }
                      labelPlacement="start"
                      label={
                        <Typography style={chartStyle.labelsControls} className="chart-labels-controls">
                          {t("Insights.Transactions.Keys.Reimbursed")}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      className="from-control-switch"
                      control={
                        <Switch
                          name={nameError}
                          data-testid={nameError}
                          checked={isError}
                          onChange={handleSwitchChange}
                        />
                      }
                      labelPlacement="start"
                      label={
                        <Typography style={chartStyle.labelsControls} className="chart-labels-controls">
                          {t("Insights.Transactions.Keys.Error")}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      className="from-control-switch"
                      control={
                        <Switch
                          name={nameReverted}
                          data-testid={nameReverted}
                          checked={isReverted}
                          onChange={handleSwitchChange}
                        />
                      }
                      labelPlacement="start"
                      label={
                        <Typography style={chartStyle.labelsControls} className="chart-labels-controls">
                          {t("Insights.Transactions.Keys.Reverted")}
                        </Typography>
                      }
                    />
                  </Stack>
                  <Stack direction="row" spacing={2} justifyContent="flex-end" style={{ marginTop: "16px" }}>
                    <CleanFilterButton filterFnc={cleanFilter} label={t("Filter.Clean")} />
                    <FilterButton filterFnc={filterData} label={t("Filter.Filter")} />
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Stack>
          <Stack direction="row">
            <Stack sx={{ width: "100%" }}>
              <div style={{ position: "relative", width: "65vw", height: "370px", marginLeft: "30px" }}>
                <Bar data={chartData ?? chartDataInfo["data"]} options={options} />
              </div>
            </Stack>
            <Stack
              spacing={3}
              justifyContent="center"
              sx={{ margin: "15px 40px", width: "100%", height: "fit-content" }}
            >
              {getLegends()}
            </Stack>
          </Stack>
        </CardContent>
      )}
    </Card>
  );
};

Chart.propTypes = {
  branches: PropTypes.arrayOf(
    PropTypes.shape({
      store_name: PropTypes.string.isRequired,
    })
  ).isRequired,
};
